import styled from "styled-components";

export const CampoModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .campo__check {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 3px;

    .check {
      border: 1px solid var(--border-color);
      border-radius: 5px;
      box-sizing: border-box;
      padding: 10px;
    }

    label {
      font-size: 13px;
      font-weight: 500;
      color: var(--text-color);
      display: inline-flex;
    }
  }
`