import { api } from '../../../api';
import { useQuery } from 'react-query';
import { localConfig } from '../../../../Configs/localConfig';
import { message } from 'antd';

//=================================================================================>
// BUSCAR LISTA DE ATIVOS CADASTRADOS
//<=================================================================================

//TYPES
type ImagemMonitoramento__Itens = {
    cod: number | null;
    cod_monitoramento: number | null;
    arquivo: string | null;
    descricao: string | null;
    create_time: string | null;
    update_time: string | null;
    delete_time: string | null;
    create_user: string | null;
    update_user: string | null;
    delete_user: string | null;
    url_arquivo: string | null;
}

//INTERFACE
export interface ImagemMonitoramento {
    itens: ImagemMonitoramento__Itens[],
    total?: number | null,
    mensagem?: string | null,
}

export const useGetImagemPontoMonitoramento = (codMonitoramento: number, triggerError?: Function) => {
    const queryKey: any = [`monitoramento-imagem_${codMonitoramento}`];

    const { conta, codCliente } = localConfig();

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ImagemMonitoramento, Error>(queryKey, async () => {
        
        return api.get(`monitoramento-imagem?codConta=${conta}&codCliente=${codCliente}&codMonitoramento=${codMonitoramento}&pagination=-1`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de ativos!');
                triggerError?.(true);
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_imagem: data?.itens ?? [], 
        carregando_imagem: isFetching, 
        refetch_imagem: refetch,
        isFetched_aimgem: isFetchedAfterMount
    };
};
