import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { kml } from '@tmcw/togeojson';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faFloppyDisk, faMap, faPen, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Drawer, Form, Input, Row, message } from 'antd';

//STYLES
import { EditarTalhao } from './styled';

//APP
import { useLogic } from '../../context/useLogic';
import { useAccess } from '../../context/useAccess';
import { Notification } from '../notification';
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado';
import { calculoAreaCoord, getBoundsNoArray } from '../../services/Afins';
import { getTalhaoCultivavel, postTalhaoCultivavel, putTalhaoCultivavel } from '../../services/TalhaoSafra';

//COMPONENTS
import Maps from '../maps/polygon';
import Icons from '../Icons';
import KMLUploader from '../kmlUpload';
import DesenhaTalhaoNew from '../desenhoTalhao/new';
import InputCustom from '../CustomUi/input';
import TextAreaCustom from '../CustomUi/textarea';
import ButtonCustom from '../ButtonCustom';
import { Gap } from '../CustomUi/gap';
import { Render } from '../CustomUi/render';

//INTERFACES
interface TypeProps {
    visible: boolean
    onClose: (data: boolean) => void
    update: number
    setUpdate: (codPro?: number, codTalhao?: number) => void
    atualizar: (data?: number, data2?: string) => void
    codPro: number
    permissions: ItensPerUserLogged
    dataTalhao: any
}
interface Coordinates {
    latitude: number
    longitude: number
}
interface TypeEditPolygon {
    nome: string
    propriedadeCod: number
    propriedadeNome: string
    fitBounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral
    polygon: google.maps.LatLngLiteral[] | google.maps.LatLng[]

}

const FormTalhaoSafra: React.FC<TypeProps> = (props) => {

    //PROPS
    const { visible, onClose, update, atualizar, setUpdate, codPro, dataTalhao, permissions } = props;

    const [form] = Form.useForm()
    const { responsive } = useLogic()
    const { atualizarArea, setAtualizarArea } = useAccess()

    const [drawerVisible, setDrawerVisible] = useState(false)
    const [safra, setSafra] = useState(true)
    const [kmls, setKml] = useState(null)
    const [coordsKml, setCoordsKml] = useState([])
    const [editPolygon, setEditPolygon] = useState<TypeEditPolygon>({
        polygon: [],
    } as TypeEditPolygon)
    const [selectedFile, setSelectedFile] = useState(null)
    const [disable, setDisable] = useState(true)
    const [validoFile, setValidoFile] = useState(false)
    const [modalDesenho, setModalDesenho] = useState(false)
    const [logUser, setLogUser] = useState(false)
    const [usuarioDeRegistro, setUsuarioDeRegistro] = useState('')
    const [dataDeRegistro, setDataDeRegistro] = useState('')
    const [criadoUpdate, setCriadoUpdate] = useState<number>()

    //NOVO
    const [nome_talhao, setNome_talhao] = useState<string>('');
    const [area_talhao, setArea_talhao] = useState<string>('');
    const [obs_talhao, setObs_talhao] = useState<string>('');
    const [loading_salvar, setLoading_salvar] = useState<boolean>(false);

    const hancleDrawerClose = () => {
        setDrawerVisible(false)
        onClose(false)
        setUpdate(0)
        form.resetFields()
        setKml(null)
        setSelectedFile(null)
        setCoordsKml([])
        setDisable(true)
    }

    /* Faz a requisição de todos os talhões da safra */
    const handleGetTalhao = async () => {
        try {
            message.loading('Aguarde...')
            const response = await getTalhaoCultivavel({
                codPropriedade: codPro,
                cod: update,
            })

            if (response.status === 200) {
                const dados = response.data.itens[0]

                setKml(dados)
                dados.arquivo = ''

                // form.setFieldsValue(dados)
                console.log("dados: ", dados)
                setNome_talhao(dados?.nome);

                const areaPolygon = dados.kml.coordenadas.map((item) => {
                    return { lat: item.latitude, lng: item.longitude }
                })

                const calcArea = calculoAreaCoord([areaPolygon])

                if (calcArea > 0) {
                    setValidoFile(true)
                } else {
                    setValidoFile(false)
                }

                setEditPolygon({
                    nome: dados.nome,
                    propriedadeNome: '',
                    propriedadeCod: dados.cod_propriedade,
                    fitBounds: getBoundsNoArray(
                        dados.kml.coordenadas.map((item) => {
                            return { lat: item.latitude, lng: item.longitude }
                        })
                    ),
                    polygon: dados.kml.coordenadas.map((item) => {
                        return { lat: item.latitude, lng: item.longitude }
                    }),
                })

                // form.setFieldsValue({ area: calcArea })
                setArea_talhao(String(calcArea))
                message.destroy()

            }
        } catch (error) {
            if (error.response) {
                const { data } = error.response

                Notification({
                    type: 'error',
                    message: 'Erro',
                    descricao: data.error,
                })

                message.destroy()
            }
        }
    }

    const listPropri = () => {
        handleGetTalhao()
        atualizar(codPro, '')
        setDisable(false)
    }

    const handleFileUpload = (rawData: string, base64Data) => {
        extractCoordinates(rawData)
        setSelectedFile(base64Data)
    }

    const parseXmlFromString = async (xmlString: string): Promise<Document> => {
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(xmlString, 'application/xml')
        return xmlDoc
    }

    const extractCoordinates = async (kmlString: string) => {
        try {
            const domKml = await parseXmlFromString(kmlString)

            const dataGeoJson = kml(domKml)

            const talhoes = []

            const dados = dataGeoJson.features
            const filtrado = dados.filter((data) => data.geometry.type === 'Polygon')

            if (filtrado.length > 0) {
                for (let i = 0; i < filtrado.length; i += 1) {
                    const fragment = filtrado[i]
                    const talhaoPoly = []

                    const coordinates = fragment.geometry.coordinates[0]

                    for (let ii = 0; ii < coordinates.length; ii += 1) {
                        const coord = coordinates[ii]

                        talhaoPoly.push({ lat: coord[1], lng: coord[0] })
                    }

                    talhoes.push(talhaoPoly)
                }

                const areaCoord = talhoes[0].map((item) => {
                    return { lat: item.lat, lng: item.lng }
                })

                const calcArea = calculoAreaCoord([areaCoord])
                if (calcArea <= 0) {
                    Notification({
                        message: 'Arquivo Kml inválido',
                        type: 'error',
                    })
                    setValidoFile(false)
                    setTimeout(() => {
                        setDisable(true)
                    }, 1000)
                } else {
                    setValidoFile(true)
                    setTimeout(() => {
                        setDisable(false)
                    }, 1000)
                }

                // form.setFieldsValue({ area: calcArea })
                setArea_talhao(String(calcArea))

                if (update > 0) {
                    setEditPolygon({
                        nome: kmls.nome,
                        propriedadeNome: '',
                        propriedadeCod: kmls.cod_propriedade,
                        fitBounds: getBoundsNoArray(editPolygon.polygon),
                        polygon: areaCoord.map((item) => {
                            return { lat: item.lat, lng: item.lng }
                        }),
                    })
                } else {
                    setCoordsKml(areaCoord)
                }
            } else {
                Notification({
                    message: 'Arquivo Kml inválido',
                    type: 'error',
                })
                setValidoFile(false)
            }
        } catch (error) {
            console.error('Erro ao processar XML:', error)
        }
    }

    const handleEditContorno = () => {
        setEditPolygon({
            nome: kmls.nome,
            propriedadeNome: '',
            propriedadeCod: kmls.cod_propriedade,
            fitBounds: getBoundsNoArray(editPolygon.polygon),
            polygon: kmls.kml.coordenadas.map((item) => {
                return { lat: item.latitude, lng: item.longitude }
            }),
        })

        setModalDesenho(true)
    }

    const onFinish = async (data) => {
        try {
            setLoading_salvar(true);
            message.loading('Aguarde...')
            // @ts-ignore
            if (update <= 0) {
                const novoArquivo = data.arquivo ?? selectedFile

                const dadosAtualizados = {
                    ...data,
                    nome: nome_talhao,
                    observacao: obs_talhao,
                    arquivo: novoArquivo,
                    cod_propriedade: codPro
                }
                const response = await postTalhaoCultivavel(codPro, dadosAtualizados)
                if (response.status === 201) {
                    message.destroy()
                    Notification({
                        message: 'Talhão Cadastrado com Sucesso',
                        type: 'success',
                    })

                    form.resetFields()

                    onClose(false)
                    setKml(null)
                    setUpdate(0)
                    setSelectedFile(null)
                    setCoordsKml([])
                    setDisable(true)
                    setAtualizarArea(!atualizarArea)
                    atualizar(codPro, '')
                    setLoading_salvar(false);
                }
                
                setLoading_salvar(false);
            } else {
                const novoArquivo = selectedFile

                const dadosAtualizados = {
                    ...data,
                    nome: nome_talhao,
                    observacao: obs_talhao,
                    arquivo: novoArquivo,
                }


                const response = await putTalhaoCultivavel(codPro, update, dadosAtualizados)
                if (response.status === 200) {
                    message.destroy()
                    Notification({
                        message: 'Talhão Atualizado com Sucesso',
                        type: 'success',
                    })

                    onClose(false)
                    setKml(null)
                    setUpdate(0)
                    setSelectedFile(null)
                    setCoordsKml([])
                    setDisable(true)
                    setAtualizarArea(!atualizarArea)
                    atualizar(codPro, '')
                    setLoading_salvar(false);
                }
                setLoading_salvar(false);
            }
        } catch (error) {
            message.destroy()
            setLoading_salvar(false);

            if (error.response) {
                const { data } = error.response
                if (!data.error[0].field) {
                    Notification({
                        type: 'error',
                        message: 'Erro',
                        descricao: data.error,
                    })
                } else {
                    data.error.forEach((data) => {
                        Notification({
                            type: 'error',
                            message: 'Erro',
                            descricao: data.msg[0],
                        })
                    })
                }
            }
        }
    }

    const handleChangeMask = (data) => {
        let area = data.target.value
        area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
        area = (area / 100).toFixed(2).replace('.', ',')
        // form.setFieldsValue({ area })
        setArea_talhao(String(area))
    }

    useEffect(() => {
        form.setFieldsValue({ cod_propriedade: codPro })
        setDrawerVisible(visible)
    }, [visible])

    useEffect(() => {
        if (update <= 0) {
            form.resetFields()
            setUsuarioDeRegistro('')
            setDataDeRegistro('')
            setLogUser(false)
            setCriadoUpdate(0)
        } else {
            handleGetTalhao()
        }
    }, [update])

    useEffect(() => {
        if (visible) {
            const listTalhao = dataTalhao.filter((item) => item.cod === update)
            if (update > 0 && listTalhao[0].create_user) {
                setUsuarioDeRegistro(listTalhao[0].create_user)
                setDataDeRegistro(listTalhao[0].create_time)
                setLogUser(true)
                setCriadoUpdate(0)
            }

            if (update > 0 && listTalhao[0].update_user != null) {
                setUsuarioDeRegistro(listTalhao[0].update_user)
                setDataDeRegistro(listTalhao[0].update_time)
                setCriadoUpdate(1)
                setLogUser(true)
            }
        }
    }, [visible, update])

    useEffect(() => {
        if (!drawerVisible) {
            setNome_talhao('');
            setArea_talhao('');
            setObs_talhao('');
        }
    }, [drawerVisible])
    

    return (
        <Form form={form} layout='vertical' onFinish={onFinish}>
            <Drawer
                title={
                    <div style={{ display: 'flex', flexDirection: 'column', gap:5, justifyContent:'center' }}>
                        <div style={{width:'calc(100% + 42px)',height:35,borderBottom:'thin solid var(--border-color)', marginLeft: -22}}>
                            <ButtonCustom
                                onClick={hancleDrawerClose}
                                type='tertiary'
                                danger
                                style={{marginLeft: 10, marginTop: -10}}
                                icon={<FontAwesomeIcon icon={faXmark} />}
                            >
                                Fechar
                            </ButtonCustom>
                        </div>
                        
                        <>{update ? 'Atualizar' : 'Cadastrar'}</>
                        <small style={{ opacity: '0.5', fontSize: '10px' }}>
                            {logUser === true ? (
                                <>
                                    {' '}
                                    {criadoUpdate > 0 ? 'Atualizado por:' : 'Criado por:'}{' '}
                                    {`${usuarioDeRegistro} ${dayjs(dataDeRegistro).format('DD/MM/YYYY HH:mm:ss')}`}
                                </>
                            ) : null}
                        </small>
                    </div>
                }
                style={{ position: 'absolute' }}
                placement='right'
                width='100%'
                onClose={hancleDrawerClose}
                open={drawerVisible}
                closeIcon={false}
                getContainer={false}
                destroyOnClose
                footer={false}
            >

                <EditarTalhao>
                    <div className='EditarTalhao__form' style={{ pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto' }}>

                        <InputCustom 
                            label='Nome do talhão'
                            placeholder='Nome do talhão'
                            value={nome_talhao}
                            onChange={setNome_talhao}
                            icon={faMap}
                        />

                        <Gap value={15} type='column' />

                        <InputCustom 
                            label='Área do talhão (ha)'
                            placeholder='Área do talhão'
                            value={area_talhao}
                            onChange={setNome_talhao}
                            icon={faChartArea}
                            readOnly
                            desabilitado
                        />

                        <Gap value={15} type='column' />

                        <TextAreaCustom 
                            label='Observação'
                            value={obs_talhao}
                            onChange={setObs_talhao}
                            placeholder='Observação do talhão'
                        />
                        
                        <Gap value={15} type='column' />

                        <div className='EditarTalhao__form__import'>
                            <Form.Item style={{ width: 'auto', height:'10px' }} name='arquivo'>
                                <KMLUploader
                                    disable={disable}
                                    setDisable={setDisable}
                                    onFileUpload={handleFileUpload}
                                    valido={validoFile}
                                    setValido={setValidoFile}
                                    descricao='Importar KML'
                                    type='link'
                                >

                                </KMLUploader>
                            </Form.Item>
                        </div>  

                        <Gap value={15} type='column' />

                        <div className='EditarTalhao__form__footer'>
                            <Button
                                hidden={permissions?.inclusao === 0 && permissions?.alteracao === 0}
                                type='primary'
                                htmlType='submit'
                                style={{ background: 'transparent', padding: 0, margin: 0, border: 'none', boxShadow: 'none' }}
                            >
                                <ButtonCustom 
                                    icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                                    onClick={()=>{}}
                                    loading={loading_salvar}
                                    disabled={loading_salvar}
                                    hidden={permissions?.inclusao === 0 && permissions?.alteracao === 0}
                                >
                                    {update ? 'Salvar edição' : 'Salvar'}
                                </ButtonCustom>
                            </Button>
                            
                        </div>
                        
                    </div>

                    <div className='EditarTalhao__mapa'>
                        {update <= 0 ? (
                            <div className='EditarTalhao__mapa__container'>
                                <Render condition={(coordsKml).length > 0}>
                                    <Maps coord={coordsKml} customHeight={'100%'} />
                                </Render>
                                <Render condition={(coordsKml).length === 0}>
                                    <span className='EditarTalhao__mapa__container__aviso'>Importe um KML para visualizar o mapa ou desenhe um talhão</span>
                                    <Maps coord={[{ lat: -16.033067963936567, lng: -50.75721549399004 }]}  customHeight={'100%'} />
                                </Render>
                            </div>
                        ) : (
                            <div className='EditarTalhao__mapa__container'>
                                <div className='EditarTalhao__m__c__float'>
                                    <Button
                                        style={{ backgroundColor: 'white', color: 'var(--primary-color)', border: 'none' }}
                                        hidden={responsive}
                                        onClick={handleEditContorno}
                                        icon={<Icons style={{ color: 'green' }} icon={faPen} />}
                                    >
                                        Editar desenho
                                    </Button>
                                </div>
                                <Render condition={(editPolygon.polygon).length > 0}>
                                    <Maps coord={editPolygon.polygon} customHeight={'100%'} />
                                </Render>
                                <Render condition={(editPolygon.polygon).length === 0}>
                                    <span style={{color:'#fff'}}>Importe um KML para visualizar o mapa ou desenhe um talhão</span>
                                    <Maps coord={[{ lat: -14.235, lng: -51.9253 }]} customHeight={'100%'} />
                                </Render>
                            </div>
                        )}
                    </div>
                </EditarTalhao>

                {/* Hide */}

                <Form.Item initialValue='1' hidden name='status'>
                    <Input />
                </Form.Item>

                <Form.Item hidden initialValue={localStorage.getItem('conta')} name='cod_conta'>
                    <Input />
                </Form.Item>
                <Form.Item hidden initialValue={localStorage.getItem('cod-cliente')} name='cod_cliente'>
                    <Input />
                </Form.Item>
                <Form.Item hidden name='cod_propriedade' />
            </Drawer>


            <DesenhaTalhaoNew
                isOpen={modalDesenho}
                update={update}
                onSuccess={listPropri}
                cultivavel={safra}
                onClose={setModalDesenho}
                talhao={editPolygon}
            />
        </Form>
    )
}

export default FormTalhaoSafra
