import styled from "styled-components"

export const ProgressContainer = styled.div`
    width: 100%;
    padding: 15px 0px;
    display: flex;
    align-items: center;

    :is([data-isWhite = true]) {
        filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7476%) hue-rotate(232deg) brightness(97%) contrast(98%);
    }

    div.ProgressContainer__conteudo {
        width: 100%;
        max-width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        img.ProgressContainer__conteudo__success {
            width: 27px;
            margin-bottom: -12px;
        }

        div.PC__conteudo__barContainer {
            width: 100%;
            height: 20px;
            display: flex;
            align-items: center;
            gap: 15px;
            margin-top: -7px;

            div.PC__conteudo__barContainer__track {
                width: 100%;
                height: 5px;
                background: var(--hover-color);
                border-radius: 10px;

                div.PC__conteudo__barContainer__track__bar {
                    width: 0%;
                    height: 5px;
                    background: rgb(93,165,125);
                    background: linear-gradient(90deg, rgba(93,165,125,1) 70%, rgba(79,255,157,1) 100%);
                    border-radius: 10px;
                    transition: .4s;
                }
            }

            span.PC__conteudo__barContainer__percent {
                font-weight: 600;
                color: var(--text-color);
            }
        }

        span.ProgressContainer__conteudo__legenda {
            font-weight: 600;
            color: var(--primary-color);
            display: flex;
            animation: blink 0.8s infinite;
            animation-timing-function: linear;
            margin-top: -15px;
        }
        span.ProgressContainer__conteudo__estimado {
            font-weight: 500;
            font-size: 12px;
            color: var(--primary-color);
            display: flex;
            margin-top: -10px;
        }

        @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.4; }
            100% { opacity: 1; }
        }

    }
`