import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FixedSizeList as List } from 'react-window';
import { faAngleLeft, faDownLeftAndUpRightToCenter, faEye, faLayerGroup, faMap, faMountain, faPrint, faTableList, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { FooterPadrao } from "../../../Footer/styled";
import { Monitoramento } from "./styled";
import { TitleContainer } from "../../../ordemLaboratorio/mapaFertilidade/components/painelFiltro/styled";

//APP
import { objOrder } from "../../../../services/Afins";
import { listPragas } from "../tabelaPragas/meta";
import { useScreenSize } from "../../../../utils/tsx";
import { PropriedadeItens } from "../../../../services/Propriedades";
import { leadZero, removerAcentos } from "../../../../utils";
import { TypeDetailsMonitoramento } from "../../../../features/Monitoramento/Monitoramento.dataDetails";
import { MonitoramentoMapCalorType } from "../..";
import { MoniNivelItemControleItens } from "../../../../services/monitoramento/nivelControleItens";
import { esconderAccordion, MonitoramentoMapaCalorMonitoramentoType, obterAlturaListaTodasPragas, obterTalhoes, processarTodosTalhoes } from "./meta";

//COMPONENTS
import Svg from "../../../talhasvg";
import Tabs from "../../../Tabs";
import Input from "../../../CustomUi/input";
import Carregando from "../../../CustomUi/loading";
import ButtonCustom from "../../../ButtonCustom";
import ProgressLoading from "../../../CustomUi/progressLoading";
import { Empty } from "antd";
import { Render } from "../../../CustomUi/render";
import { Inline } from "../../../CustomUi/inline";
import { InfoBox } from "../../../CustomUi/infoBox";
import { RowItem } from "../../../CustomUi/table/rowItem";
import { RowTable } from "../../../CustomUi/table/rowTable";
import { Switcher } from "../../../CustomUi/Switcher";
import { Accordion } from "../../../CustomUi/accordion";
import { LineDivider } from "../../../CustomUi/LineDivider";
import { TabelaPraga } from "../tabelaPragas";
import { HeaderTable } from "../../../CustomUi/table/headerTable";
import { ColorCircle } from "../tabelaPragas/styled";
import { TabelaDatas } from "../tabelaDatas";

//PROPS
interface Props {
    main_data: { [key: string]: MonitoramentoMapCalorType[] };
    all_data: { [key: string]: MonitoramentoMapaCalorMonitoramentoType[]; }[];
    progress: number;
    listDates: any;
    nome_safra: string;
    hideMarker: boolean;
    
    propriedade: PropriedadeItens;
    dataDetails: TypeDetailsMonitoramento;
    nome_cliente: string;
    nivelControle: MoniNivelItemControleItens[];
    setHideMarker: React.Dispatch<React.SetStateAction<boolean>>;
    groupTotalizar: boolean;
    nome_propriedade: string;
    disabilitarGerarBook: boolean;
    visibleChangeBooking: boolean;
    setVisibleChangeBooking: React.Dispatch<React.SetStateAction<boolean>>;
    setVisibleDrawerPonto: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: Function;
    setCalorSelect: Function;
    handleClickBadge: Function;
    handleChangeCollapse: Function;
    handleAssembleHeatMap: Function;
}

export const PainelMonitoramento: React.FC<Props> = ({ main_data, all_data, propriedade, groupTotalizar, nivelControle, listDates,
    nome_cliente, nome_safra, nome_propriedade, handleAssembleHeatMap, handleClickBadge, setCalorSelect, handleChangeCollapse,
    progress, onClose, setHideMarker, hideMarker, dataDetails, disabilitarGerarBook, setVisibleChangeBooking, setVisibleDrawerPonto, 
    visibleChangeBooking }) => {

    //STATES
    const [current_tab, setCurrent_tab] = useState<'Por talhão' | 'Todos'>('Por talhão');
    const [search_talhao, setSearch_talhao] = useState<string>('');
    const [search_praga, setSearch_praga] = useState<string>('');
    const [open_accordion, setOpen_accordion] = useState<number | null>(null);
    const [pragas_talhao, setPragas_talhao] = useState<any>();
    const [encolher, setEncolher] = useState<boolean>(false);
    const [loading_todos_dados, setLoading_todos_dados] = useState<boolean>(true);
    const [list_todos_dados, setList_todos_dados] = useState([]);
    const [list_todos_dados_preserve, setList_todos_dados_preserve] = useState([]);
    const [fake_loading, setFake_loading] = useState<boolean>(false);
    //SELECIONADOS
    const [praga_selecionada, setPraga_selecionada] = useState<listPragas|null>(null);
    const [nome_praga_selecionada, setNome_praga_selecionada] = useState<string>('');
    const [nome_variacao_selecionada, setNome_variacao_selecionada] = useState<string>('');
    const [open_tabela_datas, setOpen_tabela_datas] = useState<boolean>(false);


    //CONST
    const { currentHeight } = useScreenSize({});

    useEffect(() => {
      return () => {
        setCurrent_tab('Por talhão');
        setSearch_talhao('');
        setOpen_accordion(null);
        setEncolher(false);
        setOpen_tabela_datas(false);
        setLoading_todos_dados(true);
      }
    }, [])
    

    //FUNCTIONS
      function fakeLoading() {
        setFake_loading(true);

        setTimeout(() => {
            setFake_loading(false);
            setVisibleChangeBooking(true);
        }, 700);
    }
    function obterDadosTalhaoSelecionado(cod_talhao: number) {

        const dados_talhao = main_data?.[cod_talhao];

        const monitoramento = dados_talhao.map((rec) => rec.monitoramento)

        let listMoni = []

        monitoramento.forEach((rec) => {
            rec.forEach((item) => {
                listMoni = [...listMoni, ...item]
            })
            return listMoni
        })

        const moniNivel = groupBy(listMoni, 'codEspVari');

        let totalizar = {}

        listMoni.forEach((item) => {
            const cod = item.codEspVari.split(', ')
            const codE = `${cod[0]}, ${cod[1]}`
            const codV = `${cod[0]}, ${cod[1]}, ${cod[2]}`

            if (cod[3] === '1') {
                if (totalizar[codE] === undefined) {
                    totalizar[codE] = [item]
                } else {
                    totalizar[codE].push(item)
                }
            }
            else if (totalizar[codV] === undefined) {
                totalizar[codV] = [item]
            }
            else {
                totalizar[codV].push(item)
            }
        })

        const dateIsPraga = Object.entries(groupTotalizar ? objOrder(totalizar) : objOrder(moniNivel)).map(([dKey, dValue]: [any, any]) => {
            const pragas = groupBy(dValue, 'dataInit')

            return { [dKey]: { ...pragas } }
        });
        

        setPragas_talhao(dateIsPraga);
        return dateIsPraga

    }


    function buscarTalhao(buscar_nome: string) {
        const nomeSemAcentos = removerAcentos(buscar_nome)
        return function (item) {
            const talhaoSemAcentos = removerAcentos(String(item?.['nome_talhao']))
            return talhaoSemAcentos.toLowerCase().includes(nomeSemAcentos.toLowerCase()) || !buscar_nome
        }
    }

    function processarTodosOsDados() {
        let processados = processarTodosTalhoes(all_data, nivelControle, listDates, groupTotalizar);
        let tempo_processamento = (processados??[]).length * 20;
        
        setList_todos_dados(processados);
        setList_todos_dados_preserve(processados);

        setTimeout(() => {
            setLoading_todos_dados(false);
        }, tempo_processamento);
    }

    useEffect(() => {

        setTimeout(() => {
           if (list_todos_dados_preserve.length > 0) {
                if (search_praga === '') {
                    setList_todos_dados(list_todos_dados_preserve);
                }
                if(search_praga.length > 0) {
                    let buscar_praga = list_todos_dados_preserve.filter(buscarPraga(search_praga));
                    setList_todos_dados(buscar_praga);
                }
            } 
        }, 300);
         
    }, [search_praga])

    function buscarPraga(buscar_nome: string) {
        const nomeSemAcentos = removerAcentos(buscar_nome);
        return function (item) {
          const pragaSemAcentos = removerAcentos(String(item?.['especie_nome']))
          const variacaoSemAcentos = removerAcentos(String(item?.['variacao']))
          return pragaSemAcentos.toLowerCase().includes(nomeSemAcentos.toLowerCase()) ||
            variacaoSemAcentos.toLowerCase().includes(nomeSemAcentos.toLowerCase()) || !buscar_nome
        }
    }

    const Row = ({ index, style }) => {//NOSONAR

        let { especie_nome, variacao, cores, key } = list_todos_dados?.[index] ?? {};

        function selecionarPraga(key_selecionada: number) {
            let select = list_todos_dados.filter(item=> item.key === key_selecionada);
            setPraga_selecionada(select?.[0]);
        }

        function qtdeDados() {
            let { cor_verde_claro, cor_verde_forte, cor_amarelo_claro, cor_amarelo_forte, cor_vermelho } = cores;
            let soma = cor_verde_claro + cor_verde_forte + cor_amarelo_claro + cor_amarelo_forte +cor_vermelho;
            return soma;
        }

        return (
            <div style={style}>
                <RowTable onDoubleClick={()=>{
                    setNome_praga_selecionada(especie_nome);
                    setNome_variacao_selecionada(variacao);
                    selecionarPraga(key);
                    setOpen_tabela_datas(true);
                }}>
                    <RowItem width={240} fontSize={13}>
                        {especie_nome}
                    </RowItem>
                    <RowItem width={116} fontSize={12.5}>
                        {variacao}
                    </RowItem>
                    <RowItem width={155} gap={5} style={{flexWrap: "wrap", padding: '0px 5px'}} >
                        <Render condition={cores?.cor_verde_claro > 0}>
                            <ColorCircle data-cor={'verde_claro'}>{leadZero(cores?.cor_verde_claro)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_verde_forte > 0}>
                            <ColorCircle data-cor={'verde_forte'}>{leadZero(cores?.cor_verde_forte)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_amarelo_claro > 0}>
                            <ColorCircle data-cor={'amarelo_claro'}>{leadZero(cores?.cor_amarelo_claro)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_amarelo_forte > 0}>
                            <ColorCircle data-cor={'amarelo_forte'}>{leadZero(cores?.cor_amarelo_forte)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_vermelho > 0}>
                            <ColorCircle data-cor={'vermelho'}>{leadZero(cores?.cor_vermelho)}</ColorCircle>
                        </Render>
                    </RowItem>
                    <RowItem width={65}>
                        <ButtonCustom 
                            onClick={()=>{
                                setNome_praga_selecionada(especie_nome);
                                setNome_variacao_selecionada(variacao);
                                selecionarPraga(key);
                                setOpen_tabela_datas(true);
                            }}
                            type={qtdeDados()!==1 ? 'secondary' : 'primary'}
                            icon={ qtdeDados()!==1 ? <FontAwesomeIcon icon={faTableList} /> : <FontAwesomeIcon icon={faEye} />}
                        >
                        </ButtonCustom>
                    </RowItem>
                </RowTable>
            </div>
        );
    }

    return (
        <Monitoramento data-encolher={encolher}>
            <Inline justifyContent="space-between">
                <ButtonCustom
                    onClick={() => { onClose?.() }}
                    icon={<FontAwesomeIcon icon={faAngleLeft} />}
                    type="tertiary"
                    danger
                    style={{ marginLeft: -10 }}
                >
                    Voltar
                </ButtonCustom>
                <ButtonCustom
                    onClick={() => { setEncolher(!encolher) }}
                    icon={<FontAwesomeIcon icon={encolher ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter} />}
                    type="tertiary"
                    title={encolher? 'Expandir painel' : 'Encolher painel'}
                />
            </Inline>
            <TitleContainer data-esconder={encolher}>
                <div className="TitleContainer__icon">
                    <FontAwesomeIcon icon={faMap} />
                </div>
                <div className="TitleContainer__titles">
                    <h2 className="TitleContainer__titles__talhao">
                        {nome_propriedade}
                    </h2>
                </div>
            </TitleContainer>
            <InfoBox
                items={[
                    { title: 'Cliente', value: nome_cliente },
                    { title: 'Safra', value: nome_safra },
                    { title: 'Qtde de talhões', value: leadZero((obterTalhoes(main_data)?.length ?? 0)) },
                ]}
                spaceBetWeen
            />
            <LineDivider />

            <div style={{ height: 'calc(100% - 235px)', marginTop: 15, position: 'relative', zIndex: `997 !important` }}>
                {/* <Box padding={15}> */}
                {/* <TitleH3 style={{color:'var(--text-color-light)'}}>Mapas de calor</TitleH3> */}

                <div className="Monitoramento__loading" style={{ display: (progress === 100 || progress === Infinity) && 'none' }}>
                    <ProgressLoading
                        width={590}
                        justifyContent="center"
                        legenda="Carregando lista de talhões"
                        animation
                        progress={progress}
                        trigger={() => {}}
                    />
                </div>
 
                {/* <Render condition={loading_finalizado && progress === 100}> */}
                    <Inline style={{ marginTop: -8 }}>
                        <Tabs
                            width={270}
                            tabs={[
                                { descricao: 'Por talhão', icone: faMountain, task: ()=>{setLoading_todos_dados(true);setSearch_praga('')} },
                                { descricao: 'Todos', icone: faLayerGroup, task: ()=>{processarTodosOsDados();setSearch_talhao('')} },
                            ]}
                            onChange={setCurrent_tab}
                            selecionado={current_tab}
                            trackWidth={127.5}
                        />

                        <Input
                            value={current_tab==='Por talhão' ? search_talhao : search_praga}
                            onChange={current_tab==='Por talhão' ? setSearch_talhao : setSearch_praga}
                            placeholder={current_tab==='Por talhão' ? "buscar talhão" : 'buscar praga'}
                        />
                    </Inline>
                {/* </Render> */}

                                               
                <Render condition={current_tab === 'Por talhão' && obterTalhoes(main_data).length === 0}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>Nenhum registro disponível!</>} />
                </Render>
                <Render condition={current_tab === 'Todos' && list_todos_dados.length === 0}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>Nenhum registro disponível!</>} />
                </Render>

                <Render condition={current_tab === 'Por talhão' && obterTalhoes(main_data).length > 0}>
                                                        
                    <div style={{ height: 'calc(100vh - 320px)', overflowY: 'auto', boxSizing: 'border-box', paddingRight: 7, marginTop: 10, }}>
                        {(obterTalhoes(main_data) ?? []).filter(buscarTalhao(search_talhao)).map(({ nome_talhao, cod_talhao }, index) => {
                            const talhao = propriedade?.talhao.find((rec) => rec.cod === cod_talhao);
                            return (
                                <Accordion
                                    key={cod_talhao}
                                    label={nome_talhao}
                                    open={open_accordion === cod_talhao}
                                    isFirst={index === 0}
                                    isLast={(index + 1) === (obterTalhoes(main_data).length)}
                                    single={open_accordion === cod_talhao}
                                    style={{ display: esconderAccordion(open_accordion, cod_talhao) }}
                                    extra={
                                        <Svg
                                            fill={talhao.isSubArea ? '#FFA500' : '#70CE9F'}
                                            // @ts-ignore
                                            coordinates={talhao?.kml?.coordenadas}
                                        />
                                    }
                                    trigger={open_accordion === null
                                        ? () => {
                                            handleChangeCollapse?.(cod_talhao);
                                            setOpen_accordion(cod_talhao);
                                            obterDadosTalhaoSelecionado(cod_talhao)
                                        }
                                        : () => { handleChangeCollapse?.(-1); setOpen_accordion(null) }
                                    }
                                >
                                    <TabelaPraga
                                        nome_talhao={nome_talhao}
                                        cod_talhao={cod_talhao}
                                        list_pragas={pragas_talhao}
                                        listDates={listDates}
                                        nivelControle={nivelControle}
                                        groupTotalizar={groupTotalizar}
                                        handleAssembleHeatMap={handleAssembleHeatMap}
                                        handleClickBadge={handleClickBadge}
                                        setCalorSelect={setCalorSelect}
                                        dataDetails={dataDetails}
                                        encolher={encolher}
                                        setVisibleDrawerPonto={setVisibleDrawerPonto}
                                        visibleChangeBooking={visibleChangeBooking}
                                    />
                                </Accordion>
                            )
                        })}
                    </div>
                </Render>

                <Render condition={current_tab === 'Todos' && list_todos_dados.length > 0}>
                    {loading_todos_dados
                    ? 
                    <div style={{height: 'calc(100vh - 320px)',display:'flex',alignContent:'center',justifyContent:'center'}}>
                        <Carregando legenda="Processando dados do monitoramento..." animation justifyContent="center"/>
                    </div>
                    : 
                    <div style={{ height: 'calc(100vh - 320px)', overflowY: 'auto', boxSizing: 'border-box', paddingRight: 7, marginTop: 10, }}>
                        <HeaderTable
                            itens={[
                                {valor: 'Praga', width: 240},
                                {valor: 'Variação', width: 116},
                                {valor: 'Resumo', width: 155.5},
                                {valor: '', width: 64},
                            ]}
                        />
                        <div style={{marginTop:-1}}>
                            <Render condition={list_todos_dados.length > 0}>
                                <List
                                    height={obterAlturaListaTodasPragas(currentHeight)}
                                    itemCount={list_todos_dados.length ?? 0}
                                    itemSize={55}
                                    width={'100%'}
                                    useIsScrolling
                                >
                                    {Row}
                                </List>
                            </Render>

                            <Render condition={list_todos_dados.length === 0}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> <b>'{search_talhao}'</b> não encontrado!</>} />
                            </Render>
                        </div>


                        
                        <Render condition={open_tabela_datas && !encolher}>
                            <TabelaDatas 
                                nome_praga={nome_praga_selecionada}
                                nome_variacao={nome_variacao_selecionada}
                                nome_talhao={''}
                                onClose={()=>{setOpen_tabela_datas(false);setVisibleDrawerPonto(false)}}
                                praga_selecionada={praga_selecionada?.cores?.dados_gerais ?? []}
                                list_datas={praga_selecionada?.datas ?? []}
                                handleAssembleHeatMap={handleAssembleHeatMap}
                                handleClickBadge={handleClickBadge}
                                setCalorSelect={setCalorSelect}
                                dataDetails={dataDetails}
                                origem={'Todos'}
                                encolher={encolher}
                                setVisibleDrawerPonto={setVisibleDrawerPonto}
                                visibleChangeBooking={visibleChangeBooking}
                            />
                        </Render>
                    </div>
                    }
                    
                </Render>


            </div>

            <FooterPadrao>

                <Switcher
                    isChecked={hideMarker}
                    trigger={setHideMarker}
                    label='Exibir marcadores'
                    disabled={progress < 100}
                />

                <ButtonCustom
                    onClick={() => { fakeLoading() }}
                    icon={<FontAwesomeIcon icon={faPrint} />}
                    disabled={progress < 100 || disabilitarGerarBook || fake_loading}
                    loading={fake_loading}
                >
                    Gerar book completo
                </ButtonCustom>
            </FooterPadrao>

        </Monitoramento>
    )
};