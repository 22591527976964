
import { Spin } from 'antd';
import { ProgressContainer } from './styled';
import { useState, useEffect } from 'react';

//ASSETS
import successIcon from '../../../assets/img/success.svg';

//PROPS
interface Props {
    justifyContent?: 'flex-start' | 'center' | 'flex-end';
    legenda?: string;
    width?: number | string;
    height?: number | string;
    animation?: boolean;
    size?: 'small' | 'default' | 'large';
    direction?: 'column' | 'row';
    isWhite?: boolean;
    progress: number;
    trigger?: Function;
}

const ProgressLoading: React.FC<Props> = ({ justifyContent, height, width, animation, legenda, size, direction, isWhite, progress, trigger }) => {

    //STATES
    const [finalizado, setFinalizado] = useState(false);
    const [new_progress, setNew_progress] = useState<number>(0);
    const [conexao_instavel, setConexao_instavel] = useState<boolean>(false);
    const [contar_tempo, setContar_tempo] = useState<number>(0);
    const [tempo_estimado, setTempo_estimado] = useState<number>(0);
    const [inicio, setInicio] = useState(null); // Tempo inicial
    const [tempoRestante, setTempoRestante] = useState<number|null>(null); 
    
    useEffect(() => {
        if (new_progress===100) {
            setTimeout(() => {
                trigger?.(true);
                // setFinalizado(true);
            }, 1500);
        }
        return(()=> {
            setFinalizado(false);
            trigger?.(false);
        })
    }, [new_progress]);
    

    useEffect(() => {
        setTimeout(() => {
            setNew_progress(progress)
        }, 500);    
    }, [progress])
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (navigator.onLine) {
                //@ts-ignore
                if (navigator.connection.downlink < 1) {
                    setConexao_instavel(true);
                } else {
                    setConexao_instavel(false);
                }
            } else {
                setConexao_instavel(true);
            }
            setContar_tempo(contar_tempo=> contar_tempo + 1);
        }, 1000);

        if (progress === 100) {
            clearInterval(intervalId);
        }
    
        return () => {
            clearInterval(intervalId);
        };
    }, [progress]);

    useEffect(() => {
        if (new_progress === 0) {
          setInicio(Date.now()); // Define o tempo inicial
        }
    
        if (new_progress > 0 && new_progress < 100) {
          const agora = Date.now();
          const tempoDecorrido = (agora - inicio) / 1000; // Tempo decorrido em segundos
          const tempoTotalEstimado = (tempoDecorrido / new_progress) * 100; // Estima tempo total
          const restante = tempoTotalEstimado - tempoDecorrido; // Calcula tempo restante
          setTempoRestante(restante); // Atualiza estado com tempo restante
        }
    
        if (new_progress === 100) {
          setTempoRestante(0); // Tempo restante é zero ao completar
        }
      }, [new_progress, inicio]);
    
    

    if (!finalizado) {
        return (
            <ProgressContainer style={{justifyContent: justifyContent, height: height, width: width}} data-isWhite={isWhite}>
                <div className='ProgressContainer__conteudo' style={{flexDirection: direction}}>
                    
                    {new_progress===100
                        ? <img alt='Finalizado' src={successIcon} className='ProgressContainer__conteudo__success' />
                        : <Spin size={size ?? 'default'} />
                    }
                
                    <div className='PC__conteudo__barContainer'>
                        <div className='PC__conteudo__barContainer__track'>
                            <div className='PC__conteudo__barContainer__track__bar' style={{width: `${new_progress}%`}} />
                        </div>
                        <span className='PC__conteudo__barContainer__percent'>
                            {new_progress}%
                        </span>
                    </div>

                    <span className='ProgressContainer__conteudo__legenda' style={{animation: (!animation || progress===100) && 'none'}}>
                        {new_progress===100 ? 'Finalizado' : legenda} {conexao_instavel?'(internet lenta)':''}
                    </span>
                    {(tempoRestante !== null && new_progress < 100 && contar_tempo >2) && (
                        <span className='ProgressContainer__conteudo__estimado'>Tempo estimado restante: {Math.trunc(tempoRestante??0)} segundos</span>
                    )}
                    
                </div>
            </ProgressContainer>
        )
    }
    
    return null;
}

export default ProgressLoading;