import Icon from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Empty, Modal, Popconfirm } from "antd";
import { faDownload, faEye, faMapLocationDot, faPencil, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { TabelaSafra } from "./style";

//APP
import { removerAcentos } from "../../../../../utils";
import { ItensPerUserLogged } from "../../../../../services/login/permissaoUsuarioLoado";
import { useDeleteTalhaoSafra } from "../../../../../services/TalhaoSafra/DELETE";

//COMPONENTS
import Svg from "../../../../../Components/talhasvg";
import Input from "../../../../../Components/CustomUi/input";
import Carregando from "../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../Components/ButtonCustom";
import { Box } from "../../../../../Components/CustomUi/box";
import { Render } from "../../../../../Components/CustomUi/render";
import { Inline } from "../../../../../Components/CustomUi/inline";
import { Select } from "../../../../../Components/CustomUi/Select";
import { Actions } from "./components/actions";
import { RowItem } from "../../../../../Components/CustomUi/table/rowItem";
import { TitleH2 } from "../../../../../Components/CustomUi/titleH2";
import { RowTable } from "../../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../../Components/CustomUi/table/bodyTable";
import { HeaderTable } from "../../../../../Components/CustomUi/table/headerTable";
import { ConfirmarExclusao } from "./components/confirmarExclusao";

//PROPS
interface Props {
    disabled_action_buttons: boolean;
    loading_action_buttons: boolean;
    permisao_inclusao: boolean;
    novo_onClick: Function;
    importar_onClick: Function;
    desenhar_onClick: Function;
    ver_propriedade_onClick: Function;
    ver_todas_propriedade_onClick: Function;
    ver_todas_propriedades_loading: boolean;
    ver_todas_propriedades_disabled: boolean;
    pulverizacao_onClick: Function;
    pulverizacao_hidden: boolean;
    dadosEstatisticas: any;
    dadosResultado: any;
    dataTalhao: any[];
    nomePropriedade: string;
    list_propriedades: any[];
    obterListaTalhoes: Function;
    list_talhoes: any[],
    permissions: ItensPerUserLogged;
    handleClickVisuTalhao: Function;
    handleClikBaixarKml: Function;
    handleTrashTalhao: Function;
    atualizarLista: Function;
    carregando_lista_talhao: boolean;
}

export const TabelaListaTalhoes: React.FC<Props> = ({ disabled_action_buttons, permisao_inclusao, loading_action_buttons, 
    novo_onClick, importar_onClick, desenhar_onClick, ver_propriedade_onClick, ver_todas_propriedade_onClick, 
    ver_todas_propriedades_loading, ver_todas_propriedades_disabled, pulverizacao_onClick, pulverizacao_hidden,
    dadosEstatisticas, dadosResultado, dataTalhao, nomePropriedade, list_propriedades, obterListaTalhoes, list_talhoes, 
    permissions, handleClickVisuTalhao, handleClikBaixarKml, atualizarLista, carregando_lista_talhao
 }) => {

    //STATES
    const [buscar_talhao, setBuscar_talhao] = useState<string>('');
    const [nome_propriedade, setNome_propriedade] = useState<string>('');
    const [open_propriedade, setOpen_propriedade] = useState<boolean>(false);
    const [fake_loading, setFake_loading] = useState<boolean>(false);
    const [item_fake_loading, setItem_fake_loading] = useState<number|null>(null);
    const [pop_confirmar_exclusao, setPop_confirmar_exclusao] = useState<boolean>(false);
    const [item_cod_deletar, setItem_cod_deletar] = useState<number|null>(null);
    const [erro_ao_deletar, setErro_ao_deletar] = useState<boolean>(false);
    const [cod_talhao_atual, setCod_talhao_atual] = useState<number|null>(null);
    const [cod_propriedade_atual, setCod_propriedade_atual] = useState<number|null>(null);
    const [nome_talhao, setNome_talhao] = useState<string>('');
    const [resposta_erro, setResposta_erro] = useState<string>('');

    //API
    const { mutate_deletar_talhao_safra, carregando_deletar_talhao_safra } = useDeleteTalhaoSafra();

    //FUNCTIONS
    function baixarKML(arquivo:string, nome:string, cod:number, kml:any) {
        handleClikBaixarKml(arquivo, nome, cod, kml.coordenadas)
        setTimeout(() => {
            setFake_loading(false)
        }, 1000);
    }

    function falhaAoExcluir(message: string) {
        setResposta_erro(message);
        setItem_cod_deletar(null);
        setPop_confirmar_exclusao(false);
        setErro_ao_deletar(true);

    }

    function deletarTalhao(cod_talhao: number, cod_propriedade: number) {
        setErro_ao_deletar(false);
        setCod_talhao_atual(cod_talhao);
        setCod_propriedade_atual(cod_propriedade);
        mutate_deletar_talhao_safra({
            codPro: cod_propriedade,
            codTalhao: cod_talhao,
            atualizarLista: ()=>{atualizarLista(cod_propriedade, '')},
            falhaAoExcluir: falhaAoExcluir,
            closePopup: ()=>{setPop_confirmar_exclusao(true);setItem_cod_deletar(null)}
        });   
    }

    function buscarTalhao(buscar_nome: string) {
        const palavraSemAcentos = removerAcentos(buscar_nome)
        return function (item) {
            const nomeSemAcentos = removerAcentos(String(item?.nome))
            return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || 
               String(item?.area).toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
        }
    }

    //EFFETCS
    useEffect(() => {
        if ((list_propriedades??[]).length === 1) {
            setNome_propriedade(list_propriedades[0].nome);
            obterListaTalhoes(list_propriedades[0].cod);
        }
    }, [list_propriedades])
    
    return (
        <TabelaSafra>

            <Box style={{marginTop: 7}} gap={10}>
                <TitleH2>Lista de talhões</TitleH2>

                <Actions 
                    disabled_action_buttons={disabled_action_buttons}
                    loading_action_buttons={loading_action_buttons}
                    permisao_inclusao={permisao_inclusao}
                    
                    novo_onClick={novo_onClick}
                    importar_onClick={importar_onClick}
                    desenhar_onClick={desenhar_onClick}
                    ver_propriedade_onClick={ver_propriedade_onClick}
                    ver_todas_propriedade_onClick={ver_todas_propriedade_onClick}
                    ver_todas_propriedades_loading={ver_todas_propriedades_loading}
                    ver_todas_propriedades_disabled={ver_todas_propriedades_disabled}
                    pulverizacao_onClick={pulverizacao_onClick}
                    pulverizacao_hidden={pulverizacao_hidden}
                    dadosEstatisticas={dadosEstatisticas}
                    dadosResultado={dadosResultado}
                    dataTalhao={dataTalhao}
                    nomePropriedade={nomePropriedade}
                />

                <Inline>
                    <Select 
                        placeholder="Selecione a propriedade"
                        value={nome_propriedade}
                        onChange={setNome_propriedade}
                        open={open_propriedade}
                        trigger={setOpen_propriedade}
                        descricaoItem="nome"
                        idItem="cod"
                        items={list_propriedades??[]}
                        width={'30%'}
                        icon={faMapLocationDot}
                        extraItem={'cod'}
                        onChangeExtra={obterListaTalhoes}
                        allowClear={false}
                    />
                    <Input
                        placeholder='Buscar talhão'
                        value={buscar_talhao}
                        onChange={setBuscar_talhao}
                        width={'70%'}
                    />
                </Inline>

                <HeaderTable 
                    style={{marginTop:-15}}
                    itens={[
                        {valor: 'Talhão', width: 80.5, id: 'contorno_talhao'},
                        {valor: 'Nome', width: 'calc(100% - 551.5px)', id: 'nome_talhao'},
                        {valor: 'Área (ha)', width: 105.5, id: 'area_talhao'},
                        {valor: 'Ações', width: 360, id: 'options_talhao'},
                    ]}
                />

                <BodyTable
                    style={{height: 'calc(100vh - 365px)',marginTop:-10, overflowY: 'scroll'}}
                >

                    <Render condition={(list_talhoes??[]).length === 0 && !carregando_lista_talhao}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={nome_propriedade===''? <>Nenhuma propriedade foi selecionada!</> : <>Nenhuma talhão cadastrado!</>} />
                    </Render>
                    <Render condition={(list_talhoes??[]).length > 0 && !carregando_lista_talhao && (list_talhoes??[]).filter(buscarTalhao(buscar_talhao)).length === 0}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> <b>'{buscar_talhao}'</b> não encontrado!</>} />
                    </Render>

                    <Render condition={carregando_lista_talhao}>
                        <Carregando 
                            animation
                            legenda="Carregando talhões"
                            justifyContent="center"
                            height={300}
                        />
                    </Render>

                    <Render condition={!carregando_lista_talhao}>
                        {(list_talhoes??[]).filter(buscarTalhao(buscar_talhao)).map(({ cod, nome, area, kml, arquivo, cod_propriedade })=> {
                            return (
                                <RowTable key={cod} style={{height: 70}} onDoubleClick={()=>{handleClickVisuTalhao(cod_propriedade, cod)}}>
                                    <RowItem width={80}>
                                        <Icon component={() => <Svg coordinates={kml?.coordenadas} fill='#70ce9f' />} />{/* NOSONAR */}
                                    </RowItem>
                                    <RowItem width={'calc(100% - 545px)'}>
                                        {nome}
                                    </RowItem>
                                    <RowItem width={105}>
                                        {area?.replace('.', ',')}
                                    </RowItem>
                                    <RowItem width={360} gap={10}>
                                        <ButtonCustom 
                                            onClick={()=>{setFake_loading(true);setItem_fake_loading(cod);baixarKML(arquivo, nome, cod, kml)}}
                                            type='tertiary'
                                            icon={<FontAwesomeIcon icon={faDownload} />}
                                            disabled={kml === null || (fake_loading && item_fake_loading===cod)}
                                            loading={fake_loading && item_fake_loading===cod}
                                        >
                                            Baixar kml
                                        </ButtonCustom>
                                        <Popconfirm
                                            placement="topRight"
                                            title={`Tem certeza que deseja excluir este talhão?`}
                                            open={pop_confirmar_exclusao && item_cod_deletar === cod}
                                            description={
                                            <div style={{display:'flex',flexDirection:'column',gap:15}}>
                                                <span>Ao confirmar a exclusão a operação não poderá ser desfeita!</span>
                                                <Inline justifyContent="flex-end">

                                                    <ButtonCustom 
                                                        onClick={()=>{setPop_confirmar_exclusao(false)}}
                                                        type="tertiary"
                                                        disabled={carregando_deletar_talhao_safra}
                                                        icon={<FontAwesomeIcon icon={faXmark} />}
                                                    >
                                                        Cancelar
                                                    </ButtonCustom>

                                                    <ButtonCustom 
                                                        value="Deletar"
                                                        danger
                                                        onClick={()=>{deletarTalhao(cod, cod_propriedade);setNome_talhao(nome)}}
                                                        icon={<FontAwesomeIcon icon={faTrash} /> }
                                                        loading={carregando_deletar_talhao_safra}
                                                        disabled={carregando_deletar_talhao_safra}
                                                    >
                                                        Sim, excluir
                                                    </ButtonCustom>

                                                </Inline>
                                            </div>}
                                            okButtonProps={{style: {display:'none'}}}
                                            cancelButtonProps={{style: {display:'none'}}}
                                        >
                                            <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none',alignSelf:'flex-start'}}>
                                            <ButtonCustom 
                                                onClick={()=>{setItem_cod_deletar(cod);setPop_confirmar_exclusao(true)}}
                                                // onClick={()=>{handleTrashTalhao(cod_propriedade, cod)}}
                                                danger
                                                type="primary"
                                                icon={<FontAwesomeIcon icon={faTrash} />}
                                                hidden={permissions?.exclusao === 0}
                                            >
                                                Excluir
                                            </ButtonCustom>
                                            </Button>
                                        </Popconfirm>
                                        
                                        <ButtonCustom 
                                            onClick={()=>{handleClickVisuTalhao(cod_propriedade, cod)}}
                                            icon={<FontAwesomeIcon icon={permissions?.acesso === 1 ? faEye : faPencil} />}
                                            hidden={permissions?.alteracao === 0 && permissions?.acesso === 0}
                                        >
                                            {permissions?.acesso === 1 ? 'Detalhes' : 'Editar'}
                                        </ButtonCustom>
                                    </RowItem>
                                </RowTable>
                            )
                        })}
                    </Render>
                    
                </BodyTable>
                
            </Box>

            <Modal
                open={erro_ao_deletar}
                destroyOnClose
                onCancel={()=>setErro_ao_deletar(false)}
                title='Será necessário informar o motivo'
                footer={false}
                width={550}
            >
                <ConfirmarExclusao 
                    onClose={()=>{setErro_ao_deletar(false)}}
                    cod_talhao={cod_talhao_atual}
                    cod_propriedade={cod_propriedade_atual}
                    nome_talhao={nome_talhao}
                    atualizarLista={atualizarLista}
                    resposta_erro={resposta_erro}
                />
            </Modal>

        </TabelaSafra>
    )
}