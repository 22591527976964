import styled from "styled-components";

export const ContentModalPraga = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10;
    position: sticky;
    top: 0;
    

    :is([data-encolher = true]) {
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        opacity: 0;
        transform: scale(0);
    }
`;

export const ColorCircle = styled.div`
    width: 22px;
    min-width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 0px;

    :is([data-cor = 'amarelo_claro']) {
        background: #fcff02;
        color: #B35600;
        font-weight: 600;
    }
    :is([data-cor = 'amarelo_forte']) {
        background: #fcb700;
        color: #8F3200;
        font-weight: 600;
    }
    :is([data-cor = 'verde_claro']) {
        background: #b8ff05;
        color: #00572A;
        font-weight: 600;
    }
    :is([data-cor = 'verde_forte']) {
        background: #01FF00;
        color: #004D25;
        font-weight: 600;
    }
    :is([data-cor = 'vermelho']) {
        background: #fb0303;
        color: #fff;
        font-weight: 600;
    }

`;