import styled from "styled-components"

//STYLES
const InlineDiv = styled.div`
    display: flex;
    gap: 10px;
`;

//PROPS
interface Props {
    children: React.ReactNode;
    gap?: number;
    alignItems?: 'center' | 'flex-start' | 'flex-end';
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
    reverse?: boolean; 
    style?: React.CSSProperties;
}

export const Inline: React.FC<Props> = ({ children, gap, alignItems, justifyContent, reverse, style }) => {
    return (
        <InlineDiv style={{ 
            gap: gap, 
            alignItems: alignItems, 
            justifyContent: justifyContent, 
            flexDirection: reverse ? 'row-reverse' : undefined,
            ...style
        }}>
            {children}
        </InlineDiv>
    )
}