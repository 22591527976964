import styled from "styled-components";

export const Monitoramento = styled.div`
    position: absolute;
    top: 7px;
    left: 7px;
    background: #fff;
    width: 620px;
    height: calc(100vh - 20px);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
    transition: .2s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 996 !important;
    animation: 1s entrance_monitoramento cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

    @keyframes entrance_monitoramento {
        0% {
            opacity: 0;
            transform: translateX(350px);
        }
        100% {
            opacity: 1;
            transform: translateX(0px);
        }
    }

    :is([data-encolher = true]) {
        width: 190px;
        height: 60px;
    }

    div.Monitoramento__loading {
        width: 100%;
        height: calc(100% + 20px);
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        top: -10px;
        left: 0;
        display: flex;
        align-items: center;
        z-index: 2;
}
`;