//STYLE
import { TextAreaWrapper } from './styled'

//PROPS
interface Props {
  label?: string
  placeholder?: string
  value: string
  onChange: React.Dispatch<React.SetStateAction<string>>
  height?: number | string
  maxLength?: number
  maxHeight?: number | string
  resize?: boolean
  disabled?: boolean
  onKeyUp?: Function
  width?: number | string
  maxWidth?: number | string
}

const TextArea: React.FC<Props> = ({
  label,
  value,
  onChange,
  height,
  maxLength,
  maxHeight,
  maxWidth,
  width,
  placeholder,
  resize,
  disabled,
  onKeyUp,
}) => {
  const decodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = text
    return textArea.value
  }

  return (
    <TextAreaWrapper>
      {!!label && <label className='TextAreaWrapper__label'>{label}</label>}

      <textarea
        className='TextAreaWrapper__textarea'
        placeholder={placeholder}
        value={decodeHTMLEntities(value)}
        onChange={(e) => onChange(e.currentTarget.value)}
        maxLength={maxLength}
        style={{
          width: width,
          height: height,
          maxHeight: maxHeight,
          resize: resize === false ? 'none' : undefined,
        }}
        disabled={disabled}
        onKeyUp={() => {
          onKeyUp?.()
        }}
      />
      {maxLength > 0 && value?.length > 0 ? <div style={{display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
        <span style={{fontSize: '11px', padding: 0, margin: 0}}>{value?.length} / {maxLength}</span>
      </div>: null}
    </TextAreaWrapper>
  )
}

export default TextArea
