import ReactDOM from 'react-dom';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//ASSETS
import ttivos from '../../../../assets/img/logo.png';

//STYLES
import { BarContainer, ModalDetalhes } from "./styled";

//APP
import { leadZero } from '../../../../utils';
import { TypeDetailsMonitoramento } from '../../../../features/Monitoramento/Monitoramento.dataDetails';

//COMPONENTS
import ButtonCustom from '../../../ButtonCustom';
import { Box } from '../../../CustomUi/box';
import { TitleH4 } from '../../../CustomUi/titleH4';

//PROPS
interface Props {
    nome_talhao: string;
    nome_praga: string;
    data_selecionada: string;
    onClose: Function;
    dataDetails: TypeDetailsMonitoramento;
    visibleChangeBooking: boolean;
}

export const Detalhes: React.FC<Props> = ({ onClose, nome_talhao, nome_praga, data_selecionada, dataDetails, visibleChangeBooking }) => {

    //CONST
    const cores: string[] = ['#01ff00', '#fcff02', '#fcb700', '#fb0303'];
    const tabela_notas: {header:string,row:string}[] = [
        {header:'Minimo', row: dataDetails?.media?.min.toFixed(1)}, 
        {header:'Média', row: parseFloat(String(dataDetails?.media?.med)).toFixed(1)}, 
        {header:'Máximo',row: dataDetails?.media?.max.toFixed(1)}
    ];
    const tabela_dados_coleta:  {header:string,row:string|number}[] = [
        {header: 'Pontos monitorados', row: leadZero(dataDetails.qtdPontos)},
        {header: 'Pontos acima do nível de controle', row: leadZero(dataDetails?.pontosAcima)},
        {header: 'Pontos abaixo do nível de controle', row: leadZero(dataDetails?.pontosAbaixo)},
        {header: 'Escala invertida', row: `${dataDetails?.inverterEscala ? 'SIM': 'NÃO'}`},
        {header: 'Data do monitoramento', row: dataDetails?.data},
        {header: 'Estádio(s)', row: dataDetails?.estadios},
    ];

    return ReactDOM.createPortal(

        <ModalDetalhes  data-ocultar={visibleChangeBooking}>
            <div className='ModalDetalhes__header'>
                
                <h4 className='MD__header__title'>
                    <span className='MD__header__title__light'>{nome_talhao}</span> 》<span className='MD__header__title__light'>{nome_praga}</span> 》{data_selecionada}
                </h4>

                <ButtonCustom 
                    type='tertiary'
                    onClick={()=>{onClose()}}
                    icon={<FontAwesomeIcon icon={faXmark} />}
                    danger
                />

            </div>

            <div className='ModalDetalhes__content'>
                
                <Box padding={15}>
                    <TitleH4 style={{color:'var(--text-color-light)'}}>Legenda</TitleH4>
                    <BarContainer>
                        <div className='BarContainer__bar'>
                            {cores.map(cor=> {
                                return (
                                    <div key={cor} style={{width:'100%',height:'100%',background:cor}} />
                                )
                            })}
                        </div>
                        <div className='BarContainer__lagenda'>
                            <span className='BarContainer__lagenda__label'>Abaixo do NC</span>
                            <span className='BarContainer__lagenda__label'>Nível de controle</span>
                            <span className='BarContainer__lagenda__label'>Acima do NC</span>
                        </div>
                    </BarContainer>
                    
                </Box>

                <TitleH4 style={{color:'var(--text-color-light)'}}>Notas da análise</TitleH4>
                <div className='MD__content__tabela_notas'>
                    {tabela_notas.map(({header, row})=> {
                        return (
                            <div key={header} className='MD__content__tabela_notas__column'>
                                <div className="MD__c__tabela_notas__column__header">
                                    {header}
                                </div>
                                <div className="MD__c__tabela_notas__column__row">
                                    {row}
                                </div>
                            </div>
                        )
                    })}
                </div>

                <TitleH4 style={{color:'var(--text-color-light)'}}>Detalhes da coleta</TitleH4>
                <div className='MD__content__coleta'>
                    {tabela_dados_coleta.map(({header, row})=> {
                        return (
                            <div key={header} className='MD__content__tabela_coleta__column'>
                                <div className="MD__c__tabela_coleta__column__header">
                                    {header}
                                </div>
                                <div className="MD__c__tabela_coleta__column__row" >
                                    {row}
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='MD__content__tabela_notas__logo'>
                    <img alt='Ttivos gestão rural' src={ttivos} className='MD__content__tabela_notas__logo__img' />
                </div>
                
            </div>

        </ModalDetalhes>,

        document.getElementById('portal')
      );
}