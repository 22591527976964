import ReactDOM from 'react-dom';
import { CarrouselContainer, MediaContainer, Overlay } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { gerarKey } from '../TabsRadio/meta';
import { useState } from 'react';
import ButtonCustom from '../../ButtonCustom';

//PROPS
interface Props {
    slides: { media: string, type?: string, description?: string }[];
    slide_inicial?: number;
    onClose: Function;
}

export const SlidesFullScreen: React.FC<Props> = ({ slides, slide_inicial=0, onClose }) => {

    const [slide_atual, setSlide_atual] = useState<number>(slide_inicial);

    function avancarSlide() {
        let qtd_slides = slides?.length;
        if ((slide_atual+1) === qtd_slides) {
            setSlide_atual(0);
        }
        else {
            setSlide_atual(slide_atual=> slide_atual + 1)
        }
    }
    function retrocederSlide() {
        let qtd_slides = slides?.length;
        if (slide_atual === 0) {
            setSlide_atual(qtd_slides - 1);
        }
        else {
            setSlide_atual(slide_atual=> slide_atual - 1);
        }
    }

    return ReactDOM.createPortal(
        <Overlay>
            <ButtonCustom 
                icon={<FontAwesomeIcon icon={faXmark} />}
                onClick={()=>onClose?.()}
                danger
                style={{
                    position:'absolute',
                    right: 15,
                    top: 15,
                    transform: 'scale(1.2)'
                }}
            />
            <MediaContainer data-single={(slides??[]).length < 2}>
                <button className='MediaContainer__navigator' onClick={()=>{retrocederSlide()}}>
                    <FontAwesomeIcon icon={faAngleLeft} className='MC__navigator__arrow' />
                </button>

                <div className='MediaContainer__slide'>
                    <div className='MediaContrainer__media'>
                        <img src={slides?.[slide_atual]?.media} alt={`media_${slide_atual}`} className='MC__media__img'/>
                        <img src={slides?.[slide_atual]?.media} alt={`media_${slide_atual}`} className='MC__media__img_ambient'/>
                    </div>
                    <p>{slides?.[slide_atual]?.description}</p>
                </div>
                
                
              
                <button className='MediaContainer__navigator' onClick={()=>{avancarSlide()}}>
                    <FontAwesomeIcon icon={faAngleRight} className='MC__navigator__arrow' />
                </button>
            </MediaContainer>

            <CarrouselContainer  data-single={(slides??[]).length < 2}>
                <div className='CarrouselContainer__carrousel'>
                    {slides.map(({ media }, index)=> {
                        return (
                            <button 
                                className='CC__carrousel__item' 
                                key={gerarKey(index)} 
                                data-active={slide_atual===index}
                                onClick={()=>{setSlide_atual(index)}}
                            >
                                <img src={media} alt={`thumb_${index}`} className='CC__carrousel__item__thumbnail'/>
                            </button>
                        )
                    })}
                </div> 
            </CarrouselContainer>

        </Overlay>,

        document.getElementById('portal')
    );
}