import styled from 'styled-components'

export const BodyDrawer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .form__edit__desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .campo__check {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 3px;

    .check {
      border: 1px solid var(--border-color);
      border-radius: 5px;
      box-sizing: border-box;
      padding: 10px;
    }
    label {
      font-size: 13px;
      font-weight: 500;
      color: var(--text-color);
      display: inline-flex;
      gap: 5px;
    }
  }

  .campo__salvar__deletar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
