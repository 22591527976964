import React, { useEffect, useRef, useState } from 'react'

import Icon, { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import {
  faBug,
  faCheck,
  faCopy,
  faDisease,
  faPen,
  faSeedling,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  TreeSelect,
  message,
} from 'antd'
import { AxiosResponse } from 'axios'
import { orderBy } from 'lodash'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import {
  ItemMoniNivelControl,
  MoniNivelControleItens,
  TypeMoniNivelControle,
  getAttMoniNivelControle,
  getListEstadios,
  getMoniNivelControle,
  postMoniNivelControle,
  putMoniNivelControle,
} from '../../../services/monitoramento/nivelControle'
import { Notification } from '../../notification'

import { decodeHtml, filterTable, tiposGerais, tryError } from '../../../services/Afins'
import { EstadioItens, TypeEstadios, getEstadios } from '../../../services/estadio'
import {
  MoniEspecieItens,
  TypeMoniEspecie,
  getMoniEspecie,
} from '../../../services/monitoramento/especie'
import {
  postMoniNivelControleItem,
  putMoniNivelControleItem,
  getTiposSistema,
} from '../../../services/monitoramento/nivelControleItens'
import Icons from '../../Icons'
import { IconAlert } from '../../iconsAlert'
import { Divider } from 'antd/lib'
import DiasDesdeData from '../../DiasDesdeData'
import { DivCultura } from '../styles'
import { gerarKey } from '../../../utils'

interface TypesEspecie {
  atualiza: number
  codEstadio?: number
  codCultura: number
  visible: boolean
  onClose: (data: boolean) => void
  listNivelControle: any[]
}

const { TreeNode } = TreeSelect

const tagRender = (props: CustomTagProps, color: string) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  )
}

const MoniNivelControle: React.FC<TypesEspecie> = (props) => {
  const { atualiza, onClose, visible, listNivelControle, codCultura, codEstadio } = props

  const refRowTable = useRef<HTMLTableRowElement[]>([])

  const [update, setUpdate] = useState(atualiza)

  const [drawerVisible, setDrawerVisible] = useState(false)

  const [loadingEdit, setLoadingEdit] = useState(false)

  const [validateForms, setValidateForms] = useState(true)

  const [dataItemNivelControle, setDataItemNivelControle] = useState([])

  const [loadingSaveEspecie, setLoadingSaveEspecie] = useState(false)

  const [metricaValue, setMetricaValue] = useState(null)
  const [tipoMetrica, setTipoMetrica] = useState(null)

  const [expandedRowKeys, setExpandedRowKeys] = useState()

  const [dataEstadioSelect, setDataEstadioSelect] = useState<number[]>([])
  const [dataEstadioNovo, setDataEstadioNovo] = useState([])

  const [propsMetrica, setPropsMetrica] = useState<any>({
    disabled: true,
  })

  const [dataCultura, setDataCultura] = useState([])

  const [itemsExcluidos, setItemsExcluidos] = useState([])

  const [dataEstadio, setDataEstadio] = useState<EstadioItens[]>([])

  const [especieVariSelect, setEspecieVariSelect] = useState({
    tipo: null,
    vari: null,
  })

  const [estadioRemover, setEstadioRemover] = useState([])

  const [items, setItems] = useState([])

  const [cultaSelected, setCultaSelected] = useState(0)

  const [dataEspecie, setDataEspecie] = useState<MoniEspecieItens[]>([])

  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataT, setData] = useState<Number>()
  const [metricaInicial, setMetricaInicial] = useState(null)
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)
  const [salvoApi, setSalvoApi] = useState(false)
  const [nomeCultura, setNomeCultura] = useState('')

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const [form] = Form.useForm<MoniNivelControleItens>()

  const [tipos, setTipos] = useState([])
  const [tipoObservado, setTipoObservado] = useState()
  const [tipoMonitoramento, setTipoMonitoramento] = useState()
  const [tipoTeste, setTeste] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  const listCultura = async () => {
    const resp = await tiposGerais()

    setDataCultura(resp)
  }

  const listEspecie = async (cod) => {
    setLoadingSaveEspecie(true)
    setDataEspecie([])
    form.resetFields(['cod_variacao_especie'])
    const resp: AxiosResponse<TypeMoniEspecie> = await getMoniEspecie({
      codCategoria: cod,
      embed: 'variacao',
    })

    listEstadio(codCultura)

    setDataEspecie(resp.data.itens)
    setLoadingSaveEspecie(false)
  }

  const listEstadio = async (codCultura) => {
    const resp: AxiosResponse<TypeEstadios> = await getEstadios({ codCultura, pagination: -1 })

    setDataEstadio(resp.data.itens)
  }

  const editNivelControle = async (data) => {
    setTeste(data)
    setLoadingEdit(true)

    const response: AxiosResponse<TypeMoniNivelControle> = await getMoniNivelControle({
      cod: data,
      embed: 'item',
    })

    const dados = response.data.itens[0]

    const itens = dados.item.map((rec) => {
      rec.edt = false
      return rec
    })

    const resp: AxiosResponse = await getListEstadios({
      data,
    })

    const dadosEstadios = resp.data.itens
    // AGRUPA E MONTA OS DADOS NECESSARIO DO NIVEL DE CONTROLE
    const agruparEstadios = dadosEstadios.reduce((acc, item) => {
      if (!acc[item.cod_nivel_controle_item]) {
        acc[item.cod_nivel_controle_item] = []
      }
      acc[item.cod_nivel_controle_item].push(item)
      return acc
    }, {})

    const dadosNovosEstadios = []
    const codsAdd = []

    itens.forEach((item1) => {
      dadosEstadios.forEach((item2) => {
        if (item1.cod === item2.cod_nivel_controle_item && !codsAdd.includes(item1.cod)) {
          const estadios = agruparEstadios[item1.cod] || []

          dadosNovosEstadios.push({
            ...item1,
            nivel_controle_item_estadio: estadios,
          })
          codsAdd.push(item1.cod) // Adiciona o ID ao array de IDs adicionados
        }
      })
    })
    setNomeCultura(dados.cultura_nome)
    form.setFieldsValue({ cod_cultura: dados.cod_cultura, item: dadosNovosEstadios })
    setCultaSelected(dados.cod_cultura)
    listEstadio(codCultura)
    setDataItemNivelControle(dadosNovosEstadios)

    setLoadingEdit(false)
  }

  // const attNivelControle = async (data) => {
  //   setLoadingEdit(true)

  //   const response: AxiosResponse<TypeMoniNivelControle> = await getAttMoniNivelControle({
  //     cod: data,
  //     embed: 'item',
  //   })

  //   const dados = response.data.itens[0]

  //   const itens = dados.item.map((rec) => {
  //     rec.edt = false
  //     return rec
  //   })

  //   setDataItemNivelControle(itens)

  //   setLoadingEdit(false)
  // }

  const tiposSistema = async () => {
    const response: AxiosResponse = await getTiposSistema({
      codTipo: 57,
    })

    const dados = response.data.itens
    setTipos(dados)
  }

  const handleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setItemsExcluidos([])
    setUpdate(0)
    setItemsExcluidos([])
    setDataEspecie([])
    setDataItemNivelControle([])
    setValidateForms(true)
    form.resetFields()
    setMetricaValue(null)
    setTipoMonitoramento(undefined)
    setTipoObservado(undefined)
    setData(undefined)
    setMetricaInicial(undefined)
    setTipoMetrica(undefined)
    setSalvoApi(false)
    setDataEstadioSelect([])
    setMetricaValue(null)
    setPropsMetrica({ disable: true })
  }

  const handleChangeCultura = (data) => {
    setCultaSelected(data)
    listEstadio(data)
  }

  const handleClickTree = (tipo = null, vari = null) => {
    setEspecieVariSelect({ tipo, vari })
  }

  const handleChangeMetrica = (data) => {
    form.resetFields(['valor'])
    if (data === 1) {
      // @ts-ignore
      form.setFieldsValue({ valor: 0 })
    }

    if (data === 3) {
      // @ts-ignore
      form.setFieldsValue({ valor: '0%' })
    }
    setMetricaValue(data)
  }

  const handleChangeEstadio = (data: any[]) => {
    const contem = [...items]

    let temp = []
    for (let ii = 0; ii < contem.length; ii += 1) {
      const codCo = contem[ii]

      const veri = data.find((info) => info === codCo)

      if (!veri) {
        temp = [...temp, codCo]
      }
    }

    setEstadioRemover(temp)
  }

  const onFinish = async (data) => {
    message.loading('Aguarde...', 99999)

    try {
      setLoadingEdit(true)
      let item = []
      data.item.forEach((record, i) => {
        let tempCod
        if (record.cod_variacao_especie) {
          tempCod = {
            cod_variacao_especie: parseInt(record.cod_variacao_especie, 10),
          }
        } else {
          tempCod = { cod_especie: parseInt(record.cod_especie, 10) }
        }
        item = [
          {
            cod_tipo_metrica: record.cod_tipo_metrica,
            totalizar: record.totalizar,
            inverter_escala: record.inverter_escala,
            ...tempCod,
            valor: record.valor,
            tipo_observado: tipoObservado,
            tipo_monitorado: tipoMonitoramento,
            estadios: i + 1 === data.item.length ? data.estadio : record.estadio,
          },
        ]

        return item
      })
      const dataFinish = {
        cod_cultura: data.cod_cultura,
        item,
      }

      if (update <= 0) {
        const response: AxiosResponse<TypeMoniNivelControle> = await postMoniNivelControle(
          dataFinish
        )
        if (response.status === 201) {
          Notification({
            message: 'Cadastrado com sucesso',
            type: 'success',
          })

          // form.setFieldsValue(response.data.itens[0])
          // setDataItemNivelControle([response.data.itens[0]]);
          // setUpdate(response.data.itens[0].cod)

          message.destroy()
          onClose(true)
        }
      } else {
        if (itemsExcluidos.length > 0) {
          // @ts-ignore
          dataFinish.item_remover = itemsExcluidos
        }

        const response: AxiosResponse<TypeMoniNivelControle> = await putMoniNivelControle(
          update,
          dataFinish,
          'item,nivel_controle_item_estadio'
        )
        if (response.status === 200) {
          Notification({
            message: 'Cadastrado com sucesso',
            type: 'success',
          })

          onClose(true)
          setItemsExcluidos([])
          // form.setFieldsValue(response.data.itens[0])
          // setDataItemNivelControle(response.data.itens)
          setValidateForms(false)

          message.destroy()
        }
      }
      setLoadingEdit(false)
    } catch (error) {
      message.destroy()
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const handleChangeTotalizador = (status, cod) => {
    const dados = [...dataItemNivelControle]
    for (let i = 0; i < dados.length; i += 1) {
      const vari = dados[i]
      if (vari.cod === cod) {
        vari.totalizar = !status ? 1 : 0
      }
    }

    setDataItemNivelControle(dados)
  }

  const handleChangeInverter = (status, cod) => {
    const dados = [...dataItemNivelControle]
    for (let i = 0; i < dados.length; i += 1) {
      const vari = dados[i]
      if (vari.cod === cod) {
        vari.inverter_escala = !status ? 1 : 0
      }
    }

    setDataItemNivelControle(dados)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const onTableRowExpand = (expanded, record) => {
    const keys: any = []

    if (expanded) {
      keys.push(record.cod)
      setDataEstadioSelect(record.nivel_controle_item_estadio.map((rec) => rec.cod_estadio))
    }
    setExpandedRowKeys(keys)
  }

  const OnChangeObservado = (value) => {
    setTipoObservado(value)
  }

  const OnChangeMonitorado = (value) => {
    setTipoMonitoramento(value)
  }

  useEffect(() => {
    setDrawerVisible(visible)
  }, [visible])

  useEffect(() => {
    if (atualiza > 0) {
      setUpdate(atualiza)
      editNivelControle(atualiza)
    } else {
      form.setFieldsValue({ cod_cultura: codCultura })
      const nomeCulturas = dataCultura.filter((item) => item.cod === codCultura)
      setNomeCultura(nomeCulturas[0]?.nome)
    }
  }, [atualiza, codCultura])

  useEffect(() => {
    let metri

    switch (metricaValue) {
      case 3:
        metri = {
          min: 0,
          max: 100,
          formatter: (value) => `${value}%`,
          parser: (value) => value.replace('%', ''),
        }
        break
      default:
        break
    }

    setPropsMetrica({ ...metri })
  }, [metricaValue])

  useEffect(() => {
    // form.setFieldsValue({ item: dataItemNivelControle })
    const edit = dataItemNivelControle.find((data, i) => data.edt === true)
  }, [dataItemNivelControle])

  useEffect(() => {
    if (codCultura > 0) {
      listEstadio(codCultura)
    }
  }, [codCultura])

  useEffect(() => {
    if (salvoApi == true) {
      setTimeout(() => {
        setTipoMonitoramento(undefined)
        setTipoObservado(undefined)
      }, 800)
    }
  }, [salvoApi])

  useEffect(() => {
    if (metricaInicial != tipoMetrica) {
      setData(undefined)

      setTipoMonitoramento(undefined)
      setTipoObservado(undefined)
    }
  }, [tipoMetrica])

  useEffect(() => {
    tiposSistema()
    listCultura()
  }, [])

  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Drawer
        placement='left'
        open={drawerVisible}
        closeIcon={false}
        contentWrapperStyle={{ minWidth: '1200px' }}
        onClose={handleDrawerClose}
        style={{ position: 'absolute' }}
        bodyStyle={{ overflow: 'hidden' }}
        getContainer={false}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button danger onClick={handleDrawerClose} type='primary' style={{ marginRight: 8 }}>
              Fechar
            </Button>

            <Space className='log-user'>
              {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                <>
                  <DiasDesdeData
                    dataCriacao={dataAlteracao}
                    onDataFromCreation={handleDataFromCreation}
                  />
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    atualizado por: {usuarioQueAlterou}{' '}
                    {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                      ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                      : `às ${horarioDeAtualizacao}h`}
                  </small>
                </>
              ) : !logUser && update > 0 ? (
                <>
                  {' '}
                  {usuarioQueCriou === '' ? null : (
                    <small style={{ opacity: '0.5', fontSize: '10px' }}>
                      criado por: {usuarioQueCriou}{' '}
                      {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                        ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                        : `às ${horarioDeCriacao}h`}
                    </small>
                  )}
                </>
              ) : null}
            </Space>
          </div>
        }
      >
        <Col span={24}>
          <Row hidden={!loadingEdit} gutter={[8, 55]}>
            <Col
              style={{
                minHeight: '60vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              span={24}
            >
              <Spin size='large' />
            </Col>
          </Row>
          <Row hidden={loadingEdit} gutter={[8, 0]}>
            <Col flex='0 1 200px'>
              <Form.Item
                rules={[
                  {
                    required: validateForms && dataItemNivelControle.length <= 0,
                  },
                ]}
                name='cod_categoria'
                label='Tipo'
              >
                <Select
                  onChange={listEspecie}
                  options={[
                    {
                      label: (
                        <>
                          <Icon component={() => <Icons icon={faBug} />} /> Pragas
                        </>
                      ),
                      value: 1,
                    },
                    {
                      label: (
                        <>
                          <Icon component={() => <Icons icon={faSeedling} />} /> Planta Daninha
                        </>
                      ),
                      value: 2,
                    },
                    {
                      label: (
                        <>
                          <Icon component={() => <Icons icon={faDisease} />} /> Doenças
                        </>
                      ),
                      value: 3,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col flex='0 1 200px'>
              <Form.Item rules={[{ required: true }]} name='cod_cultura' label='Cultura'>
                {/* <Select style={{overflow: 'hidden'}} value={cultaSelected} onChange={handleChangeCultura} disabled>
                  {dataCultura.map((data) => {
                    console.log("dataCultura: ", data.nome)
                    const check = listNivelControle.find(
                      (record) => data.cod === record.cod_cultura
                    )
                    return (
                      <div style={{border: '1px solid #d9d9d9', height: '30px', padding: '3px', borderRadius: '5px', backgroundColor: '#F5F5F5', color: '#C3C4C3'}}>
                      <p style={{marginLeft: '5px'}}>{data.nome}</p>
                      </div>
                    )
                  })}
                </Select> */}
                <DivCultura>
                  <p style={{ marginLeft: '5px' }}>{nomeCultura}</p>
                </DivCultura>
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                rules={[
                  {
                    required: validateForms && dataItemNivelControle.length <= 0,
                  },
                ]}
                name='cod_variacao_especie'
                label='Item'
              >
                <TreeSelect
                  treeLine={true && { showLeafIcon: false }}
                  showSearch
                  filterTreeNode={(search, item) => {
                    return String(item.key).toLowerCase().indexOf(search.toLowerCase()) >= 0
                  }}
                  disabled={dataEspecie.length <= 0}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                >
                  {dataEspecie.map((data, index) => {
                    let tipoPraga
                    switch (data.cod_categoria) {
                      case 1:
                        tipoPraga = faBug
                        break
                      case 2:
                        tipoPraga = faSeedling
                        break
                      case 3:
                        tipoPraga = faDisease
                        break

                      default:
                        break
                    }
                    return (
                      <TreeNode
                        selectable={!(data.variacao.length > 0)}
                        title={
                          <div onClick={() => handleClickTree(index, null)}>
                            <Icon component={() => <Icons icon={tipoPraga} />} /> {data.nome}
                          </div>
                        }
                        key={data.nome}
                        value={`C${data.cod}`}
                      >
                        {data.variacao.map((vari, ii) => (
                          <TreeNode
                            key={vari.nome}
                            value={`v${vari.cod}`}
                            title={
                              <div onClick={() => handleClickTree(index, ii)}>
                                <Icon component={() => <Icons icon={tipoPraga} />} />{' '}
                                {`${data.nome} > ${vari.nome}`}
                              </div>
                            }
                            treeLine={false}
                          />
                        ))}
                      </TreeNode>
                    )
                  })}
                </TreeSelect>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row gutter={[9, 9]}>
                <Col span={12}>
                  <Form.Item name='estadio' label='Estádio'>
                    <Select
                      disabled={dataEspecie.length <= 0}
                      showSearch
                      onChange={handleChangeEstadio}
                      optionFilterProp='filter'
                      mode='multiple'
                    >
                      {dataEstadio.map((data) => (
                        <Select.Option key={data.cod} value={data.cod} filter={data.nome}>
                          <Space>
                            <div className='nameNivel' style={{ width: '300px' }}>
                              {data.nome}
                            </div>
                          </Space>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item
                    rules={[
                      {
                        required: validateForms && dataItemNivelControle.length <= 0,
                      },
                    ]}
                    name='cod_tipo_metrica'
                    label='Métrica'
                  >
                    <Select onChange={handleChangeMetrica}>
                      <Select.Option value={1} key={1}>
                        Unidade
                      </Select.Option>
                      <Select.Option value={2} key={2}>
                        Escala
                      </Select.Option>
                      <Select.Option value={3} key={3}>
                        Porcentagem
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={2}>
                  <Form.Item initialValue={0} name='valor' label='Valor'>
                    {metricaValue === 2 ? (
                      <Select
                        options={[
                          {
                            children: '0 - Ausente',
                            label: '0 - Ausente',
                            value: 0,
                          },
                          {
                            children: '1 - Baixo',
                            label: '1 - Baixo',
                            value: 1,
                          },
                          {
                            children: '2 - Médio',
                            label: '2 - Médio',
                            value: 2,
                          },
                          {
                            children: '3 - Alto',
                            label: '3 - Alto',
                            value: 3,
                          },
                          {
                            children: '4 - Muito Alto',
                            label: '4 - Muito Alto',
                            value: 4,
                          },
                        ]}
                      />
                    ) : metricaValue === 1 ? (
                      <InputNumber decimalSeparator=',' width='10%' />
                    ) : (
                      <InputNumber decimalSeparator=',' width='10%' {...propsMetrica} />
                    )}
                  </Form.Item>
                </Col>

                <Col span={3}>
                  {metricaValue === 3 ? (
                    <Form.Item name='tipo_observado' label='O que avaliar'>
                      <Select
                        value={tipoObservado}
                        onChange={OnChangeObservado}
                        options={tipos.map((data) => {
                          return { label: data.nome, value: data.cod }
                        })}
                      />
                    </Form.Item>
                  ) : null}
                </Col>

                <Col span={3}>
                  {metricaValue === 3 ? (
                    <Form.Item name='tipo_monitorado' label='Em que avaliar'>
                      <Select
                        value={tipoMonitoramento}
                        onChange={OnChangeMonitorado}
                        options={tipos.map((data) => {
                          return { label: data.nome, value: data.cod }
                        })}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Card size='small'>
                <Form.List initialValue={[]} name='item'>
                  {(list, { add, remove }) => {
                    const onAddEspecie = async (estadio = []) => {
                      try {
                        form.validateFields()
                        const items = JSON.parse(JSON.stringify(dataItemNivelControle))

                        const codEspecie = Number(
                          form.getFieldValue('cod_variacao_especie').replace(/[^0-9]/g, '')
                        )
                        let nomeEspecie = null

                        const dadosTipo = dataEspecie[especieVariSelect.tipo]

                        if (especieVariSelect.vari !== null) {
                          nomeEspecie = dadosTipo.variacao[especieVariSelect.vari].nome
                        }

                        let cateNome
                        switch (form.getFieldValue('cod_categoria')) {
                          case 1:
                            cateNome = 'PRAGA'
                            break
                          case 2:
                            cateNome = 'PLANTA DANINHA'
                            break
                          case 3:
                            cateNome = 'DOENÇAS'
                            break

                          default:
                            break
                        }

                        let metrica
                        switch (form.getFieldValue('metrica')) {
                          case 1:
                            metrica = 'Unidade'
                            break
                          case 2:
                            metrica = 'Escala'
                            break
                          case 3:
                            metrica = 'Porcentagem'
                            break

                          default:
                            break
                        }

                        const vari =
                          nomeEspecie === null
                            ? {
                                cod_especie: codEspecie,
                                cod_variacao_especie: null,
                              }
                            : {
                                cod_variacao_especie: codEspecie,
                              }
                        const data = {
                          cod: null,
                          categoria_nome: cateNome,
                          // @ts-ignore
                          especie_nome: dadosTipo.nome,
                          valor: parseFloat(form.getFieldValue('valor')).toFixed(2),
                          tipo_metrica_nome: metrica,
                          variacao_especie_nome: nomeEspecie,
                          cod_tipo_metrica: form.getFieldValue('cod_tipo_metrica'),
                          tipo_observado: form.getFieldValue('tipo_observado'),
                          tipo_monitorado: form.getFieldValue('tipo_monitorado'),
                          ...vari,
                          ...(estadio.length > 0 ? { estadio } : { estadio: null }),
                        }
                        add(data)
                        items.push(data)
                        form.submit()
                        setDataItemNivelControle(items)

                        setEspecieVariSelect({ tipo: null, vari: null })
                        setValidateForms(!(form.getFieldValue('item')?.length > 0))
                        setMetricaValue(null)
                        setData(undefined)
                        setTimeout(() => {
                          form.resetFields()
                          editNivelControle(codEstadio === 0 ? tipoTeste : codEstadio)
                        }, 1000)
                        setDataEstadioSelect([])
                      } catch (error) {
                        tryError(error)
                      }
                    }

                    const onEditEspecie = (data, cod: number) => {
                      const dados: any = [...dataItemNivelControle]

                      // @ts-ignore
                      setExpandedRowKeys([cod])
                      const i = dados.findIndex((rec) => rec.cod === cod)

                      setDataEstadioSelect(
                        dados[i]?.nivel_controle_item_estadio?.map((rec) => rec?.cod_estadio)
                      )

                      for (let item_dado of dados) {
                        const item = item_dado;

                        item.edt = false

                        if (data.cod === item.cod) {
                          item.edt = true

                          setData(Number(item.valor))
                          setTipoMonitoramento(item.tipo_monitorado)
                          setTipoObservado(item.tipo_observado)
                          setMetricaInicial(item.cod_tipo_metrica)
                        }
                      }
                      setDataItemNivelControle(dados)
                    }

                    const onCloneEspecie = async (data, index) => {
                      const cultura = form.getFieldValue('cod_cultura')
                      const tipo = form.getFieldValue('cod_categoria')
                      const vari = form.getFieldValue('cod_variacao_especie')
                      const metrica = form.getFieldValue('cod_tipo_metrica')

                      if (tipo === undefined) {
                        Notification({
                          type: 'error',
                          message: 'Erro',
                          descricao: 'Selecione um tipo para clonar',
                        })
                      } else if (vari === undefined) {
                        Notification({
                          type: 'error',
                          message: 'Erro',
                          descricao: 'Selecione um item para clonar',
                        })
                      } else if (metrica === undefined) {
                        Notification({
                          type: 'error',
                          message: 'Erro',
                          descricao: 'Selecione uma métrica para clonar',
                        })
                      } else {
                        try {
                          let varicao
                          if (vari.substr(0, 1) === 'v') {
                            varicao = { cod_variacao_especie: Number(vari.substr(1)) }
                          } else {
                            varicao = { cod_especie: Number(vari.substr(1)) }
                          }
                          const dataPost = {
                            cod: null,
                            cod_cultura: cultura,
                            cod_nivel_controle: update,
                            ...varicao,
                            cod_tipo_metrica: metrica,
                            valor: data.valor,
                            totalizar: data.totalizar,
                            estadio: data.nivel_controle_item_estadio.map((rec) => rec.cod_estadio),
                          }
                          message.loading('Aguarde...', 99999)
                          const resp = await postMoniNivelControleItem(dataPost)

                          if (resp.status === 201) {
                            Notification({ message: 'Item clonado com sucesso', type: 'success' })

                            const dados = resp.data.itens[0]

                            dataPost.cod = dados.cod
                            dataPost.nivel_controle_item_estadio =
                              dados.nivel_controle_item_estadio.map((rec) => {
                                return { cod_estadio: rec }
                              })
                            dataPost.categoria_nome = dados.categoria_nome
                            dataPost.especie_nome = dados.especie_nome
                            dataPost.variacao_especie_nome = dados.variacao_especie_nome

                            setDataItemNivelControle([...dataItemNivelControle, dataPost])

                            message.destroy()
                          }
                        } catch (error) {
                          tryError(error)
                        }
                      }
                    }

                    const onChangeEstadio = (data, index) => {
                      setDataEstadioSelect(data)

                      const dados = JSON.parse(JSON.stringify(dataItemNivelControle))
                      const cods = data.map((item) => {
                        return { cod_estadio: item }
                      })

                      dados[index].nivel_controle_item_estadio = [...cods]

                      setDataItemNivelControle(dados)
                    }

                    const onChangeItemCheckBox = (checked, cod, index) => {
                      const dados = [...dataItemNivelControle]

                      if (checked) {
                        const remover = estadioRemover.filter((rec) => rec !== cod)

                        setEstadioRemover(remover)
                      } else {
                        const empty = estadioRemover.filter((rec) => rec === cod)

                        if (empty.length <= 0) {
                          setEstadioRemover([...estadioRemover, cod])
                        }
                      }

                      setDataItemNivelControle(dados)
                    }

                    const onSaveEspecie = async (data, cod) => {
                      try {
                        message.loading('Aguarde...', 999999)
                        const dados: ItemMoniNivelControl[] = JSON.parse(
                          JSON.stringify(dataItemNivelControle)
                        )

                        const index = dados.findIndex((rec) => rec.cod === cod)

                        const dataNivel =
                          dados[index].cod_variacao_especie === null
                            ? { cod_especie: dados[index].cod_especie }
                            : {
                                cod_variacao_especie: dados[index].cod_variacao_especie,
                              }
                        const put = {
                          ...dataNivel,
                          inverter_escala: data.inverter_escala,
                          totalizar: data.totalizar,
                          cod_tipo_metrica: data.cod_tipo_metrica,
                          valor: dados[index].valor,
                          estadios: dataEstadioSelect,
                          estadios_remover: estadioRemover,
                          tipo_observado: tipoObservado,
                          tipo_monitorado: tipoMonitoramento,
                        }

                        try {
                          const resp = await putMoniNivelControleItem(data.cod, put)
                          if (resp.status === 200) {
                            Notification({
                              message: 'Variação atualizada',
                              type: 'success',
                            })

                            setEstadioRemover([])
                            setTipoObservado(undefined)
                            setTipoMonitoramento(undefined)
                            dados[index].edt = false
                            dados[index].nivel_controle_item_estadio =
                              resp.data.itens[0].nivel_controle_item_estadio

                            editNivelControle(tipoTeste)
                            setData(undefined)
                            setMetricaInicial(undefined)
                            setTipoMetrica(undefined)
                            setSalvoApi(true)
                            onTableRowExpand(false, dados)
                            setDataItemNivelControle([...dados])

                            message.destroy()
                          }
                        } catch (error) {
                          tryError(error)
                        }
                      } catch (error) {
                        tryError(error)
                      }
                    }

                    const onChangeTipoMetrica = (cod, name, id) => {
                      setTipoMetrica(cod)
                      const dados = JSON.parse(JSON.stringify(dataItemNivelControle))

                      const index = dados.findIndex((rec) => rec.cod === id)

                      dados[index].cod_tipo_metrica = cod
                      dados[index].tipo_metrica_nome = name.children
                      setDataItemNivelControle(dados)
                    }

                    const onChangeEscala = (valor, cod) => {
                      setData(undefined)

                      const dados = [...dataItemNivelControle]

                      const index = dados.findIndex((rec) => rec.cod === cod)

                      dados[index].valor = valor
                      setData(Number(valor))
                      setDataItemNivelControle(dados)
                    }

                    const onChangeAvalair = (cod, label, tipo: number) => {
                      setTipoMonitoramento(undefined)
                      const dados = [...dataItemNivelControle]

                      const index = dados.findIndex((rec) => rec.cod === label)

                      dados[index].cod = label
                      setTipoMonitoramento(cod)

                      setDataItemNivelControle(dados)
                    }

                    const onChangeAvalairObs = (cod, label, tipo: number) => {
                      setTipoObservado(undefined)
                      const dados = [...dataItemNivelControle]

                      const index = dados.findIndex((rec) => rec.cod === label)

                      dados[index].cod = label
                      setTipoObservado(cod)

                      setDataItemNivelControle(dados)
                    }

                    const onRemoveEspecie = async (cod, index) => {
                      const codNC = update === 0 ? codEstadio : update
                      try {
                        const resp = await putMoniNivelControle(codNC, {
                          item_remover: [cod],
                        })
                        if (resp.status === 200) {
                          let temp = []

                          remove(index)
                          for (let item_nivel_controle of dataItemNivelControle) {
                            const element = item_nivel_controle
                            if (cod !== element.cod) {
                              temp = [...temp, element]
                            }
                          }
                          setDataItemNivelControle(temp)
                          setValidateForms(!(form.getFieldValue('item')?.length > 0))
                          Notification({
                            message: 'Item excluido com sucesso',
                            type: 'success',
                          })
                        }
                      } catch (error) {
                        tryError(error)
                      }
                    }

                    const dataOrdenada = orderBy(dataItemNivelControle, ['especie_nome'], ['asc'])
                    return (
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Button onClick={() => onAddEspecie()} type='primary'>
                            Inserir
                          </Button>
                        </Col>
                        <Col flex='1 1 600px'>
                          <Table
                            rowKey='cod'
                            bordered
                            scroll={{ y: 'calc(100vh - 500px)' }}
                            pagination={{
                              onChange: handlePageChange,

                              total: dataItemNivelControle?.length,
                              showSizeChanger: false,
                            }}
                            dataSource={dataOrdenada}
                            onRow={(_, index) => {
                              return {
                                ref: (ref) => {
                                  refRowTable.current[index] = ref
                                },
                                onClick: () => null,
                              }
                            }}
                            expandable={{
                              onExpandedRowsChange: () => setEstadioRemover([]),
                              expandedRowKeys,
                              expandIcon: ({ expanded, onExpand, record }) => {
                                return (
                                  <Button
                                    size='small'
                                    type={expanded ? 'dashed' : null}
                                    ghost={!expanded}
                                    onClick={(e) => onExpand(record, e)}
                                    icon={expanded ? <MinusOutlined /> : <PlusOutlined />}
                                  >
                                    Estádios
                                  </Button>
                                )
                              },
                              columnWidth: 120,
                              onExpand: (data, record) => {
                                onTableRowExpand(data, record)
                              },
                              expandedRowRender: (record, i) => {
                                return (
                                  <Form.Item label='Estádio'>
                                    <Checkbox.Group
                                      disabled={!record.edt}
                                      onChange={(data) => onChangeEstadio(data, i)}
                                      value={dataEstadioSelect}
                                    >
                                      <Row>
                                        {dataEstadio.map((rec, index) => (
                                          <Col span={8} key={gerarKey(index)}>
                                            <Checkbox
                                              onChange={(e) =>
                                                onChangeItemCheckBox(e.target.checked, rec.cod, i)
                                              }
                                              value={rec.cod}
                                            >
                                              {rec.nome}
                                            </Checkbox>
                                          </Col>
                                        ))}
                                      </Row>
                                    </Checkbox.Group>
                                  </Form.Item>
                                )
                              },
                            }}
                            footer={() => (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'right',
                                }}
                              >
                                <b>Total geral: {dataItemNivelControle?.length}</b>
                              </div>
                            )}
                            size='small'
                            columns={[
                              {
                                key: 1,
                                dataIndex: 'categoria_nome',
                                render: (data)=> {
                                  return <>{decodeHtml(data)}</>
                                },
                                title: 'Categoria',
                                ...filterTable(true, true, 'categoria_nome', dataItemNivelControle),
                              },
                              {
                                key: 2,
                                dataIndex: 'especie_nome',
                                render: (data)=> {
                                  return <>{decodeHtml(data)}</>
                                },
                                title: 'Descrição',
                                ...filterTable(true, true, 'especie_nome', dataItemNivelControle),
                                sorter: (a, b) => a.especie_nome.localeCompare(b.especie_nome),
                                sortDirections: ['ascend', 'descend'],
                              },
                              {
                                key: 3,
                                dataIndex: 'variacao_especie_nome',
                                render: (data)=> {
                                  return <>{decodeHtml(data)}</>
                                },
                                title: 'Variação',
                                ...filterTable(
                                  true,
                                  true,
                                  'variacao_especie_nome',
                                  dataItemNivelControle,
                                  false
                                ),
                              },
                              {
                                key: 4,
                                dataIndex: 'valor',
                                width: '150px',
                                title: 'Valor',
                                render: (data, record, index) => {
                                  let escalaName
                                  switch (parseFloat(data)) {
                                    case 0:
                                      escalaName = 'Ausente'
                                      break
                                    case 1.0:
                                      escalaName = 'Baixo'
                                      break
                                    case 2.0:
                                      escalaName = 'Médio'
                                      break
                                    case 3.0:
                                      escalaName = 'Alto'
                                      break
                                    case 4.0:
                                      escalaName = 'Muito Alto'
                                      break

                                    default:
                                      break
                                  }
                                  if (record.cod_tipo_metrica === 2) {
                                    data = escalaName
                                  } else {
                                    data = record.valor
                                  }
                                  return (
                                    <>
                                      {record.edt ? (
                                          <div
                                            style={{
                                              width: '100%',
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                          >
                                            {record.cod_tipo_metrica === 2 ? (
                                              <Select
                                                style={{ width: '100%' }}
                                                value={dataT}
                                                onChange={(cod) => onChangeEscala(cod, record.cod)}
                                                size='small'
                                                options={[
                                                  {
                                                    children: '0 - Ausente',
                                                    label: '0 - Ausente',
                                                    value: 0,
                                                  },
                                                  {
                                                    children: '1 - Baixo',
                                                    label: '1 - Baixo',
                                                    value: 1,
                                                  },
                                                  {
                                                    children: '2 - Médio',
                                                    label: '2 - Médio',
                                                    value: 2,
                                                  },
                                                  {
                                                    children: '3 - Alto',
                                                    label: '3 - Alto',
                                                    value: 3,
                                                  },
                                                  {
                                                    children: '4 - Muito Alto',
                                                    label: '4 - Muito Alto',
                                                    value: 4,
                                                  },
                                                ]}
                                              />
                                            ) : (
                                              <InputNumber
                                                onChange={(cod) => onChangeEscala(cod, record.cod)}
                                                decimalSeparator='.'
                                                value={dataT}
                                                size='small'
                                                width='100%'
                                                step='0.01'
                                                {...propsMetrica}
                                              />
                                            )}
                                            {record.cod_tipo_metrica === 3 ? (
                                              <>
                                                <div
                                                  style={{
                                                    height: '1px',
                                                    width: '100%',
                                                    backgroundColor: '#d9d9d9',
                                                    marginTop: '15px',
                                                    marginBottom: '10px',
                                                  }}
                                                ></div>
                                                <Form.Item
                                                  label='O que avaliar'
                                                  style={{ height: '30px' }}
                                                >
                                                  <Select
                                                    size='small'
                                                    style={{ width: '70%' }}
                                                    value={tipoObservado}
                                                    onChange={(cod) =>
                                                      onChangeAvalairObs(cod, record.cod, 1)
                                                    }
                                                    options={tipos.map((data) => {
                                                      return { label: data.nome, value: data.cod }
                                                    })}
                                                  />
                                                </Form.Item>
                                                <Form.Item
                                                  label='Em que avaliar'
                                                  style={{ height: '30px' }}
                                                >
                                                  <Select
                                                    size='small'
                                                    style={{ width: '70%' }}
                                                    value={tipoMonitoramento}
                                                    onChange={(cod) =>
                                                      onChangeAvalair(cod, record.cod, 1)
                                                    }
                                                    options={tipos.map((data) => {
                                                      return { label: data.nome, value: data.cod }
                                                    })}
                                                  />
                                                </Form.Item>
                                              </>
                                            ) : null}
                                          </div>
                                          
                                      ) : (
                                        data
                                      )}
                                    </>
                                  )
                                },
                              },
                              {
                                key: 5,
                                dataIndex: 'cod_tipo_metrica',
                                width: '150px',
                                title: 'Métrica',

                                render: (data, record, index) => {
                                  let metrica
                                  switch (data) {
                                    case 1:
                                      metrica = 'Unidade'
                                      break
                                    case 2:
                                      metrica = 'Escala'
                                      break
                                    case 3:
                                      metrica = 'Porcentagem'
                                      break

                                    default:
                                      break
                                  }
                                  return (
                                    <>
                                      {record.edt ? (
                                        <Select
                                          style={{ width: '100%' }}
                                          size='small'
                                          onChange={(cod, name) =>
                                            onChangeTipoMetrica(cod, name, record.cod)
                                          }
                                          value={record.cod_tipo_metrica}
                                        >
                                          <Select.Option value={1} key={1}>
                                            Unidade
                                          </Select.Option>
                                          <Select.Option value={2} key={2}>
                                            Escala
                                          </Select.Option>
                                          <Select.Option value={3} key={3}>
                                            Porcentagem
                                          </Select.Option>
                                        </Select>
                                      ) : (
                                        metrica
                                      )}
                                    </>
                                  )
                                },
                              },
                              {
                                key: 0,
                                dataIndex: 'totalizar',
                                width: 80,
                                title: 'Totalizar',
                                render: (data, record) => {
                                  return (
                                    <div hidden={record.cod_variacao_especie === null}>
                                      <Switch
                                        disabled={!record.edt}
                                        checked={data === 1}
                                        size='small'
                                        onChange={() => handleChangeTotalizador(data, record.cod)}
                                        checkedChildren='Sim'
                                        unCheckedChildren='Não'
                                      />
                                    </div>
                                  )
                                },
                              },
                              {
                                key: 7,
                                dataIndex: 'inverter_escala',
                                width: 105,
                                title: 'Inverter esc.',
                                render: (data, record) => (
                                  <Switch
                                    disabled={!record.edt}
                                    title='Inverter escala'
                                    checked={data === 1}
                                    size='small'
                                    onChange={() => handleChangeInverter(data, record.cod)}
                                    unCheckedChildren='Não'
                                    checkedChildren='Sim'
                                  />
                                ),
                              },
                              {
                                key: 6,
                                dataIndex: 'cod',
                                width: '135px',
                                render: (data, record, index) => (
                                  <Space>
                                    <Button
                                      disabled={data === null}
                                      size='small'
                                      shape='default'
                                      className={record.edt ? 'success-button' : null}
                                      onClick={
                                        record.edt
                                          ? () => onSaveEspecie(record, data)
                                          : () => onEditEspecie(record, data)
                                      }
                                      icon={<Icons icon={record.edt ? faCheck : faPen} />}
                                    />
                                    <Popconfirm
                                      title='Deseja clonar este item'
                                      icon={<IconAlert type='confirm' size={4} />}
                                      onConfirm={() => onCloneEspecie(record, index)}
                                      cancelButtonProps={{
                                        danger: true,
                                        type: 'primary',
                                      }}
                                      okText='Sim'
                                      cancelText='Não'
                                    >
                                      <Button
                                        size='small'
                                        shape='default'
                                        icon={<Icons icon={faCopy} />}
                                      />
                                    </Popconfirm>
                                    <Popconfirm
                                      title='Deseja realmente remover o item?'
                                      icon={<IconAlert type='confirm' size={4} />}
                                      onConfirm={() => onRemoveEspecie(data, index)}
                                      cancelButtonProps={{
                                        danger: true,
                                        type: 'primary',
                                      }}
                                      okText='Sim'
                                      cancelText='Não'
                                    >
                                      <Button
                                        size='small'
                                        shape='default'
                                        type='primary'
                                        danger
                                        icon={<Icons icon={faTrash} />}
                                      />
                                    </Popconfirm>
                                    {/* dataSource={data} */}
                                  </Space>
                                ),
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    )
                  }}
                </Form.List>
              </Card>
            </Col>
            <Col flex='1 1 100%' />
          </Row>
        </Col>
      </Drawer>
    </Form>
  )
}

export default MoniNivelControle
