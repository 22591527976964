import { faPagelines } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendar,
  faClipboardCheck,
  faCogs,
  faCrosshairs,
  faFlask,
  faHandHoldingDroplet,
  faLaptopHouse,
  faLeaf,
  faMapMarkedAlt,
  faTachometerAlt,
  faUsers,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons'
import { localConfig } from '../Configs/localConfig'
import { APP_PREFIX_PATH } from './AppConfig'

import AgrupamentoTalhao from '../Views/app/agrupamentoTalhao'
import Fornecedores from '../Views/app/cadastros/fornecedores'
import Servicos from '../Views/app/cadastros/servicos'
import Cliente from '../Views/app/cliente'
import AppCampoConfig from '../Views/app/configAppCampo'
import PagePerfis from '../Views/app/configs/perfis'
import EquipeConsultor from '../Views/app/consultriaAgronomica/equipeConsultor'
import EquipeLider from '../Views/app/consultriaAgronomica/equipeLider'
import PageMapaPropriedade from '../Views/app/consultriaAgronomica/mapaPropriedade'
import UserConsultor from '../Views/app/consultriaAgronomica/userConsultor'
import UserLiderEquipe from '../Views/app/consultriaAgronomica/userLider'
import UserTechnician from '../Views/app/consultriaAgronomica/userTecnicoAt'
import Equipe from '../Views/app/equipe'
import Home from '../Views/app/home'
import Lab from '../Views/app/laboratorio'
import Monitoramento from '../Views/app/monitoramento'
import NovoMonitoramento from '../Views/app/monitoramento/novo-index'
import PageEspecie from '../Views/app/monitoramento/especie'
import PageNovoMonitoramento from '../Views/app/monitoramento/newMonitoramento'
import PageNivelControle from '../Views/app/monitoramento/nivelControle'
import PagePadraoMonitoramento from '../Views/app/monitoramento/padraoMonitoramento'
import ResumoMonitoramento from '../Views/app/monitoramento/resumo'
import Patrimonio from '../Views/app/patrimonio'
// import PageConfigBook from '../Views/app/precisao/configBook'
import PageConfigBookNovo from '../Views/app/precisao/novoConfigBook'
import GrupoServico from '../Views/app/precisao/grupoanalises'
import Laboratorios from '../Views/app/precisao/laboratorio'
import OrderSer from '../Views/app/precisao/laborder'
import PageMapaFertilidade from '../Views/app/precisao/mapFertilidade'
import Propriedades from '../Views/app/propriedades'
import PageAgrupamentoPropriedade from '../Views/app/propriedades/agrupamentoPropriedade'
import PageCalcario from '../Views/app/recomendacao/calcario'
// import CompatibilidadeAtivo from '../Views/app/recomendacao/compatibilidadeAtivo'
// import CompatibilidadeInsumos from '../Views/app/recomendacao/compatibilidadeInsumos'
import RecomendaInsumos from '../Views/app/recomendacao/insumos'
import Safra from '../Views/app/safra'
import PageSubArea from '../Views/app/subarea'
import Talhao from '../Views/app/talhao'
import TalhaoCultivavel from '../Views/app/talhaoSafra/novo-index'
import GerarBook from '../Components/monitoramento/gerarBook'
import { NovoCompatibilidadeAtivo } from '../Views/app/recomendacao/novoCompatibilidadeAtivo'
import { EquipeAssistenteTecnico2 } from '../Views/app/consultriaAgronomica/equipeAssistenteTecnico2'
import ModalEscolha from '../Components/modal-escolha-agrupamento'
import NovoMapaFertilidades from '../Views/app/precisao/novoMapasFertilidades'

export interface TypesNavigate {
  key: string
  id: string | number
  hidden: boolean
  path?: string
  title: string
  className?: string
  icon: any
  breadcrumb: boolean
  component?: React.ComponentType<any>
  keywords?: string
  description?: string
  submenu: {
    key: string
    id: string | number
    hidden: boolean
    visible?: boolean
    path: string
    title: string
    icon: any
    breadcrumb: boolean
    component: React.ComponentType
    submenu: any[]
    keywords?: string
    description?: string
  }[]
}

const dashBoardNavTree: TypesNavigate[] = [
  {
    key: '/home',
    id: 65,
    hidden: false,
    path: `${APP_PREFIX_PATH}/home`,
    title: 'DashBoard',
    icon: faTachometerAlt,
    breadcrumb: false,
    component: Home,
    submenu: [],
    keywords: 'DashBoard inicio home casa comeco mapa dados sistema clima previsão dados registros casa começo comeco',
    description: 'Página inicial do sistema'
  },
  {
    key: '/equipe',
    id: 4,
    hidden: false,
    path: `${APP_PREFIX_PATH}/equipe`,
    title: 'Equipe',
    icon: faUsers,
    breadcrumb: false,
    component: Equipe,
    submenu: [],
    keywords: `Equipe quipe euipe equpe eqioe equie eequipe eqquipe equuipe equiipe ewquipe esquipe
    team equipo grupo time colaboradores funcionarios funcionario funcionaro cadastrar registrar equipe colaborador perfil consultoria perfil cliente 
    geral foto nome técnico agricola diretor técnico líder de equipe assistente monitaramento diretor  staff 
    assistentes dirigentes stafe trabalhadores nova editar excluir deletar
    `,
    description: 'Página de cadastro dos membros da equipe: Listagem de equipes e cadastro de novos membros de equipe'
  },
  {
    key: '/cliente',
    id: 5,
    hidden: false,
    path: `${APP_PREFIX_PATH}/cliente`,
    title: 'Clientes',
    icon: faCalendar,
    breadcrumb: true,
    component: Cliente,
    submenu: [],
    keywords: `Cliente Clientes ciente clente clinte ccliente clliente clientte
    freguês comprador consumidor usuário logo foto nome razão social 
    registrar cliente cadastrar cliente editar cliente novo cliente excluir cliente deletar cliente`,
    description: 'Página de cadastro de clientes: Listagem de clientes e cadastro de novos clientes'
  },
  {
    key: '/safra',
    id: 26,
    hidden: false,
    path: `${APP_PREFIX_PATH}/safra`,
    title: 'Safras',
    icon: faLeaf,
    breadcrumb: true,
    component: Safra,
    submenu: [],
    keywords: `Safra Safras sfra safa sara ssafra saafra saffra safrra safraa
    colheita colecta milho soja sorgo granifero aveia saca espiga grão toneladas cultura gergelim algodão algodoeiro pastagem trigo
    forragens para cobertura mix para cobertura culturas
    registrar safra cadastrar safra editar safra novo safra excluir safra deletar safra atualizar safra`,
    description: 'Página de cadastro de safra: Listagem de safras cadastradas e cadastro de safra por cultura'
  },
  {
    key: '/patrimonio',
    id: 27,
    hidden: false,
    path: `${APP_PREFIX_PATH}/patrimonio`,
    title: 'Patrimônio',
    icon: faLaptopHouse,
    breadcrumb: true,
    component: Patrimonio,
    submenu: [],
    keywords: `Patrimonio ptrimonio parimonio patimonio patrimonnio patrimoniio
    quadriciclo veiculos ferramentas imoveis caminhão silos maquinas eletronicos tanque monitores
    bens fazenda acervo capital nome modelo categorias aquisição valor condição ano de fabricação categoria tipo marca`,
    description: 'Página de cadastro de patrimônio: vincule veiculos, maquinas, ferramentas etc... a propriedade'
  },
  {
    key: '/propriedades-list',
    hidden: false,
    id: '',
    path: '',
    title: 'Propriedades',
    icon: faMapMarkedAlt,
    breadcrumb: false,
    submenu: [
      {
        key: '/propriedades',
        id: 25,
        hidden: false,
        path: `${APP_PREFIX_PATH}/propriedades`,
        title: 'Cadastro',
        icon: '',
        breadcrumb: false,
        component: Propriedades,
        submenu: [],
        keywords: `Cadastro de propriedades cadastro propriedade`,
        description: 'Cadastro de propriedades'
      },
      {
        key: '/modal-escolha',
        id: 25,
        hidden: process.env.NODE_ENV === 'production',
        path: `${APP_PREFIX_PATH}/modal-escolha`,
        title: 'Agrupamentos',
        icon: '',
        breadcrumb: false,
        component: ModalEscolha,
        submenu: [],
        keywords: `Agrupamentos agrupamento de propriedades`,
        description: 'Agrupar propriedades'
      },
      {
        key: '/agrupamento-propriedade',
        id: 25,
        hidden: false,
        visible: true,
        path: `${APP_PREFIX_PATH}/agrupamento-propriedade`,
        title: 'Agrupamentos',
        icon: '',
        breadcrumb: false,
        component: PageAgrupamentoPropriedade,
        submenu: [],
        keywords: `Agrupamentos agrupamento de propriedades`,
        description: 'Agrupar propriedades'
      },
      {
        key: '/talhao/cadastro',
        id: 28,
        hidden: false,
        path: `${APP_PREFIX_PATH}/talhao/cadastro`,
        title: 'Talhões da propriedade',
        icon: '',
        breadcrumb: false,
        component: Talhao,
        keywords: `talhões de propriedades talhão`,
        description: 'Cadastrar/editar ou exlcuir talhões da propriedade: Desenhar, importar e exportar talhão',
        submenu: [],
      },
      {
        key: '/talhao/safra',
        id: 29,
        hidden: false,
        path: `${APP_PREFIX_PATH}/talhao/safra`,
        title: 'Talhões da safra',
        icon: '',
        breadcrumb: false,
        component: TalhaoCultivavel,
        submenu: [],
        keywords: `talhões da safra talhão da safra asfra kml importar exportar ver propriedade ver todas propriedades ver safra area ha relatório de talhões`,
        description: 'Cadastrar/editar ou exlcuir talhões da safra: Desenhar, importar, exportar, Ver propriedade, ver todas as propriedades da safra, linha de pulverização, imprimir relatório de talhões'
      },
      {
        key: '/talhao/subarea',
        id: 30,
        hidden: false,
        path: `${APP_PREFIX_PATH}/talhao/subarea`,
        title: 'Subárea do talhão',
        component: PageSubArea,
        icon: '',
        breadcrumb: false,
        submenu: [],
        keywords: `Subárea do talhão sub-area subi-area`,
        description: 'Cadastrar/editar ou exlcuir subarea do talhão'
      },
      {
        key: '/talhao/grupotalhaoes',
        id: 31,
        hidden: false,
        visible: true,
        path: `${APP_PREFIX_PATH}/talhao/grupotalhaoes`,
        title: 'Agrupamento de talhões',
        icon: '',
        breadcrumb: false,
        component: AgrupamentoTalhao,
        submenu: [],
        keywords: `Agrupamento de talhões Agrupamento de talhão agrupar juntar combinar juntos`,
        description: 'Agrupar talhões'
      },
    ],
  },
  {
    key: '/precisao',
    id: '',
    hidden: false,
    className: 'ant-menu-item',
    title: 'Agricultura de precisão',
    icon: faCrosshairs,
    breadcrumb: true,
    submenu: [
      {
        key: '/precisao/laborder',
        id: 32,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/laborder`,
        title: 'OS para Coleta',
        icon: '',
        breadcrumb: false,
        component: OrderSer,
        submenu: [],
        keywords: `Agricultura de precisão OS para coleta ordem de serviço para coleta orderm de sercico para coleta pra coleta coletar
        data cliente safra consultoria propriedade talhão laboratório status data da os área de amostra responsável coleta
         liberar coleta coletado em nova analise importar malha geral kml analises serviços imprimir ordem dados do cliente 
         clonar csv enviada pra analise analisada
        `,
        description: 'Ordem de serviço para coleta: enviar coleta para análise, importar malha geral'
      },
      {
        key: '/precisao/mapa-fertilidade',
        id: 82,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/mapa-fertilidade`,
        title: 'Mapa de fertilidade',
        icon: '',
        breadcrumb: false,
        component: NovoMapaFertilidades,
        // component: PageMapaFertilidade,
        submenu: [],
        keywords: `Agricultura de precisão mapa de fertilidade os resultado ordem de serviço resultado data safra propriedade talhão
        mapa de krigagem fertilidade indicadores indicador precrições prescrição prescricao prescricoes interpretado interpretar
        distribuido distribuir distribuida profundidade elementos marcadores data da coleta 00-20 00-30 00-40 00-50 20-40 
        micro fosforo materiaorganica potassio calcio magnesio gerar book completo cores dicas fertilidade do solo talhões
        `,
        description: 'Mapa de fertilidade: Resultado da ordem de serviço, mapa de krigagem, gerar book completo'
      },
      {
        key: '/precisao/config-book',
        id: 84,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/config-book`,
        title: 'Configuração do book',
        icon: '',
        breadcrumb: false,
        component: PageConfigBookNovo,
        submenu: [],
        keywords: `Agricultura de precisão Configuração do Book configurações do book ajustar definir definições texto introdução capa lista de elementos 
        fertilidade indicadores indicador 
        prescrições prescrição prescricao prescricoes ordem ordenar ordenação configurar book interpretado interpretar distribuido distribuir 
        mapa profundidades visivel visibilidade mostrar exibir observação do elemento informação descrição tipo de representação`,
        description: 'Configurar book de fertilidadade: Definir ordenação dos elementos, visibilidade no book, tipo de profundidade, observação do elemento, tipo de representação do mapa'
      },
      // {
      //   key: '/precisao/config-book-2',
      //   id: 84,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/precisao/config-book-2`,
      //   title: 'Config do book 2',
      //   icon: '',
      //   breadcrumb: false,
      //   component: PageConfigBookNovo,
      //   submenu: [],
      // },
      {
        key: '/precisao/laboratorios',
        id: 33,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/laboratorios`,
        title: 'Laboratórios',
        icon: '',
        breadcrumb: false,
        component: Laboratorios,
        submenu: [],
        keywords: `Agricultura de precisão Laboratórios laboratorio nome laboratorio tecsolo laborsolo cadastrar laboratorio análises analise endereço pessoa fisica pessoa juridica 
        cnpj fantasia`,
        description: 'Cadastrar/editar/atualizar/deletar laboratório'
      },
      {
        key: '/precisao/gruposervicos',
        id: 34,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/gruposervicos`,
        title: 'Grupo de análises',
        icon: '',
        breadcrumb: false,
        component: GrupoServico,
        submenu: [],
        keywords: `Agricultura de precisão Grupo de Serviços nome grupo profundidade análises analise plantas extrato de saturação macro granulometria 
        cor cores micronutrientes macronutrientes`,
        description: 'Grupo de serviços: Cadastrar grupo de serviço, definir cor, profundidade e análises'
      },
      {
        key: '/precisao/calcario',
        id: 32,
        hidden: false,
        path: `${APP_PREFIX_PATH}/precisao/calcario`,
        title: 'Cadastro de calcário',
        icon: '',
        breadcrumb: false,
        component: PageCalcario,
        submenu: [],
        keywords: `Agricultura de precisão Cadastro calcário nome do fornecedor óxido de calcio óxido de magnésio poder de neutralização Poder Relativo de Neutralização Total 
        Reatividade Peneira de fundo`,
        description: 'Cadastro de fornecedor de calcário'
      },
    ],
  },
  {
    key: '/monitoramento',
    id: 23,
    hidden: false,
    title: 'Monitoramento',
    icon: faPagelines,
    breadcrumb: true,
    submenu: [
      {
        key: '/monitoramento/mapadecalor',
        id: 73,
        hidden: false,
        path: `${APP_PREFIX_PATH}/monitoramento/mapadecalor`,
        title: 'Mapa de calor detalhado',
        icon: '',
        breadcrumb: false,
        component: NovoMonitoramento,
        submenu: [],
        keywords: `monitoramento monitoriamento monitorar monitor  Mapa de calor detalhado heatmap heat map gerar book completo talhão talhões imprimir 
        Espécie variação min minimo minima méd media médio máx maximo maxima pntos estadios inverter escala invertida 
        Abaixo do NC Nível de controle Acima do NC quantidade de talhões qtd talhões qtde talhões safra por talhão todos
        exibir marcadores praga variação resumo datas daninha tiguera picão preto pontos monitorados escala invertida data do monitoramento 
        latitude longitude espécie repetição repetições valor observado`,
        description: 'Mapa de calor detalhado: exibe o mapa de calor com informações de Espécie variação no talhão'
      },
      {
        key: '/monitoramento/gerarbook',
        hidden: false,
        id: 73,
        path: `${APP_PREFIX_PATH}/monitoramento/gerarbook`,
        title: 'Gerar book resumido',
        component: GerarBook,
        icon: '',
        breadcrumb: false,
        submenu: [],
        keywords: `monitoramento monitoriamento monitorar monitor  Gerar book resumido resumir menor leve `,
        description: 'Gera o book de monitoramento de forma resumida, gerando o arquivo mais rápido e mais leve'
      },
      {
        key: '/monitoramento/resumo',
        id: 73,
        hidden: false,
        path: `${APP_PREFIX_PATH}/monitoramento/resumo`,
        title: 'Resumo',
        icon: '',
        breadcrumb: false,
        component: ResumoMonitoramento,
        submenu: [],
        keywords: `monitoramento monitoriamento monitorar monitor  Resumo monitoramento Espécie variação min minimo minima méd media médio máx maximo maxima pntos estadios inverter escala invertida 
        Abaixo do NC Nível de controle Acima do NC gerar planilha gerar pdf gerar excel geral csv `,
        description: 'Dados do monitoramento de forma resumida propriedade talhão data inicio data de inicio'
      },
      {
        key: '/monitoramento/especie',
        id: 35,
        hidden: false,
        path: `${APP_PREFIX_PATH}/monitoramento/especie`,
        title: 'Catálogo de pragas',
        icon: '',
        breadcrumb: false,
        component: PageEspecie,
        submenu: [],
        keywords: `monitoramento monitoriamento monitorar monitor  Catálogo de pragas e ervas daninhas pagas peagas Catálogo de pragas, doenças e plantas daninhas 
        nome nome cientifico tipo ácaro aguape alfafa algodao alfinee tetra polyoha siline eichornis praga planta daninha doença joio`,
        description: 'Cadastrar catálogo de pragas, doenças e plantas daninhas'
      },
      {
        key: '/monitoramento/niveldecontrole',
        hidden: false,
        id: 36,
        path: `${APP_PREFIX_PATH}/monitoramento/niveldecontrole`,
        title: 'Nível de controle',
        icon: '',
        breadcrumb: false,
        component: PageNivelControle,
        submenu: [],
        keywords: `monitoramento monitoriamento monitorar monitor Nível de controle nc control level categoria tipo variação valor métrica inverter escala totalizar 
        algodoeiro avaeria feijoeiro ferreagens para cobertura gergelim milho mix para cobertura pastagem soja sorgo trigo culturas estádio`,
        description: 'Cadastro de Nível de controle (NC): visualizar/editar categoria, tipo, variação métrica, etc...'
      },
      // {
      //   key: '/monitoramento/padraomonitoramento',
      //   hidden: false,
      //   id: 37,
      //   path: `${APP_PREFIX_PATH}/monitoramento/padraomonitoramento`,
      //   title: 'Padrões de monitoramento',
      //   component: PagePadraoMonitoramento,
      //   icon: '',
      //   breadcrumb: false,
      //   submenu: [],
      //   keywords: `monitoramento monitoriamento monitorar monitor Padrões de monitoramento padrao de monitoramento cultura nome padrão consultoria estadio nivel de controle`,
      //   description:'Cadastrar/visualizar/editar padrões de monitoramento'
      // },
      {
        key: '/monitoramento/padrao_pontos',
        hidden: false,
        id: 71,
        path: `${APP_PREFIX_PATH}/monitoramento/padrao_pontos`,
        title: 'Pontos padrão para monitoramento',
        component: PageNovoMonitoramento,
        icon: '',
        breadcrumb: false,
        submenu: [],
        keywords: `monitoramento monitoriamento monitorar monitor Pontos padrão para monitoramento padrões pin agulha ponta visualizar pontos 
        propriedade agrupamento talhão subarea sub-area importar exportar excluir todos visualizar pontos importar linhas de plantio exibir linhas de plantio`,
        description: 'Visualizar pontos padrão para o monitoramento: Visualizar por Propriedade, por agrupamento, por talhão ou por subárea'
      },
    ],
  },
  {
    key: '/recomendacao',
    id: '',
    hidden: false,
    title: 'Recomendação',
    icon: faHandHoldingDroplet,
    breadcrumb: false,
    submenu: [
      {
        key: 'recomendaacaoinsumos',
        id: 38,
        hidden: false,
        path: `${APP_PREFIX_PATH}/recomendacao/insumos`,
        title: 'Catálogo insumos',
        icon: '',
        breadcrumb: false,
        component: RecomendaInsumos,
        submenu: [],
        keywords: `Recomendação recomendacao recomendados recomendadas recomendar Catálogo insumos catalogo de insumos produto 
         nome fabricante categoria do insumo composição composicao culturas alamos agriconnection agroimport ccab solus nortox 
         rainbow chemical tecnomyl defensivos culturas milho soja trigo pastagem sorgo gergelim classe agronomica formulação unidade dose 
         ativo concentração ativo aplicação aplicar fase da cultura`,
         description: 'Cadastrar/visualizar/editar catálogo de insumos'
      },
      //RONALDO PEDIU PARA REMOVER Compatibilidade insumos -> 15/07/2024
      // {
      //   key: 'recomendaacaocompatibilidadeinsumo',
      //   id: 39,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/recomendacao/compatibilidadeinsumo`,
      //   title: 'Compatibilidade insumos',
      //   icon: '',
      //   breadcrumb: false,
      //   component: CompatibilidadeInsumos,
      //   submenu: [],
      // },
      //------------------------------------------------------------------------
      // {
      //   key: 'recomendaacaocompatibilidadeativo',
      //   id: 40,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/recomendacao/compatibilidadeativo`,
      //   title: 'Compatibilidade ativos',
      //   icon: '',
      //   breadcrumb: false,
      //   component: CompatibilidadeAtivo,
      //   submenu: [],
      // },
      {
        key: 'recomendaacaocompatibilidadeativo',
        id: 39,
        hidden: false,
        path: `${APP_PREFIX_PATH}/recomendacao/incompatibilidades`,
        title: 'Incompatibilidades',
        icon: '',
        breadcrumb: false,
        component: NovoCompatibilidadeAtivo,
        submenu: [],
        keywords: `Recomendação recomendacao recomendados recomendadas recomendar Incompatibilidades compatibilidades 
        insumos produto comercial (produto comercial) grupo químico ativos formulação culturas classe agronomica biotecnologia tecnologia grupo 
        nivel de incompatibilidade nivel de compatibilidade doses plantback plant back observação observações observacao observacoes estádios 
        dose mínima dose maxima g/ha (g/ha) todos estadios todos os estadios tipo de observação tipo de observacao nivel de alerta pH de calda 
        umidade do solo umidade relativa do ar temperatura mínima temperatura máxima aplicação de calcário recente necessidade de pré-mistura 
         teor de argila teor de Matéria Orgânica carry-over outros Tempo até o inicio do plantio dias comecar plantar`,
        description: 'Cadastrar/visualizar/editar incompatibilidades, compatibilidades, doses, observações, plant back de Insumos (produto comercial), Ativos, Grupos químicos, Formulações, Culturas, Classes Agrônomicas, Biotecnologias, Grupos'
      }
    ],
  },
  {
    key: '/consultoria',
    id: '',
    hidden: localConfig().acesso === 'cliente',
    title: 'Equipes de campo',
    icon: faUserTie,
    breadcrumb: false,
    submenu: [

      {
        key: 'usuariotecnico',
        id: 68,
        hidden: false,
        path: `${APP_PREFIX_PATH}/consultoria/user-tecnico-at`,
        title: 'Atribuição de cargos',
        icon: '',
        breadcrumb: false,
        component: UserTechnician,
        submenu: [],
        keywords: `consultoria Equipes de campo times staff colegas Atribuição de cargos atribuir cargo definir vincular cargo associar cargo 
        Técnicos e Ass. técnicos Técnicos e Assistentes técnicos Usuários tecnicos de campo lideres assitente`,
        description: 'Atribuir cargo para usuário da equipe de campo'
      },
      //OCULTADO TEMPORIAMENTE PARA ENXUTAR (07/08/24)
      // {
      //   key: 'usuarioconsultor',
      //   id: 67,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/consultoria/user-consultor`,
      //   title: 'Consultores',
      //   icon: '',
      //   breadcrumb: false,
      //   component: UserConsultor,
      //   submenu: [],
      // },
      //OCULTADO TEMPORIAMENTE PARA ENXUTAR (07/08/24)
      // {
      //   key: 'usuarioLiderEquipe',
      //   id: 78,
      //   hidden: false,
      //   path: `${APP_PREFIX_PATH}/consultoria/user-lider`,
      //   title: 'Líder de Equipe',
      //   icon: '',
      //   breadcrumb: false,
      //   component: UserLiderEquipe,
      //   submenu: [],
      // },
      
      {
        key: 'equipeconsultor',
        id: 69,
        hidden: false,
        path: `${APP_PREFIX_PATH}/consultoria/equipe-consultor`,
        title: 'Equipe consultor',
        icon: '',
        breadcrumb: false,
        component: EquipeConsultor,
        submenu: [],
        keywords: `consultoria Equipes de campo times staff colegas equipe do consultor assistente tecnico vinculados vincular associar linkar `,
        description: 'Vincular assistente técnico para a equipe do consultor'
      },
      {
        key: 'equipelider',
        id: 77,
        hidden: false,
        path: `${APP_PREFIX_PATH}/lideria/equipe-lider`,
        title: 'Equipe líder de campo',
        icon: '',
        breadcrumb: false,
        component: EquipeLider,
        submenu: [],
        keywords: `consultoria Equipes de campo times staff colegas Equipe líder de campo equipe do lider chefe tecnicos vinculados vincular associar linkar`,
        description: 'Vincular assistente para a equipe do líder de campo'
      },
      {
        key: 'equipeAssistenteTecnico',
        id: 89,
        hidden: false,
        path: `${APP_PREFIX_PATH}/lideria/equipe-assistente-tecnico`,
        title: 'Equipe assistente técnico',
        icon: '',
        breadcrumb: false,
        component: EquipeAssistenteTecnico2,
        submenu: [],
      },
      {
        key: '/consultoria/mapa-propriedade',
        id: 81,
        hidden: false,
        path: `${APP_PREFIX_PATH}/consultoria/mapa-propriedade`,
        title: 'Mapa de acesso',
        icon: '',
        breadcrumb: false,
        component: PageMapaPropriedade,
        submenu: [],
        keywords: `consultoria Equipes de campo times staff colegas Mapa de área com acesso consultor lider cultura data vigente consultar visualizar mapa 
         propriedade cliente qtde de talhãoes quantidade de talhões talhão area area/ha`,
        description: 'Mapa de área com acesso'
      },
    ],
  },
  {
    key: '/cadastros',
    hidden: false,
    id: '',
    title: 'Cadastros',
    icon: faClipboardCheck,
    breadcrumb: false,
    submenu: [
      {
        key: '/cadastros/fornecedores',
        id: 52,
        hidden: false,
        path: `${APP_PREFIX_PATH}/cadastros/fornecedores`,
        title: 'Fornecedores',
        icon: '',
        breadcrumb: false,
        component: Fornecedores,
        submenu: [],
        keywords: `Cadastros Fornecedores fornecedor Nome Fornecedor Tipo de Fornecedor razão social nome fantasia cnpj 
        ie celular fone fixo email uf cidade cep endereço numero serviçoes pessoa física pessoa jurídica  `,
        description: 'Cadastrar/visualizar/editar fornecedores'
      },
      {
        key: '/cadastros/servicos',
        id: 57,
        hidden: false,
        path: `${APP_PREFIX_PATH}/cadastros/servicos`,
        title: 'Serviços',
        icon: '',
        breadcrumb: false,
        component: Servicos,
        submenu: [],
        keywords: `Cadastros serviços servicos`,
        description: 'Cadastrar/visualizar/editar serviços'
      },
    ],
  },
  {
    key: '/config',
    hidden: false,
    id: '',
    title: 'Configurações',
    icon: faCogs,
    breadcrumb: false,
    submenu: [
      {
        key: '/config/perfil',
        id: 6,
        hidden: false,
        path: `${APP_PREFIX_PATH}/config/perfil`,
        title: 'Perfis de acesso',
        icon: '',
        breadcrumb: false,
        component: PagePerfis,
        submenu: [],
        keywords: `Configurações configuracoes configuracao configuração definição definições ajustes ajuste 
         perfis de acesso perfil de acesso pregas doenças insumos porpriedades clientes patrimonio fornecedores agricultura de precisão 
         equipe monitoramento safra consultoria permissão permissoes`,
        description: 'Cadastro de perfil de acesso e permissões'
      },
      {
        key: '/config/campo',
        id: 0,
        hidden: localStorage.getItem('tipo-acesso') === 'cliente',
        path: `${APP_PREFIX_PATH}/config/campo`,
        title: 'Aplicativos mobile',
        icon: '',
        breadcrumb: false,
        component: AppCampoConfig,
        submenu: [],
        keywords: `Configurações configuracoes configuracao configuração definição definições ajustes ajuste 
        aplicativos mobile app movel aplicativo movel celular smartphone android iphone telefone ttivos  Velocidade máxima no campo 
        Monitoramento Coleta`,
        description: 'Configurações do aplicativo mobile'
      },
    ],
  },
  {
    key: '/lab',
    id: '',
    hidden: false,
    path: `${APP_PREFIX_PATH}/lab`,
    title: 'Laboratorio',
    icon: faFlask,
    breadcrumb: false,
    component: Lab,
    submenu: [],
    keywords: `Laboratorio`,
    description: 'Laboratorio'
  },
 
]

const navigationConfig: TypesNavigate[] = [...dashBoardNavTree]

export default navigationConfig
