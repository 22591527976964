import { SelectWrapper } from './styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'
import { removerAcentos, removerEspacos } from '../../../utils'
import { useEffect, useState, useRef } from 'react'
import { ItemList } from './components/item'
import Carregando from '../loading'
import Svg from '../../talhasvg'
import { Empty } from 'antd'

//PROPS
interface Props {
    label?: string
    placeholder?: string
    value: string
    onChange: React.Dispatch<React.SetStateAction<string>>
    onChangeID?: React.Dispatch<React.SetStateAction<number | null>>
    onChangeExtra?: React.Dispatch<React.SetStateAction<number | string | null>> | Function
    icon?: any
    open: boolean
    trigger: React.Dispatch<React.SetStateAction<boolean>>
    children?: React.ReactNode
    maxHeight?: number | string
    items: any[]
    descricaoItem: string
    idItem: string
    hasTalhaoSVG?: boolean;
    extraItem?: string
    loading?: boolean
    disabled?: boolean
    task?: Function
    atual?: string
    disabledItens?: number[]
    id?: string
    allowClear?: boolean
    width?: number | string
    obrigatorio?: boolean;
    /**Tamanho do SVG em `px` ([default: `30`]) */
    iconSVGSize?: number;
}

export const Select: React.FC<Props> = ({ label, placeholder, open, trigger, value, onChange, maxHeight, items, 
    descricaoItem, icon, onChangeID, idItem, loading, disabled, task,  atual,  disabledItens, extraItem, onChangeExtra, 
    id, allowClear, width, obrigatorio, hasTalhaoSVG, iconSVGSize }) => {
    //REF
    const refSelect = useRef<any>(null)

    //STATES
    const [buscar, setBuscar] = useState<string>('')
    const [indexSelecionado, setIndexSelecionado] = useState<number>(-1);
    const [index_atual, setIndex_atual] = useState<number>(null);


    const handleKeyDown = (e) => {
        if (open) {
            if (e.key === 'ArrowDown') {
                setIndexSelecionado((prevIndex) => (prevIndex + 1) % items?.length)
            } else if (e.key === 'ArrowUp') {
                setIndexSelecionado((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1))
            } else if (e.key === 'Enter') {
                trigger(false)
            }
        }
    }

    function limpar() {
        onChange?.('')
        onChangeID?.(null)
        trigger?.(false)
    }

    const handleClickOutside: any = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (refSelect.current && !refSelect.current.contains(event.target)) {
            trigger(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    function encontrarAtivo(buscar_nome: string) {
        const ativoSemAcentos = removerAcentos(buscar_nome)
        return function (item) {
            const nomeSemAcentos = removerAcentos(String(item?.[descricaoItem]))
            return nomeSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) || !buscar_nome
        }
    }

    return (
        <SelectWrapper style={{ width: width }} ref={refSelect} data-disabled={disabled}>
            {!!label && <label htmlFor={`select_${removerEspacos(placeholder)}`}>{label} {obrigatorio ? <span className="InputWrapper__obrigatorio">(obrigatório)</span> : null}</label>}

            <div
                className='SelectWrapper__campo'
                role='none'
            // onClick={() => {
            //   trigger(true)
            // }}
            >
                {/* <FontAwesomeIcon icon={icon ?? faMagnifyingGlass} className='SelectWrapper__campo__icon' /> */}

                {(hasTalhaoSVG && index_atual !== null)
                    ? <Svg fill='#999999' coordinates={items?.[index_atual ?? 0]?.['kml']?.['coordenadas']} className='SelectWrapper__campo__icon_talhao' />
                    : <FontAwesomeIcon icon={icon ?? faMagnifyingGlass} className='SelectWrapper__campo__icon' />
                }

                <input
                    id={`select_${id ?? 'id'}_${removerEspacos(placeholder)}${Date.now()}`}
                    className='SelectWrapper__campo__input'
                    placeholder={placeholder}
                    onFocus={() => {
                        trigger(true)
                    }}
                    onKeyDown={handleKeyDown}
                    value={value}
                    onChange={(e) => {
                        onChange(e.currentTarget.value)
                        setBuscar(e.currentTarget.value)
                    }}
                    autoComplete='off'
                    // autoComplete="new-password"
                    disabled={disabled}
                />

                <div className='SelectWrapper__icons'>
                    {value !== '' && allowClear !== false && (
                        <button className='SelectWrapper__campo__limpar' onClick={limpar}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    )}
                    <button className='SelectWrapper__campo__arrow'>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </button>
                </div>
            </div>

            <div className={`SelectWrapper__lista`} data-open={open} data-hasLabel={!!label} style={{ maxHeight: maxHeight }}>
                {loading && (
                    <div style={{ boxSizing: 'border-box', padding: '10px 20px' }}>
                        <Carregando legenda='buscando registros' direction='row' animation size='small' />
                    </div>
                )}

                {(items ?? []).filter(encontrarAtivo(buscar)).map((item, index) => {
                    return (
                        <ItemList
                            key={item?.[idItem]}
                            index={index}
                            focused={index === indexSelecionado}
                            onClick={() => {
                                onChange?.(item?.[descricaoItem]);
                                onChangeID?.(Number(item?.[idItem]));
                                onChangeExtra?.(item?.[extraItem]);
                                setIndex_atual?.(index);
                                trigger(false);
                                setBuscar('');
                                task?.()
                            }}
                            current={atual}
                            disabled={disabledItens?.indexOf(Number(item?.[idItem])) > -1}
                            iconSVG={hasTalhaoSVG && item?.['kml']?.['coordenadas']}
                            iconSVGSize={iconSVGSize}
                        >
                            {item?.[descricaoItem]}
                        </ItemList>
                    )
                })}

                {(items.length === 0) && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>Nenhum registro disponível!</>} />
                )}

                {(items.length > 0 && ((items ?? []).filter(encontrarAtivo(buscar))).length === 0) && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> <b>'{value}'</b> não encontrado!</>} />
                )}

            </div>
        </SelectWrapper>
    )
}
