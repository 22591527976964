
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//STYLE
import { CollapseAcc } from "./styled";
import React, { useState } from "react";
import { Render } from "../render";

//PROPS
interface Props {
    label: string;
    extra?: React.ReactNode;
    isFirst?: boolean;
    isLast?: boolean;
    single?: boolean;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    open: boolean;
    trigger: Function;
}

export const Accordion: React.FC<Props> = ({ label, open, trigger, extra, isFirst, isLast, single, children, style }) => {
     
    return (
        <CollapseAcc style={style} data-isFirst={isFirst} data-isLast={isLast} data-single={single} >
            <button className="CollapseAcc__header" data-opened={open} onClick={()=>{trigger()}}>
               <div className="CollapseAcc__header__main">
                    <FontAwesomeIcon icon={faAngleRight} className="CA__header__main__arrow" data-opened={open} />
                    {label}
               </div>
               {extra}
            </button>
            
            <Render condition={open} >
                <div className="CollapseAcc__content">
                    {children}
                </div>
            </Render>
            
        </CollapseAcc>
    )
}