import styled from "styled-components";


export const CardPainel = styled.div`
    width: 570px;
    /* height: 300px; */
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px 15px 22px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    opacity: 0;
    transform: translateX(50px);
    animation: fade 1s forwards;
    animation-timing-function: ease;
    will-change: transform;

    @keyframes fade {
        from {
            opacity: 0;
            transform: translateX(75px);
        }
        to {
            opacity: 1;
            transform: translateX(0px);
        }
    }

`
export const Navigation = styled.div`
    width: 100%;
    height: 30px;
    margin-top: -10px;
`

export const HeaderPainel = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: thin solid var(--border-color);
    padding-bottom: 10px;
`

export const TitleContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 0px;

    :is([data-esconder = true]) {
        opacity: 0;
    }

    .TitleContainer__icon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        border-radius: 50%;
        background-color: var(--primary-color, green);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
    }

    .TitleContainer__titles {
        width: calc(100% - 50px);
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0px;
    }

    h2.TitleContainer__titles__talhao {
        font-weight: 800;
        text-transform: uppercase;
        font-size: 16px;
        color: var(--text-color);
        margin: 0;
    }
    h2.TitleContainer__titles__talhao:is([data-smaller = true]) {
        font-size: 15px;
    }
    
    h5.TitleContainer__titles__fazenda {
        font-weight: 800;
        text-transform: capitalize;
        font-size: 14px;
        color: var(--text-color-light);
        margin: 0;
    }

`

export const Detalhes = styled.div`
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s;

    &&:is([data-compactar = true]) {
        height: 2px;
    }

    .Detalhes__item {
        height: 100%;
        position: relative;
        /* background-color: red; */
    }

    span.Detalhes__item__descricao {
        font-weight: 500;
        font-size: 13px;
        color: var(--text-color-light);
        transition: .1s;
    } 

    p.Detalhes__item__valor {
        font-weight: 600;
        font-size: 14px;
        color: var(--text-color);
        transition: .1s;
        max-width: 300px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .Detalhes__item:is([data-mostrar = false]) span.Detalhes__item__descricao,
    .Detalhes__item:is([data-mostrar = false]) p.Detalhes__item__valor {
        opacity: 0;
    }

    button.Detalhes__item_hide {
        position: absolute;
        bottom: -28.8px;
        margin: auto;
        transform: translate(50%, -50%);
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: var(--primary-color);
        opacity: 0;
    } 
    .Detalhes__item:hover button.Detalhes__item_hide {
        opacity: 1;
    }
    .Detalhes__item:is([data-mostrar = false]) button.Detalhes__item_hide {
        color: var(--text-color-light);
        opacity: 0.5;
    }
    .Detalhes__item:is([data-mostrar = false]):hover button.Detalhes__item_hide {
        opacity: 1;
    }

`
export const LoadingContainer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const InlineElements = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    /* background-color: rebeccapurple; */
    height: 60px;
`

export const FormItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
`