import styled from "styled-components";

export const ModalDetalhes = styled.div`
    width: 590px;
    height: calc(100vh - 240px);
    background-color: white;
    border-radius: 10px;
    position: fixed;
    top: 170px;
    left: 30px;
    z-index: 2000 !important;
    box-shadow: rgba(153, 153, 153, 0.5) 0px 0px 15px;
    transition: .4s;
    transform-origin: bottom;
    opacity: 0;
    animation: entranceModal 0.3s forwards ;
    
    :is([data-ocultar = true]) {
        z-index: 999 !important;
    }

    @keyframes entranceModal {
        0% {
            transform: translateX(0px) translateY(50px) scale(1);
            opacity: 0;
        }
        100% {
            transform: translateX(0px) translateY(0px) scale(1);
            opacity: 1;
        }
    }

    div.ModalDetalhes__header {
        width: 100%;
        border-bottom: thin solid var(--border-color);
        box-sizing: border-box;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4.MD__header__title {
            font-size: 15px;
            font-weight: 600;
            color: var(--text-color);

            span.MD__header__title__light {
                font-size: 14px;
                font-weight: 500;
                color: var(--text-color-light);
            }
        }
    }

    div.ModalDetalhes__content {
        width: 100%;
        height: calc(100% - 80px);
        box-sizing: border-box;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: hidden;
        overflow-y: auto;

        div.MD__content__tabela_notas {
            width: 100%;
            min-height: 70px;
            display: flex;
            border: thin solid var(--border-color);
            overflow: hidden;
            border-radius: 5px;
            margin-top: -10px;

            div.MD__content__tabela_notas__column {
                width: 100%;
                border-right: thin solid var(--border-color);

                :last-child {
                    border: none;
                }

                div.MD__c__tabela_notas__column__header {
                    width: 100%;
                    min-height: 35px;
                    background: #FAFAFA;
                    border-bottom: thin solid var(--border-color);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    font-size: 14px;
                }
                div.MD__c__tabela_notas__column__row {
                    width: 100%;
                    min-height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 13px;
                }
            }
        }
        
        div.MD__content__coleta {
            width: 100%;
            min-height: 215px;
            display: flex;
            flex-direction: column;
            border: thin solid var(--border-color);
            overflow: hidden;
            border-radius: 5px;
            margin-top: -10px;

            div.MD__content__tabela_coleta__column {
                width: 100%;
                display: flex;
                border-bottom: thin solid var(--border-color);

                :last-child {
                    border: none;
                }

                div.MD__c__tabela_coleta__column__header {
                    width: 100%;
                    min-height: 35px;
                    background: #FAFAFA;
                    border-right: thin solid var(--border-color);
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-weight: 700;
                    font-size: 14px;
                    box-sizing: border-box;
                    padding: 0 10px;
                }
                div.MD__c__tabela_coleta__column__row {
                    width: 100%;
                    height: 35px;
                    overflow-y: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-weight: 500;
                    font-size: 13px;
                    box-sizing: border-box;
                    padding: 10px;
                }
            }
        }

        div.MD__content__tabela_notas__logo {
            width: 100%;
            min-height: 100px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;

            img.MD__content__tabela_notas__logo__img {
                width: 25%;
            }
        }

    }

`;


export const BarContainer = styled.div`
    width: 100%;
    margin-top: -7px;

    div.BarContainer__bar {
        width: 100%;
        height: 10px;
        background: #bebebe;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
    }
    
    div.BarContainer__lagenda {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        span.BarContainer__lagenda__label {
            font-size: 12px;
            font-weight: 600;
            color: var(--text-color);
        }
    }
`;