import styled from "styled-components";

export const PainelPontos = styled.div`
    position: absolute;
    top: 15px;
    left: 15px;
    width: 500px;
    max-height: calc(100vh - 50px);
    background-color: #fff;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    div.PainelPontos__table {
        width: 100%;
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: -10px;
    }

    div.PainelPontos__footer {
        width: 100%;
        height: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: -10px;
    }
`;