import styled from 'styled-components'

export const Container = styled.div`
    .map-content {
        position: absolute;
        width: 100%;

        .btn-edit-talhao {
            background-color: #ffffff;
            position: absolute;
            z-index: 99999;
            right: 0;

            margin: 15px;
            border-radius: 5px;
        }
    }
`;

export const EditarTalhao = styled.div`
    width: 100%;
    height: calc(100vh - 252px);
    display: flex;
    gap: 20px;

    div.EditarTalhao__form {
        width: 310px;
        height: 100%;
        background: #fff;
        box-sizing: border-box;
        padding-right: 15px;
        border-right: thin solid var(--border-color);
        position: relative;

        div.EditarTalhao__form__import {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 7px;
            border: 2px dashed var(--disabled-text-color);
        }

        div.EditarTalhao__form__footer {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: flex-end;
            border-top: thin solid var(--border-color);
            position: absolute;
            bottom: 0;
        }
    }
    
    div.EditarTalhao__mapa {
        width: calc(100% - 320px);
        height: 100%;


        div.EditarTalhao__mapa__container {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            overflow: hidden;
            border: 2px solid var(--primary-color);
            background: #345853;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            span.EditarTalhao__mapa__container__aviso {
                position: absolute;
                color: white;
                z-index: 10;
                font-size: 16px;
                text-shadow: 0 0 20px #000, 0 0 30px #000, 0 0 40px #000, 0 0 55px #000, 0 0 75px #000, 2px 2px 2px rgba(0,0,0,0);            }

            div.EditarTalhao__m__c__float {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 2;
            }
        }
    }
`;
