import { faCheck, faFileUpload, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'antd'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Icons from '../Icons'
import './styled.css'

interface KMLUploaderProps {
  onFileUpload: (rawData: string, base64Data: string) => void
  disable: boolean
  setDisable: (data: boolean) => void
  setValido?: (data: boolean) => void
  valido?: boolean,
  descricao?: string,
  type?: 'primary' | 'link' | 'link' | 'dashed' | 'default'
}

const KMLUploader: React.FC<KMLUploaderProps> = ({
  onFileUpload,
  disable,
  setDisable,
  valido,
  setValido,
  descricao,
  type
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [nameFile, setNameFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isHovered, setIsHovered] = useState(false)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleClearFile = () => {
    setSelectedFile(null)
    setNameFile(null)
    setIsHovered(false)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const toBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }

  const base64 = async (file: File) => {
    const resp = await toBase64(file)

    const limpar = resp.replace('data:', '').replace(/^.+,/, '')

    return `data:@file/xml;base64,${limpar}`
  }

  useEffect(() => {
    const handleFileRead = async (e: ProgressEvent<FileReader>) => {
      const content = e.target?.result as string
      const base64Data = await base64(selectedFile as File)
      onFileUpload(content, base64Data)
      const limiteCaracteres = 30
      const textoExibido =
        selectedFile?.name.length > limiteCaracteres
          ? `${selectedFile?.name.slice(0, limiteCaracteres)}...`
          : selectedFile?.name
      setNameFile(textoExibido)
      if (selectedFile) {
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
    }

    if (selectedFile) {
      const reader = new FileReader()
      reader.onloadend = handleFileRead
      reader.readAsText(selectedFile, 'utf-8')
    }

    // Limpar o estado do arquivo após o processamento
    return () => {
      setSelectedFile(null)
      if (nameFile == null) {
        setSelectedFile(null)
      }
    }
  }, [selectedFile, onFileUpload])

  return (
    <div className='file-container'>
      <Button type={type??'primary'} style={{color: type==='link'? 'var(--primary-color)':undefined}} icon={<Icons icon={faFileUpload} />} onClick={handleButtonClick}>
        {descricao ?? 'Arquivo Kml'}
      </Button>
      <input
        type='file'
        accept='.kml'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {nameFile != null ? (
        <div
          className='name-file'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ position: 'relative' }}
        >
          <div className='file-anexo'>
            {isLoading == true ? (
              <div className='loading-spinner'></div>
            ) : valido == true ? (
              <Icons
                style={{ width: '13px', height: '13px', color: 'green', marginBottom: 15 }}
                icon={faCheck}
              ></Icons>
            ) : (
              <Icons style={{ width: '13px', height: '13px', color: 'red', marginTop: -5 }} icon={faXmark}></Icons>
            )}
            {valido == true ? (
              <p
                style={{
                  color: isLoading == true ? 'black' : 'green',
                  whiteSpace: 'nowrap',
                  marginTop: -5
                }}
                className='nameFile'
              >
                {nameFile == null ? '' : nameFile}
              </p>
            ) : (
              <p
                style={{
                  color: isLoading == true ? 'black' : 'red',
                  whiteSpace: 'nowrap',
                  marginTop: -5
                }}
              >
                {nameFile == null ? '' : nameFile}
              </p>
            )}
          </div>
          {isHovered && (
            <Button
              style={{
                backgroundColor: 'transparent',
                height: '90%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
              }}
              onClick={handleClearFile}
            >
              <Icons
                style={{ backgroundColor: 'transparent', color: 'black', transition: '0.5s' }}
                icon={faTrash}
              />
            </Button>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default KMLUploader
