import styled from "styled-components";


export const FooterPadrao = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-top: thin solid var(--border-color);
`
