import styled from 'styled-components'

export const ContainerTime = styled.div`
  height: 100%;
  /* background-color: red; */

  .header-title {
    width: 100%;
    /* background-color: blue; */
    font-size: 25px;
    font-weight: 800;
    color: #5da57d;
  }

  .div-header-busca {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background-color: yellow; */
  }

  .div-principal {
    width: 100%;
    height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
  }

  .loading {
    position: absolute;
    top: 40%;
    right: 45%;
  }

  .div-historico {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .div-historico-principal {
    width: 100%;
    display: flex;
  }

  .log-adicionar-div {
    width: 100%;
    display: flex;
  }

  .circle-log {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .circle {
    border-radius: 50%;
    width: 20px;
    min-height: 20px;
    height: 20px;
    margin: 0px;
    padding: 0px;
  }

  // CSS VERSÃO

  .version-line {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .version {
    width: 100%;
    height: 35px;
    min-height: 35px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .line {
    background-color: #9f9f9f;
    width: 1px;
    height: 100%;
    margin: 0 10px;
  }
  //-----------------------------

  //CSS AREA INFO

  .area-info {
    width: 90%;
    height: min-content;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    padding-bottom: 20px;
    transition: 0.3s;
    .circulo-nome-autor {
      font-size: 3px;
      color: #d9d9d9;
    }
  }

  .area-info:hover,
  .area-info:is([data-open='true']) {
    padding: 10px;
    transition: 0.5s;
    border-radius: 5px;
    padding-bottom: 20px;
    .circulo-nome-autor {
      font-size: 3px;
    }
  }

  .area-info:is([data-id='1']):hover {
    background-color: #f6ffed;
    h3 {
      color: #2a6856;
    }
    pre {
      color: #3c8a5f;
    }

    p{
      color: #3c8a5f;
    }

    .polygon {
      color: #2a6856;
    }
    .circulo-nome-autor {
      color: #3c8a5f;
    }
  }
  .area-info:is([data-id='1']):hover,
  .area-info:is([data-id='1']):is([data-open='true']) {
    background-color: #f6ffed;
    h3 {
      color: #2a6856;
    }
    pre {
      color: #3c8a5f;
    }

    p{
      color: #3c8a5f;
    }

    .polygon {
      color: #2a6856;
    }

    .circulo-nome-autor {
      color: #3c8a5f;
    }
  }

  .area-info:is([data-id='2']):hover {
    background-color: #fff2f0;
    h3 {
      color: #ff4d4f;
    }
    pre {
      color: #ff4d4f;
    }

    p{
      color: #ff4d4f;
    }

    .polygon {
      color: #ff4d4f;
    }

    .circulo-nome-autor {
      color: #ff4d4f;
    }
  }

  .area-info:is([data-id='2']):hover,
  .area-info:is([data-id='2']):is([data-open='true']) {
    background-color: #fff2f0;
    h3 {
      color: #ff4d4f;
    }
    pre {
      color: #ff4d4f;
    }

    p{
      color: #ff4d4f;
    }

    .polygon {
      color: #ff4d4f;
    }

    .circulo-nome-autor {
      color: #ff4d4f;
    }
  }

  .area-info:is([data-id='3']):hover {
    background-color: #feffe6;
    h3 {
      color: #d4b106;
    }
    pre {
      color: #d4b106;
    }

    p{
      color: #d4b106;
    }

    .polygon {
      color: #d4b106;
    }

    .circulo-nome-autor {
      color: #d4b106;
    }
  }

  .area-info:is([data-id='3']):hover,
  .area-info:is([data-id='3']):is([data-open='true']) {
    background-color: #feffe6;
    h3 {
      color: #d4b106;
    }
    pre {
      color: #d4b106;
    }

    p{
      color: #d4b106;
    }

    .polygon {
      color: #d4b106;
    }

    .circulo-nome-autor {
      color: #d4b106;
    }
  }

  .area-info:is([data-id='4']):hover {
    background-color: #e6f4ff;
    h3 {
      color: #0958d9;
    }
    pre {
      color: #0958d9;
    }

    p{
      color: #0958d9;
    }

    .polygon {
      color: #0958d9;
    }
    .circulo-nome-autor {
      color: #0958d9;
    }
  }

  .area-info:is([data-id='4']):hover,
  .area-info:is([data-id='4']):is([data-open='true']) {
    background-color: #e6f4ff;
    h3 {
      color: #0958d9;
    }
    pre {
      color: #0958d9;
    }

    p{
      color: #0958d9;
    }

    .polygon {
      color: #0958d9;
    }
    .circulo-nome-autor {
      color: #0958d9;
    }
  }

  .area-info:is([data-id='5']):hover {
    background-color: #fffbe6;
    h3 {
      color: #faad14;
    }
    pre {
      color: #faad14;
    }

    p{
      color: #faad14;
    }

    .polygon {
      color: #faad14;
    }
    .circulo-nome-autor {
      color: #faad14;
    }
  }

  .area-info:is([data-id='5']):hover,
  .area-info:is([data-id='5']):is([data-open='true']) {
    background-color: #fffbe6;
    h3 {
      color: #faad14;
    }
    pre {
      color: #faad14;
    }

    p{
      color: #faad14;
    }

    .polygon {
      color: #faad14;
    }
    .circulo-nome-autor {
      color: #faad14;
    }
  }

  .area-info:is([data-id='6']):hover {
    background-color: #f9f0ff;
    h3 {
      color: #531dab;
    }
    pre {
      color: #531dab;
    }

    p{
      color: #531dab;
    }

    .polygon {
      color: #531dab;
    }
    .circulo-nome-autor {
      color: #531dab;
    }
  }

  .area-info:is([data-id='6']):hover,
  .area-info:is([data-id='6']):is([data-open='true']) {
    background-color: #f9f0ff;
    h3 {
      color: #531dab;
    }
    pre {
      color: #531dab;
    }

    p{
      color: #531dab;
    }

    .polygon {
      color: #531dab;
    }
    .circulo-nome-autor {
      color: #531dab;
    }
  }

  .area-descricao-new {
    width: 90%;
    box-sizing: border-box;
    padding-bottom: 20px;
    
  }

  .descricao-new {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins', 'Montserrat', arial, sans-serif;
    white-space: pre-wrap; 
    word-wrap: break-word;
    overflow: hidden;
    color: #9f9f9f;
    padding: 0;
    margin: 0;
    padding-bottom: 5px;
  }

  .descricao-new:is([data-id='1']):hover,
  .descricao-new:is([data-id='1']):is([data-open='true']) {
    color: #3c8a5f;
  }
  .descricao-new:is([data-id='2']):hover,
  .descricao-new:is([data-id='2']):is([data-open='true']) {
    color: #ff4d4f;
  }
  .descricao-new:is([data-id='3']):hover,
  .descricao-new:is([data-id='3']):is([data-open='true']) {
    color: #d4b106;
  }
  .descricao-new:is([data-id='4']):hover,
  .descricao-new:is([data-id='4']):is([data-open='true']) {
    color: #0958d9;
  }
  .descricao-new:is([data-id='5']):hover,
  .descricao-new:is([data-id='5']):is([data-open='true']) {
    color: #faad14;
  }
  .descricao-new:is([data-id='6']):hover,
  .descricao-new:is([data-id='6']):is([data-open='true']) {
    color: #531dab;
  }

  .header-info {
    width: 100%;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .campo-polygon-trash {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .campo_acoes {
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .trash-btn {
    opacity: 0;
  }

  .area-info:is([data-open='true']) .trash-btn {
    color: #d70000;
    font-size: 13px;
    opacity: 1;
  }

  .edit-btn {
    opacity: 0;
  }

  .area-info:is([data-open='true']) .edit-btn {
    color: #3c8a5f;
    font-size: 13px;
    margin-bottom: 10px;
    opacity: 1;
  }

  .polygon {
    font-size: 15px;
    color: #9f9f9f;
    transition: 0.3s;
  }

  .nome-tag {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: none;
  }

  .descricao {
    margin: 0;
    padding: 0;
    font-size: 14px;
    overflow: none;
    font-family: 'Poppins', 'Montserrat', arial, sans-serif;
    white-space: pre-wrap; /* Permite quebra de linha dentro do <pre> */
    word-wrap: break-word;
    overflow: hidden;
  }

  .area-info:is([data-open='true']) .descricao {
    margin: 0;
    padding: 0;
    font-size: 13px;
    transition: 0.4s;
    transform: scale(1);
    transform-origin: left;
    font-family: 'Poppins', 'Montserrat', arial, sans-serif;
    overflow: hidden;
    white-space: pre-wrap; /* Permite quebra de linha dentro do <pre> */
    word-wrap: break-word;
  }

  .area-info:is([data-open='true']) .polygon {
    transform: rotate(90deg);
  }

  .title-log {
    width: 100%;
    font-size: 16px;
    font-weight: 800;
    color: #9f9f9f;
    margin: 0;
    padding: 0;
  }

  .tag {
    width: min-content;
    box-sizing: border-box;
    padding: 0px 10px;
    font-size: 10px;
    border-radius: 5px;
    margin-top: -8px;
    box-sizing: border-box;
  }

  .nome-autor {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .p-nome {
    font-size: 11px;
    color: #9f9f9f;
    margin: 0px;
    padding: 0px;
    opacity: 0.9;
  }

  //--------------------------

  .btn-cadastrar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5da57d;
    cursor: pointer;
    transition: 0.2s;
  }

  .btn-cadastrar:hover {
    background-color: #4d8a6c;
    transform: scale(1.1);
    transition: 0.5s;
  }
`
// FORM CADASTRAR TIMELINE
export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  .header-close {
    display: flex;
    justify-content: space-between;
  }

  .title-cadastro {
    font-size: 20px;
    font-weight: 800;
    color: #5da57d;
    margin: 0;
    padding: 0;
  }

  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .area-btn-add-log {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .area-btn-salvar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  //FORM LOG

  .add-new-log {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .form-new-log {
    width: 90%;
    border-radius: 5px;
    border: 1px solid #9f9f9f;
    padding: 10px;

    h3 {
      font-size: 13px;
    }
  }

  .area-logs-new {
    width: 100%;
    border-left: 3px solid #2a6856;
    padding-left: 5px;
    padding-bottom: 5px;
  }

  .logs-new {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #9f9f9f;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }

  .type-descricao {
    width: 85%;
    display: flex;
    align-items: center;
    gap: 10px;

    .descricao-newlog {
      width: 85%;
    }

    .descricao-p {
      font-size: 13px;
      margin: 0;
      padding: 0;
    }
  }

  .btn-ações {
    width: 12%;
    display: flex;
    align-items: center;

    button {
      cursor: pointer;
      height: 100%;
      background-color: transparent;
      border: none;
      transition: 0.2s;
    }

    button:hover {
      transform: scale(1.1);
      transition: 0.5s;
    }
  }

  .add-descricao-new {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .line-divisor {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
  }

  .btn-inserir-log {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: transparent;
      border: 1px solid #5da57d;
      border-radius: 5px;
      cursor: pointer;
      color: #5da57d;
      transition: 0.2s;
    }

    button:hover {
      background-color: #5da57d;
      color: white;
      transform: scale(1.1);
      transition: 0.5s;
    }
  }
`
