/* eslint-disable no-underscore-dangle */
import 'idwpolygon'
import * as L from 'leaflet'
import { uniq } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { tryError } from '../../services/Afins'
import { Container } from './styled'
import { cores_monitoramento, cores_monitoramento_invertido } from '../monitoramento/meta'

interface CanvasInterpolationProps {
  dataGroundOverlay: TypesGerarGroupOverlay[]
  onOverlayView: (overlay: TypesOverlayView[]) => void
}

export interface TypesOverlayView {
  url: string
  praga: string
  boundsL: L.LatLngBoundsExpression
  bounds: {
    north: number
    south: number
    east: number
    west: number
  }
  key: string
  polygon?: google.maps.LatLngLiteral[]
  cor?: string
}

export interface TypesGerarGroupOverlay {
  key?: string
  nivelVari: string
  talhao_observacao: string
  praga?: [number, number, string][]
  polyCoord: L.LatLngTuple[]
  inverterEscala: boolean
}

// export const gradient = {
//   0.0: '#01ff00', // 0
//   0.1: '#01ff00', // 0
//   0.2: '#fcff02', // 1
//   0.4: '#fcff02', // 2
//   0.8: '#fcb700', // 3
//   1.0: '#fb0303', // 4
// }

// const gradientI = {
//   1.0: '#01ff00', // 0
//   0.8: '#01ff00', // 0
//   0.4: '#b8ff05', // 1
//   0.2: '#ffff02', // 2
//   0.1: '#FCFF02', // 3
//   0.0: '#FCFF02', // 4
// }

export function gerarGroupOverlay(data: TypesGerarGroupOverlay[]) {
  try {
    const polygon = new L.Polygon([], { weight: 0, fillOpacity: 1 })

    let dataFim = []
    for (let i = 0; i < data.length; i++) {
      const idw = data[i]
      const polygonGoogle = new google.maps.Polygon({
        paths: idw.polyCoord.map((rec) => {
          return { lat: rec[0], lng: rec[1] }
        }),
      })

      let temp = []

      idw.praga.forEach((item) => {
        const checkPraga = google.maps.geometry.poly.containsLocation(
          new google.maps.LatLng({ lat: item[0], lng: item[1] }),
          polygonGoogle
        )

        if (checkPraga) {
          temp = [...temp, item]
        }
      })

      idw.praga = temp

      dataFim = [...dataFim, idw]
    }

    const overlay = dataFim.map((rec, i) => {
      const map: L.Map = new L.Map(`map_${i}`).setView([0, 0], 13)
      polygon.setLatLngs(rec.polyCoord)
      const bounds = map.fitBounds(polygon.getBounds())

      const sizePragas = uniq(rec.praga.map((p) => parseFloat(p[2]).toFixed(1)))

      const grad = rec.inverterEscala ? cores_monitoramento_invertido : cores_monitoramento

      let url = ''
      if (rec.praga.length > 2 && sizePragas.length > 1) {
        // @ts-ignore

        const idw = new L.IdwLayer(rec.praga, rec.polyCoord, {
          opacity: 1,
          maxZoom: 18,
          cellSize: 3,
          gradient: grad,
          exp: 2, //raio do blur
          max: 1, //cor do blur
          min: -1,
        }).addTo(map)
        url = idw._canvas.toDataURL()
      } else {
        url = null
      }
      // map.removeLayer(idw)

      document.getElementById(`map_${i}`)?.remove()

      return {
        url,
        praga: rec.nivelVari,
        boundsL: bounds.getBounds(),
        bounds: {
          north: bounds.getBounds().getNorth(),
          south: bounds.getBounds().getSouth(),
          east: bounds.getBounds().getEast(),
          west: bounds.getBounds().getWest(),
        },
        key: rec.key,
        ...(url === null
          ? {
              polygon: rec.polyCoord.map((item) => {
                return { lat: item[0], lng: item[1] }
              }),
              cor:
                rec.praga.length <= 2 ? '#acacac' : grad[Number(Number(sizePragas[0]).toFixed(1))],
            }
          : {
              polygon: rec.polyCoord.map((item) => {
                return { lat: item[0], lng: item[1] }
              }),
            }),
      }
    })

    return overlay
  } catch (error) {
    tryError(error)
  }
}

const CanvasInterpolation: React.FC<CanvasInterpolationProps> = (props) => {
  const { onOverlayView, dataGroundOverlay } = props
  const refMaster = useRef<HTMLDivElement>()

  useEffect(() => {
    const divMaster = refMaster.current
    divMaster.innerText = ''
    for (let i = 0; i < dataGroundOverlay.length; i += 1) {
      const newDiv = document.createElement('div')
      newDiv.id = `map_${i}`
      divMaster.appendChild(newDiv)
    }
    onOverlayView(gerarGroupOverlay(dataGroundOverlay))
  }, [dataGroundOverlay])

  return <Container ref={refMaster} />
}

export default CanvasInterpolation
