import styled from "styled-components";

export const Confirmar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    div.Confirmar__phrase_container {
        margin-top: -10px;
        height: 35px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        cursor: not-allowed;
        /* pointer-events: none; */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        position: relative;
        background: var(--hover-color);
        color: var(--text-color);
        font-weight: 600;
        box-sizing: border-box;
        padding: 0px 10px;
        border-radius: 4px;
        font-style: oblique;

        div.Confirmar__phrase_container__locker {
            position: absolute;
            width: 100%;
            height: 35px;
            top: 0;
            left: 0;
            cursor: not-allowed;
        }
    }
    

    div.Confirmar__footer {
        width: 100%;
        height: 45px;
        border-top: thin solid var(--border-color);
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
`;