import styled from 'styled-components'

export const Container = styled.div`
  overflow-x: hidden;
  position: relative;
  height: '100vh';
  overflow: 'auto';

  .ant-card-bordered {
    border-radius: 5px;
  }
  main {
    overflow-x: hidden;
  }
  h1 {
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    white-space: pre-line;
  }

  div.icon {
    font-size: 50px;
    padding: 25px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .ant-divider {
    margin-top: 0 !important;
  }

  .ant-divider-inner-text {
    color: #000000 !important;
  }

  .listAniversariantes {
    width: 200px;
    height: 200px;
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    align-content: center;
  }
  .listAniversariantes span {
    color: #283142;
  }
`
export const Painel = styled.div`
  position: absolute;
  opacity: 0.8;
  width: 100%;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  transition: 0.5s;
  display: flex;

  &:hover {
    opacity: 1;
  }
`

export const PainelNiver = styled.div`
  position: absolute;
  padding: 2px;
  opacity: 0.8;
  width: calc(100% - 33rem - 0px);
  margin: 0.5rem;
  transition: 0.5s;
  right: 0;

  &:hover {
    opacity: 1;
  }
`
