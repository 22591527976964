import { BoxContainer } from "./styled"

//PROPS
interface Props {
    children?: React.ReactNode;
    borderRadius?: number;
    padding?: number | string;
    gap?: number;
    style?: React.CSSProperties;
}

export const Box: React.FC<Props> = ({ children, padding, borderRadius, gap, style }) => {
    return (
        <BoxContainer style={{...style, borderRadius: borderRadius, padding: padding, gap: gap}}>
            {children}
        </BoxContainer>
    )
}