import React, { ReactNode, useEffect, useRef, useState } from 'react'

import {
  Body,
  BodyContent,
  BodyHeader,
  BodyTitle,
  Container,
  ContentHeader,
  Extra,
  Header,
  HeaderTitle,
} from './styled'

export interface PropsColapseMoni {
  client: string
  propriedade: string
  headerContent: ReactNode
  safraName: string
  extraHeader?: ReactNode
  progress?: ReactNode
  onChange?: (cod: number) => void
  onClose?: (b: boolean) => void
  closeContent?: boolean
  talhao?: {
    codTalhao: number
    nameTalhao: string
    isSubarea?: boolean
    nameTalhaoSubArea: string
    extraBody: ReactNode
    content: ReactNode
  }[]
}

const ColapseMoni: React.FC<PropsColapseMoni> = (props) => {
  const {
    client,
    headerContent,
    propriedade,
    safraName,
    talhao = [],
    extraHeader,
    progress,
    onChange,
    onClose,
  } = props

  let { closeContent = false } = props

  const [open, setOpen] = useState(false)
  const [contentView, setContentView] = useState<ReactNode>()
  const [colapseSelect, setColapseSelect] = useState(null)

  const headerRef = useRef<HTMLDivElement>()

  const handleClickColapse = (content, cod) => {
    onChange?.(cod)
    setOpen(true)
    onClose?.(true)
    setContentView(content)
    setColapseSelect(cod)
  }

  const handleClose = () => {
    setOpen(false)
    onClose?.(false)
    setContentView(null)
    onChange?.(-1)
    setColapseSelect(-1)
  }

  useEffect(() => {
    onClose?.(closeContent)
    setOpen(closeContent)
  }, [closeContent])

  // console.log("talhao: ", talhao)

  return (
    <Container>
      <Header>
        <HeaderTitle ref={headerRef}>
          <div>
            <button>
              <button
                style={{ zIndex: 9999 }}
                onClick={() =>
                  colapseSelect === -1 && open
                    ? handleClose()
                    : handleClickColapse(headerContent, -1)
                }
              >
                {colapseSelect === -1 && open ? 'x' : '>'}
              </button>
            </button>
            <div onClick={() => handleClickColapse(headerContent, -1)}>
              <span>{propriedade} 555</span>
              <small>Toda propriedade</small>
              <small>
                Cliente: {client}, Safra: {safraName}
              </small>
            </div>
          </div>

          <Extra>{extraHeader}</Extra>
        </HeaderTitle>
      </Header>
      {progress}
      <Body hideOverflow={open}>
        {talhao.map((rec) => {
          const content = (
            <>
              <BodyTitle>
                <div>
                  <button>{'>'}</button>
                  <span>{rec.nameTalhao} 456 111</span>
                </div>
                {rec?.isSubarea && <small>Subárea do talhão {rec?.nameTalhaoSubArea}</small>}
              </BodyTitle>
              <Extra>{rec.extraBody}</Extra>
            </>
          )
          return (
            <>
              <BodyHeader
                key={crypto.randomUUID?.()}
                onClick={() => handleClickColapse(rec.content, rec.codTalhao)}
              >
                {content}
              </BodyHeader>
            </>
          )
        })}
        <BodyContent open={open}>
          <>
            {colapseSelect !== -1 && (
              <ContentHeader>
                <div>
                  <button onClick={handleClose}>{'x'}</button>
                  <span>{talhao.find((rec) => rec.codTalhao === colapseSelect)?.nameTalhao}</span>
                </div>
                <Extra>{talhao.find((rec) => rec.codTalhao === colapseSelect)?.extraBody}</Extra>
              </ContentHeader>
            )}

            {contentView}
          </>
        </BodyContent>
      </Body>
    </Container>
  )
}

export default ColapseMoni
