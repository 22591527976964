import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { Confirmar } from "./style";

//COMPONENTS
import Input from "../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../Components/CustomUi/textarea";
import ButtonCustom from "../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../Components/CustomUi/box";
import { Warning } from "../../../../../../../Components/CustomUi/warning";
import { useDeleteTalhaoSafra } from "../../../../../../../services/TalhaoSafra/DELETE";
import { gerarKey } from "../../../../../../../utils";

//PROPS
interface Props {
    onClose: Function;
    cod_propriedade: number;
    cod_talhao: number;
    nome_talhao: string;
    atualizarLista: Function;
    resposta_erro: string;
}

export const ConfirmarExclusao: React.FC<Props> = ({ onClose, cod_propriedade, cod_talhao, nome_talhao, atualizarLista, resposta_erro }) => {

    //CONST
    const frase:string = 'EXCLUIR TALHÃO'+' '+String(nome_talhao).toUpperCase();
    const split_msg_erro: string[] = String(resposta_erro).split('!');

    //STATES
    const [motivo, setMotivo] = useState<string>('');
    const [frase_digitada, setFrase_digitada] = useState<string>('');

    //API
    const { mutate_deletar_talhao_safra, carregando_deletar_talhao_safra } = useDeleteTalhaoSafra();
    

    return (
        <Confirmar>

            <Warning type="info">
                Este talhão possui as dependencias abaixo, neste caso será necessário informar o motivo da exclusão.
            </Warning>

            {split_msg_erro.map((mensagem, index)=> {
                if (mensagem!=='') {
                    return (
                        <Warning type="error" key={gerarKey(index)}>
                            {String(mensagem).replace(", para este registro", '')}
                        </Warning>
                    )
                }
                
            })}
            

            <TextArea 
                label="Informe o motivo da exclusão"
                placeholder="Descreva o motivo do porque o talhão está sendo excluído"
                resize={false}
                value={motivo}
                onChange={setMotivo}
                height={80}
            />

            
            <Box>
                <p style={{fontSize:13, margin:0}}>Para confirmar a exclusão digite a frase a seguir no campo abaixo:</p>
                <div className="Confirmar__phrase_container">
                    {frase}
                    <div className="Confirmar__phrase_container__locker"/>
                </div>
                    
                <Input 
                    placeholder="digite a frase aqui"
                    value={frase_digitada}
                    onChange={setFrase_digitada}
                    icon={faClipboardCheck}
                    error={frase_digitada?.toLowerCase() != frase?.toLowerCase()}
                    textTransform="uppercase"
                />
            </Box>
            
            <div className="Confirmar__footer">
                <ButtonCustom
                    onClick={()=>{onClose()}}
                    type="tertiary"
                    icon={<FontAwesomeIcon icon={faXmark} />}
                    disabled={carregando_deletar_talhao_safra}
                >
                    Cancelar
                </ButtonCustom>
                <ButtonCustom
                    type="primary"
                    danger
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    disabled={motivo.length===0 || frase_digitada?.toLowerCase() != frase?.toLowerCase() || carregando_deletar_talhao_safra}
                    onClick={()=>{mutate_deletar_talhao_safra({
                        codPro: cod_propriedade,
                        codTalhao: cod_talhao,
                        motivo: motivo,
                        confirmacao: String(nome_talhao).toLowerCase(),
                        atualizarLista: ()=>{atualizarLista(cod_propriedade, '')},
                        closePopup: ()=>{onClose()}
                    })}}
                    loading={carregando_deletar_talhao_safra}
                >
                    Confirmar exclusão
                </ButtonCustom>
            </div>

        </Confirmar>
    )
}