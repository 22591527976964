
export const cores_monitoramento = {
    0.0: '#01ff00', // 0
    0.1: '#01ff00', // 0
    0.2: '#fcff02', // 1
    0.4: '#fcff02', // 2
    0.8: '#fcb700', // 3
    1.0: '#fb0303', // 4
}
  
export const cores_monitoramento_invertido = {
    1.0: '#01ff00', // 0
    0.8: '#01ff00', // 0
    0.4: '#b8ff05', // 1
    0.2: '#fcff02', // 2
    0.1: '#fcff02', // 3
    0.0: '#fcff02', // 4
}