import React, { useEffect, useState } from 'react'

import { GoogleMap, Polygon } from '@react-google-maps/api'
import 'leaflet/dist/leaflet.css'
import { getBoundsNoArray } from '../../services/Afins'

export interface TypesMaps {
  coord: google.maps.LatLng[] | google.maps.LatLngLiteral[]
  customHeight?: number | string
}

const Maps: React.FC<TypesMaps> = (props) => {
  const { coord, customHeight } = props
  const [map, setMap] = useState<google.maps.Map>()
  const [polygon, setPolygon] = useState<google.maps.Polygon>()

  const optionsPolygon: google.maps.PolygonOptions = { fillColor: 'aqua', strokeColor: 'aqua' }
  const optionsMap: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
  }
  const brazilCoords = { lat: -14.235, lng: -51.9253 }
  useEffect(() => {
    if (map && coord && polygon) {
      map.fitBounds(getBoundsNoArray(coord))
      polygon.setPath(coord)
    }
  })

  return (
    // @ts-ignore
    <GoogleMap
      onLoad={setMap}
      mapContainerStyle={{ minWidth: '100%', maxWidth: '500px', height: customHeight ?? '50vh' }}
      zoom={6}
      center={brazilCoords}
      options={optionsMap}
    >
      <Polygon onLoad={setPolygon} options={optionsPolygon} />
    </GoogleMap>
  )
}

export default Maps
