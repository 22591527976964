import { groupBy } from "lodash";
import { v4 as uid } from 'uuid';
import { MonitoramentoMapCalorType } from "../../..";
import { LeiturasMonitoramentoType } from "../../../../../services/monitoramento";
import { MoniNivelItemControleItens } from "../../../../../services/monitoramento/nivelControleItens";
import { ItemCor, mediaMapaCalor } from "../../tabelaPragas/meta";
import { cores_monitoramento, cores_monitoramento_invertido } from "../../../meta";

export interface MonitoramentoMapaCalorMonitoramentoType {
    lat: number;
    lng: number;
    nivel: LeiturasMonitoramentoType['nivel_controle_item']
    ponto: number;
    colmo?: string;
    horaFim: string;
    dataFim: string;
    estadio: string;
    horaInit: string;
    dataInit: string;
    altitude?: string;
    codTalhao?: number;
    repeticao?: any[];
    codEstadio: number;
    codEspVari: string;
    nomeTalhao: string;
    valorNivel: string;
    nomeUsuario: string;
    numRepeticao?: number;
    valorApurado: string;
    codMonitoramento?: number;
    codNivelControleItem: number;
  }

export function obterTalhoes(main_data: {[key: string]:  MonitoramentoMapCalorType[]}):{cod_talhao:number,nome_talhao:string}[] {

    const main_data_array = Object.entries(main_data ?? {});
    const list_talhoes = (main_data_array ?? []).map((item)=> {
        return {
            cod_talhao: Number(item?.[0]),
            nome_talhao: (item?.[1])?.[0]?.monitoramento?.[0]?.[0]?.nomeTalhao
        }
    })
    
    return list_talhoes ?? [];
};

export function esconderAccordion(current_cod: number | null, cod_talhao: number | null): undefined | 'none' {
    if (current_cod === null) {
        return undefined;
    }
    return current_cod === cod_talhao ? undefined : 'none'
}


export function processarTodosTalhoes(all_data: { [key: string]: MonitoramentoMapaCalorMonitoramentoType[]; }[], nivelControle, listDates, groupTotalizar) {

    let processar_lista = all_data.map((rec)=> {
        let result = Object.entries(rec).map(([key, value], i) => {
            const byDate = groupBy(value, 'dataInit')
            const split = key.split(', ')
            const codESpecie = Number(split[1])
            const codVariaca = split[2] === 'null' ? null : Number(split[2])
            let nivel: MoniNivelItemControleItens =
              {} as MoniNivelItemControleItens

            if (!codVariaca) {
              nivel = nivelControle.find(
                (item) => item.cod_especie === codESpecie
              )
            } else {
              nivel = nivelControle.find(
                (item) =>
                  item.cod_especie === codESpecie &&
                  item.cod_variacao_especie === codVariaca
              )
            }

            const moni = listDates.value.map((rec) => {

              const obj = Object.keys(byDate).filter((k) => rec === k) //NOSONAR

              if (obj.length > 0) {
                const temp = Object.entries(byDate).filter(
                  ([k, v]: any) => obj[0] === k //NOSONAR
                )[0]
                return { [temp[0]]: temp[1] }
              }
              return { [rec]: [] }
            })

            
            function nomeVariacao() {
                if (nivel?.variacao_especie_nome !== null) {
                    if (groupTotalizar && nivel?.totalizar === 1) {
                        return null
                    }
                }
                return nivel?.variacao_especie_nome;
            }

            function processarMonitoramento() {
                let dados_monitoramento = moni.map((rec)=> {//NOSONAR
                    return Object.entries(rec).map(([nome_propri, valor]: any)=> {

                        const arrayValues = valor.map((item) =>
                            parseFloat(item.valorApurado)
                        ) 
                        const nivel = valor?.[0]?.nivel?.[0];

                        let is_inverter_escala = (nivel?.inverter_escala ?? 1) === 1;

                        function ocultarDoMapa() {
                            if (!is_inverter_escala) {
                                return false;
                            }
                            let is_valarApurado_sempre_zero = arrayValues.reduce((acumulador: number, item:number)=> acumulador + item, 0) === 0;
                            return is_valarApurado_sempre_zero;
                        }

                        const grad = nivel?.inverter_escala === 1 ? cores_monitoramento_invertido : cores_monitoramento;

                        let color = { cor: '' };

                        if (Math.max(...arrayValues) === 0) {
                            color.cor = grad['0']
                        } else {
                            color = mediaMapaCalor(
                              Math.max(...arrayValues),
                              Number(valor?.[0]?.valorNivel).toFixed(1)
                            )
                        }

                        const med = (
                            arrayValues.reduce(
                              (partialSum, a) => partialSum + a,
                              0
                            ) / arrayValues.length
                        ).toFixed(1)

                        const chave = uid();

                        return {                            
                            key: chave,
                            media: med,
                            info_cor: color,
                            cor: color?.cor,
                            key_atributo: nome_propri,
                            valor: valor,
                            resultado_minimo: Math.min(...arrayValues).toFixed(1),
                            resultado_media: Number(med).toFixed(1),
                            resultado_maximo: Math.max(...arrayValues).toFixed(1),
                            is_inverter_escala: is_inverter_escala,
                            oculdar_do_mapa: ocultarDoMapa()
                        }

                    })
                })

                let cores_com_media = dados_monitoramento.filter((cor) => { return cor[0].media !== 'NaN' && !(cor?.[0]?.oculdar_do_mapa) })//NOSONAR

                let cor_verde_forte = cores_com_media.filter((cor) => { return cor[0].cor === '#01ff00' }).map((item: ItemCor[]) => {//NOSONAR
                    return item[0].cor;
                })
                let cor_verde_claro = cores_com_media.filter((cor) => { return cor[0].cor === '#b8ff05' }).map((item: ItemCor[]) => {//NOSONAR
                    return item[0].cor;
                })
                let cor_amarelo_claro = cores_com_media.filter((cor) => { return cor[0].cor === '#fcff02' }).map((item: ItemCor[]) => {//NOSONAR
                    return item[0].cor;
                })
                let cor_amarelo_forte = cores_com_media.filter((cor) => { return cor[0].cor === '#fcb700' }).map((item: ItemCor[]) => {//NOSONAR
                    return item[0].cor;
                })
                let cor_vermelho = cores_com_media.filter((cor) => { return cor[0].cor === '#fb0303' }).map((item: ItemCor[]) => {//NOSONAR
                    return item[0].cor;
                })

                let dados_gerais = dados_monitoramento.map((item) => {//NOSONAR
                    return {
                        ... item?.[0],
                        cod_talhao: null
                    }
                });

                function isOcultarDoMapa() {
                    return (dados_gerais ?? []).reduce((acumulador:boolean,item:any)=> acumulador && item.oculdar_do_mapa, true);
                }

                return {
                    cor_verde_forte: cor_verde_forte.length,
                    cor_verde_claro: cor_verde_claro.length,
                    cor_amarelo_claro: cor_amarelo_claro.length,
                    cor_amarelo_forte: cor_amarelo_forte.length,
                    cor_vermelho: cor_vermelho.length,
                    dados_gerais: dados_gerais,
                    ocultar_do_mapa: isOcultarDoMapa()
                };
            }

            return {
                especie_nome: nivel?.especie_nome,
                variacao: nomeVariacao(),
                cores: processarMonitoramento(),
                datas: listDates.value,
                key: uid()
            }

        })

        return result
    });

    let exibir_atributo_acultar_mapa = (processar_lista?.[0]??[]).map((item)=> {
        return {
            ...item,
            ocultar_do_mapa: (item.cores.dados_gerais?.map(item=>item.oculdar_do_mapa) ?? []).reduce((acc:boolean, item:boolean)=> acc && item, true)
        }
    });

    let remover_oculatar_do_mapa = exibir_atributo_acultar_mapa.filter(item=>item.ocultar_do_mapa === false);
        
    return remover_oculatar_do_mapa ?? []
}


export function obterAlturaListaTodasPragas(currentHeight: number) {
    if (currentHeight > 880) {
        return 590;
    }
    
    return 370;
}