import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'
import { message } from 'antd'

export interface TypesRotina {
  itens: ItensRotinaTypes[]
}

export interface ItensRotinaTypes {
  cod_rotina: number
  cod: number
  nome: string
  cod_modulo: number
  modulo_nome?: string
  acesso: number | boolean | any
  inclusao: number | boolean | any
  alteracao: number | boolean | any
  exclusao: number | boolean | any
  modulo_cod: number | null
  descricao?: string
}

type TypesQuery = {
  cod?: number
  embed?: 'permissoes'
}

const url = '/rotina'

export const getRotina = async (params: TypesQuery) => {
  const { conta, codCliente, acesso } = localConfig()
  let moduloCliente
  if (acesso === 'cliente') {
    moduloCliente = 1
  } else {
    moduloCliente = 0
  }
  const resp: AxiosResponse<TypesRotina> = await api.get(url, {
    params: {
      codConta: conta,
      codCliente,
      ...params,
      moduloCliente,
      pagination: -1,
    },
  })

  return resp
}

export const getRotinaEdit = async (params: TypesQuery) => {
  const { conta, codCliente } = localConfig()

  const resp: AxiosResponse<TypesRotina> = await api.get('/rotina-admin', {
    params: {
      codConta: conta,
      codCliente,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const putRotinaEdit = async (data, cod, setLoading, fechar) => {
  const { codCliente, conta } = localConfig()

  const resp: AxiosResponse<TypesRotina> = await api
    .put(`${url}/${cod}`, data, {
      params: {
        codCliente,
        codConta: conta,
      },
    })
    .then((): any => {
      message.success({
        content: 'Permissão editada com sucesso!',
        key: 'loading',
        duration: 5,
      })
      setLoading(false)
      fechar()
    })
    .catch((error) => {
      message.error({ content: `${error?.response?.data?.error[0].msg.join('/n')}`, key: 'loading' })
      setLoading(false)
    })

  return resp
}

export const deleteRotinaEdit = async (cod, setLoadingDeletar) => {
  const resp: AxiosResponse<TypesRotina> = await api
    .delete(`${url}/${cod}`)
    .then((): any => {
      message.success({
        content: 'Permissão deletada com sucesso!',
        key: 'loading',
        duration: 5,
      })
      setLoadingDeletar(false)
    })
    .catch((error) => {
      message.error({ content: `${error?.response?.data?.error[0]?.msg}`, key: 'loading' })
      setLoadingDeletar(false)
    })

  return resp
}

export const postRotina = async (data, setLoading, close) => {
  const { codCliente, conta } = localConfig()
  api.defaults.headers.Acesso = 'admin'
  const resp: AxiosResponse<TypesRotina> = await api
    .post(url, data, {
      params: {
        codCliente,
        codConta: conta,
      },
    })
    .then((): any => {
      message.success({
        content: 'Permissão cadastrada com sucesso!',
        key: 'loading',
        duration: 5,
      })
      setLoading(false)
      close()
    })
    .catch((error) => {
      message.error({ content: `${error?.response?.data?.error[0]?.msg}`, key: 'loading' })
      setLoading(false)
    })

  return resp
}

export const putRotina = async (data, cod) => {
  const { codCliente, conta } = localConfig()
  api.defaults.headers.Acesso = 'admin'

  const resp: AxiosResponse<TypesRotina> = await api.put(`${url}/${cod}`, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}
