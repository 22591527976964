import styled, { css } from 'styled-components'

export const Container = styled.div<{ responsive?: boolean }>`
  ${(p) =>
    p.responsive &&
    css`
      width: 92vw;
      height: 90vh;
      overflow: auto;
    `}
  .card-temp {
    display: flex;
    justify-content: space-between;
  }

  .main-container {
    width: 500px;
    background-color: #fff;
    border-radius: 10px;
    backdrop-filter: blur(15px);
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
   
  }

  .card-temp-city {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
  }

  .campo-info-clima {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .campo-temp {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;

    .temperatura {
      display: flex;
      flex-direction: column;

      .maxima__minima{
        display: flex;
        gap: 10px; 
        justify-content: center;

        b{
          color: #939393
        }
      }
    }

    h2 {
      font-size: 50px;
      font-weight: 700;
      padding: 0;
      margin: 0;
    }

    h4 {
      font-size: 16px;
      padding: 0;
      margin: 0;
      color: #3d7054;
      text-transform: capitalize;
    }
  }

  .img-nuvem {
    width: 30%;
  }

  .infos-climas {
    width: 100%;
  }

  .infos-do-clima {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .campo-infos {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center ;
    }

    .infos-header {
      display: flex;
      justify-content: center;
      gap: 5px;
      align-items: center;

      h4 {
        color:  #3d7054;
        font-size: 16px;
        margin: 0;
        padding: 0;
      }
    }

    
  }

  .container-clima-semana{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      gap: 10px;
      overflow-x: auto;
      background-color: #f9f9f9;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 5px;

      .cards-clima-semana{
        width: 19%;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 15px;

        .max__min{
          display: flex;
          flex-direction: column;
         
        }
      }
    }

  .localizacao {
    display: flex;
    gap: 10px;

    h4 {
      padding: 0;
      margin: 0;
    }
  }
`