/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { faAngleDoubleLeft, faAngleDoubleRight, faClose } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, CardProps, Divider } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import React, { ReactNode, useRef } from 'react'
import Icons from '../Icons'

import { Container } from './styles'

interface SidePainelProps {
  title: string | ReactNode
  size?: CardProps['size']
  extra?: React.ReactNode
  hideOverflow?: boolean
  hideSize?: SizeType
  onSave?: () => void
  onClose?: (data?: boolean) => void
  textSave?: 'string'
  textCancel?: 'string'
  hideSave?: boolean
  hideCancel?: boolean
  placement?: 'left' | 'right'
  open: boolean
  onCloseSide?: (toogle: boolean) => void
  buttonExtra?: React.ReactNode
  actions?: React.ReactNode[]
  disable_save?: boolean
  loading_save?: boolean
}

const SidePainel: React.FC<SidePainelProps> = ({
  title,
  size = 'default',
  children,
  extra,
  hideSize,
  hideOverflow = false,
  onSave,
  onClose,
  textSave = 'Salvar',
  textCancel = 'Sair',
  hideSave = false,
  hideCancel = false,
  placement = 'left',
  onCloseSide,
  open = false,
  buttonExtra,
  actions = [],
  disable_save = false,
  loading_save = false
}) => {
  const refContainer = useRef<HTMLDivElement>(null)

  let direction
  switch (placement) {
    case 'left':
      direction = faAngleDoubleLeft
      break

    case 'right':
      direction = faAngleDoubleRight
      break

    default:
      break
  }
  return (
    <Container ref={refContainer} toggle={open}>
      <Card
        title={title}
        size={size}
        bodyStyle={{ maxHeight: '80vh', overflowY: hideOverflow ? 'hidden' : 'auto' }}
        extra={
          <>
            {extra}
            {extra ? <Divider type='vertical' /> : null}
            <Button
              ghost
              size={hideSize}
              onClick={() => {
                onCloseSide?.(!open)
              }}
              icon={<Icons icon={open ? direction : faClose} />}
            />
          </>
        }
        actions={[
          <div className='btn-save'>
            <Button hidden={hideSave} type='primary' loading={loading_save} disabled={disable_save} onClick={onSave}>
              {textSave}
            </Button>
            <Button hidden={hideCancel} type='primary' danger onClick={() => onClose()}>
              {textCancel}
            </Button>
            {buttonExtra}
          </div>,
          ...actions,
        ]}
      >
        <div className='content'>{children}</div>
      </Card>
    </Container>
  )
}

export default SidePainel
