import {
  faBook,
  faBoxArchive,
  faHandHoldingDroplet,
  faHome,
  faListCheck,
  faLock,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons'
import { ADMIN_PREFIX_PATH } from './AppConfig'

const dashBoardNavTree = [
  {
    key: 'home',
    id: '',
    hidden: false,
    title: 'home',
    path: `${ADMIN_PREFIX_PATH}/home`,
    icon: faHome,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'config',
    id: '',
    hidden: false,
    title: 'Cadastros gerais',
    icon: faBook,
    breadcrumb: true,
    submenu: [
      {
        key: 'catPatrimonio',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/config/catpatrimonio`,
        title: 'Categorias patrimônio',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'catProduto',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/config/catprodutos`,
        title: 'Categorias produtos',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'cadastroCultura',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/config/cadastrocultura`,
        title: 'Cultura',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'cadastroMedidas',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/config/cadastro-medidas`,
        title: 'Unidades de medidas',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'Marcas',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/config/marcas`,
        title: 'Marcas',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'estadio',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/config/estadios`,
        title: 'Estadios',
        breadcrumb: false,
        submenu: [],
      },

      {
        key: 'elementos',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/config/elementos`,
        title: 'Elementos',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'user-teste-app',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/config/user-teste-app`,
        title: 'Usuário teste App',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'permissoes',
        id: '',
        hidden: false,
        title: 'Permissões',
        path: `${ADMIN_PREFIX_PATH}/permissoes`,
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'recomendacao',
    id: '',
    title: 'Recomendação',
    // hidden: process.env.NODE_ENV === "production",
    icon: faHandHoldingDroplet,
    breadcrumb: false,
    submenu: [
      {
        key: 'recomendaacaoativostipos',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/recomendacao/tipos`,
        title: 'Tipos de ação para ativos',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dadosquimicos',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/recomendacao/dadosquimicos`,
        title: 'Dados químicos',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'recomendaacaoativos',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/recomendacao/ativos`,
        title: 'Ativos',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'recomendaformulacao',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/recomendacao/formulacao`,
        title: 'Formulação',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },

      {
        key: 'cadastroBioTecnologia',
        id: '',
        path: `${ADMIN_PREFIX_PATH}/recomendacao/cadastro-bioTecnologia`,
        title: 'Biotecnologias',
        icon: '',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'sharedFiles',
    id: '',
    hidden: false,
    title: 'Arquivos compartilhados',
    path: `${ADMIN_PREFIX_PATH}/shared-files`,
    icon: faBoxArchive,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'logs',
    id: '',
    hidden: false,
    title: 'Logs',
    path: `${ADMIN_PREFIX_PATH}/logs`,
    icon: faListCheck,
    breadcrumb: false,
    submenu: [],
  },

  {
    key: '`sair`',
    id: '',
    path: `${ADMIN_PREFIX_PATH}/logout`,
    title: 'Sair',
    icon: faPowerOff,
    // onclick: (data) => {
    //   localStorage.removeItem("acesso");
    //   history.push("/auth/login");
    // },
    breadcrumb: false,
    submenu: [],
  },
]

const navigationConfig = [...dashBoardNavTree]

export default navigationConfig
