import styled from 'styled-components'

export const CardNiver = styled.div`
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  border-radius: 10px;
  max-height: 431px;
  :is([data-open='true']) {
    overflow-y: auto;
  }

  .header__principal {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: space-between;

    .header__titulo {
      display: flex;
      gap: 5px;
      align-items: center;

      h2 {
        font-size: 18px;
        font-weight: 700;
        color: #2d3436;
        padding: 0;
        margin: 0;
        text-shadow: -1px 1px 8px #fff;
      }
    }
  }

  .campo__aniversariantes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 15px;
    width: 100%;
    height: 433px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .campo__aniversariantes:is([data-loading='true']) {
    display: flex;
    width: 100%;
  }
  .campo__aniversariantes:is([data-open='true']) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }


  .card__aniversariante {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    background-size: cover;
    background-position: center;
    border: 2px solid #5da57d;
    border-radius: 5px;

    .titulo__aniversariante {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      color: #2a6856;

      h3 {
        font-size: 16px;
        font-weight: 700;
        padding: 0;
        margin: 0;
        text-shadow: -1px 1px 8px #fff;
      }
    }

    .infos__aniversariante {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 27%;
        border-radius: 50%;
      }
    }
    .nome__data {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      justify-content: center;
      color: #2a6856;

      .nome__aniversariante {
        font-size: 13px;
        padding: 0;
        margin: 0;
        color: #2a6856;
        font-weight: 600;
        text-transform: capitalize;
        overflow: hidden;

        text-shadow: -1px 1px 4px #fff;
        background-color: red;
      }

      .data {
        display: flex;
        align-items: center;
        gap: 5px;
        text-shadow: -1px 1px 4px #fff;
      }
    }
  }

  .proximos__aniversariantes {
    width: 100%;
    height: 120px;
    display: flex;
    gap: 5px;
    padding: 10px 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: 0.2s;

    img {
      width: 35%;
      border-radius: 50%;
    }

    .info__nome__data {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .nome__aniversariante__lista {
        font-size: 12px;
        padding: 0;
        margin: 0;
        color: #2d3436;
        font-weight: 600;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .data {
        display: flex;
        align-items: center;
        gap: 5px;

        p {
          font-size: 11px;
          padding: 0;
          margin: 0;
          color: #2d3436;
        }
      }
    }
  }

  .proximos__aniversariantes:hover {
    transform: scale(1.1);
    transition: 0.5s;
  }
  .proximos__aniversariantes:is([data-hoje='true']) {
    background-color: #e9f7e8;
    border-radius: 5px;
    color: #2a6856;

    .nome__aniversariante__lista {
      color: #2a6856;
    }

    .data {
      p {
        color: #2a6856;
      }
    }
  }

  .proximos__aniversariantes:is([data-passou='true']) {
    opacity: 0.5;
  }
`
