import styled from "styled-components";

export const ModalDatas = styled.div`
    width: 590px;
    /* height: 530px; */
    height: calc(100vh - 250px);
    background-color: white;
    border-radius: 10px;
    position: fixed;
    top: 180px;
    left: 22px;
    z-index: 2000 !important;
    box-shadow: rgba(153, 153, 153, 0.5) 0px 0px 30px;
    transition: .4s;
    transform-origin: bottom;
    opacity: 0;
    animation: entranceModal 0.3s forwards ;

    :is([data-encolher = true]) {
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        opacity: 0;
        transform: scale(0);
    }
    :is([data-ocultar = true]) {
        z-index: 999 !important;
    }

    @keyframes entranceModal {
        0% {
            transform: translateX(0px) translateY(50px) scale(1);
            opacity: 0;
        }
        100% {
            transform: translateX(0px) translateY(0px) scale(1);
            opacity: 1;
        }
    }

    div.ModalDatas__header {
        width: 100%;
        border-bottom: thin solid var(--border-color);
        box-sizing: border-box;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4.MD__header__title {
            font-size: 15px;
            font-weight: 600;
            color: var(--text-color);

            span.MD__header__title__light {
                font-size: 14px;
                font-weight: 500;
                color: var(--text-color-light);
            }
        }
    }

    div.ModalDatas__content {
        width: 100%;
        height: calc(100% - 80px);
        box-sizing: border-box;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

`;


export const Circle = styled.div`
    width: 25px;
    height: 25px;
    background: #bebebe;
    border-radius: 50%;
`;