import styled from 'styled-components'

export const Container = styled.div`
    height: 89.7vh;
    overflow-y: hidden;
`

export const ContainerMap = styled.div`

  .floating {
    .floating-safra {
      position: absolute;
      width: 300px;
      padding: 5px;
      padding-bottom: 0;
      margin: 15px;
      border-radius: 5px;
      background-color: white;
      z-index: 10000;
      right: 0;
    }
  }
`
