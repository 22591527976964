import { Empty } from "antd";
import { faEye, faTableList } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FixedSizeList as List } from 'react-window';

//STYLES
import { ColorCircle, ContentModalPraga } from "./styled";

//APP
import { leadZero, removerAcentos } from "../../../../utils";
import { TypeDetailsMonitoramento } from "../../../../features/Monitoramento/Monitoramento.dataDetails";
import { MoniNivelItemControleItens } from "../../../../services/monitoramento/nivelControleItens";
import { listPragas, obterAlturaListaPragas, obterNomePraga } from "./meta";

//COMPONENTS
import Input from "../../../CustomUi/input";
import Carregando from "../../../CustomUi/loading";
import ButtonCustom from "../../../ButtonCustom";
import { Render } from "../../../CustomUi/render";
import { RowItem } from "../../../CustomUi/table/rowItem";
import { RowTable } from "../../../CustomUi/table/rowTable";
import { HeaderTable } from "../../../CustomUi/table/headerTable";
import { TabelaDatas } from "../tabelaDatas";
import { useScreenSize } from "../../../../utils/tsx";

//PROPS
interface Props {
    cod_talhao: number,
    nome_talhao: string;
    list_pragas: { [x: number]: { [x: string]: any[]; }; }[];
    nivelControle: MoniNivelItemControleItens[];
    listDates: any;
    groupTotalizar: boolean;
    handleAssembleHeatMap: Function;
    handleClickBadge: Function;
    setCalorSelect: Function;
    dataDetails: TypeDetailsMonitoramento;
    encolher: boolean;
    setVisibleDrawerPonto: React.Dispatch<React.SetStateAction<boolean>>;
    visibleChangeBooking: boolean;
}

export const TabelaPraga: React.FC<Props> = ({ list_pragas, nivelControle, listDates, groupTotalizar, 
    handleAssembleHeatMap, handleClickBadge, setCalorSelect, nome_talhao, cod_talhao, dataDetails, encolher, 
    setVisibleDrawerPonto, visibleChangeBooking }) => {

    //CONST
    const { currentHeight } = useScreenSize({});

    //STATES
    const [search_plague, setSearch_plague] = useState<string>('');
    const [open_tabela_datas, setOpen_tabela_datas] = useState<boolean>(false);
    const [praga_selecionada, setPraga_selecionada] = useState<listPragas|null>(null);
    const [nome_praga_selecionada, setNome_praga_selecionada] = useState<string>('');
    const [nome_variacao_selecionada, setNome_variacao_selecionada] = useState<string>('');
    const [lista_processadas, setLista_processadas] = useState<any[]>([]);
    const [lista_processadas_preserve, setLista_processadas_preserve] = useState<any[]>([]);
    const [fake_loading, setFake_loading] = useState<boolean>(true);
    

    const Row = ({ index, style }) => {//NOSONAR

        let { especie_nome, variacao, cores, key } = lista_processadas?.[index] ?? {};

        function selecionarPraga(key_selecionada: number) {
            let select = lista_processadas.filter(item=> item.key === key_selecionada);
            setPraga_selecionada(select?.[0]);
        }

        function qtdeDados() {
            let { cor_verde_claro, cor_verde_forte, cor_amarelo_claro, cor_amarelo_forte, cor_vermelho } = cores;
            let soma = cor_verde_claro + cor_verde_forte + cor_amarelo_claro + cor_amarelo_forte +cor_vermelho;
            return soma;
        }

        return (
            <div style={style}>
                <RowTable onDoubleClick={()=>{
                    setNome_praga_selecionada(especie_nome);
                    setNome_variacao_selecionada(variacao);
                    selecionarPraga(key);
                    setOpen_tabela_datas(true);
                }}>
                    <RowItem width={234} fontSize={13}>
                        {especie_nome}
                    </RowItem>
                    <RowItem width={115} fontSize={12.5}>
                        {variacao}
                    </RowItem>
                    <RowItem width={135} gap={5} style={{flexWrap: "wrap", padding: '0px 5px'}} >
                        <Render condition={cores?.cor_verde_claro > 0}>
                            <ColorCircle data-cor={'verde_claro'}>{leadZero(cores?.cor_verde_claro)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_verde_forte > 0}>
                            <ColorCircle data-cor={'verde_forte'}>{leadZero(cores?.cor_verde_forte)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_amarelo_claro > 0}>
                            <ColorCircle data-cor={'amarelo_claro'}>{leadZero(cores?.cor_amarelo_claro)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_amarelo_forte > 0}>
                            <ColorCircle data-cor={'amarelo_forte'}>{leadZero(cores?.cor_amarelo_forte)}</ColorCircle>
                        </Render>
                        <Render condition={cores?.cor_vermelho > 0}>
                            <ColorCircle data-cor={'vermelho'}>{leadZero(cores?.cor_vermelho)}</ColorCircle>
                        </Render>
                    </RowItem>
                    <RowItem width={65}>
                        <ButtonCustom 
                            onClick={()=>{
                                setNome_praga_selecionada(especie_nome);
                                setNome_variacao_selecionada(variacao);
                                selecionarPraga(key);
                                setOpen_tabela_datas(true);
                            }}
                            type={qtdeDados()!==1 ? 'secondary' : 'primary'}
                            icon={ qtdeDados()!==1 ? <FontAwesomeIcon icon={faTableList} /> : <FontAwesomeIcon icon={faEye} />}
                        >
                        </ButtonCustom>
                    </RowItem>
                </RowTable>
            </div>
        );
    }

    useEffect(() => {
        setLista_processadas(obterNomePraga(list_pragas, nivelControle, listDates, groupTotalizar, cod_talhao));
        setLista_processadas_preserve(obterNomePraga(list_pragas, nivelControle, listDates, groupTotalizar, cod_talhao));
    }, []);

    useEffect(() => {

        setTimeout(() => {
           if (lista_processadas_preserve.length > 0) {
                if (search_plague === '') {
                    setLista_processadas(lista_processadas_preserve);
                }
                if(search_plague.length > 0) {
                    let buscar_praga = lista_processadas_preserve.filter(buscarPraga(search_plague));
                    setLista_processadas(buscar_praga);
                }
            } 
        }, 300);
         
    }, [search_plague])
    

    useEffect(() => {
        let tempo_por_tamanho = (lista_processadas??[]).length * 35;
        let tempo_final = tempo_por_tamanho < 700 ? 700 : tempo_por_tamanho;

        setTimeout(() => {
            setFake_loading(false)
        }, tempo_final);

    }, [lista_processadas]);

    function buscarPraga(buscar_nome: string) {
        const nomeSemAcentos = removerAcentos(buscar_nome);
        return function (item) {
          const pragaSemAcentos = removerAcentos(String(item?.['especie_nome']))
          const variacaoSemAcentos = removerAcentos(String(item?.['variacao']))
          return pragaSemAcentos.toLowerCase().includes(nomeSemAcentos.toLowerCase()) ||
            variacaoSemAcentos.toLowerCase().includes(nomeSemAcentos.toLowerCase()) || !buscar_nome
        }
    }
    
    return (
        <ContentModalPraga>
            <Input 
                value={search_plague}
                onChange={setSearch_plague}
                placeholder="Buscar praga"
                autoFocus
            />
            
            <HeaderTable 
                itens={[
                    {valor: 'Praga', width: 234},
                    {valor: 'Variação', width: 115},
                    {valor: 'Resumo', width: 135},
                    {valor: '', width: 60},
                ]}
            />
            <div>
                <Render condition={fake_loading}>
                    <Carregando 
                        justifyContent="center"
                        legenda="processando lista de pragas..."
                        animation
                        height={200}
                    />
                </Render>
                <Render condition={!fake_loading && lista_processadas.length > 0}>
                    <List
                        height={obterAlturaListaPragas(currentHeight)}
                        itemCount={lista_processadas.length ?? 0}
                        itemSize={55}
                        width={'100%'}
                        useIsScrolling
                    >
                       {Row}
                    </List>
                </Render>

                <Render condition={lista_processadas.length === 0}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<> <b>'{search_plague}'</b> não encontrado!</>} />
                </Render>
            </div>

            <Render condition={open_tabela_datas && !encolher}>
                <TabelaDatas 
                    nome_praga={nome_praga_selecionada}
                    nome_variacao={nome_variacao_selecionada}
                    nome_talhao={nome_talhao}
                    onClose={()=>{setOpen_tabela_datas(false);setVisibleDrawerPonto(false)}}
                    praga_selecionada={praga_selecionada?.cores?.dados_gerais ?? []}
                    list_datas={praga_selecionada?.datas ?? []}
                    handleAssembleHeatMap={handleAssembleHeatMap}
                    handleClickBadge={handleClickBadge}
                    setCalorSelect={setCalorSelect}
                    dataDetails={dataDetails}
                    origem="Por talhão"
                    encolher={encolher}
                    setVisibleDrawerPonto={setVisibleDrawerPonto}
                    visibleChangeBooking={visibleChangeBooking}
                />
            </Render>
                        
        </ContentModalPraga>
    )
};