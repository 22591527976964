import { v4 as uid } from 'uuid';

import { MoniNivelItemControleItens } from "../../../../../services/monitoramento/nivelControleItens"
import { cores_monitoramento, cores_monitoramento_invertido } from '../../../meta';

//TYPES
export type listPragas__Cores__Dados = {
    cor: string,
    data?: string;
    info_cor: { cor: string, valorFinal?: string },
    key: string,
    media: string,
    resultado_minimo: string,
    resultado_media: string,
    resultado_maximo: string,
    valor: any[],
    key_atributo?: string,
    cod_talhao: number
}
type listPragas__Cores = {
    cor_amarelo_claro: number,
    cor_amarelo_forte: number,
    cor_verde_claro: number,
    cor_verde_forte: number,
    cor_vermelho: number,
    dados_gerais: listPragas__Cores__Dados[],
}

export type listPragas = {
    cod_especie: number,
    cod_variacao: number,
    cores: listPragas__Cores,
    monitoramento?: any[],
    datas: any[],
    especie_nome: string,
    key: number,
    nivel?: MoniNivelItemControleItens,
    variacao: string,
    ocultar_do_mapa?: boolean
}



// export const gradientI = {
//     1.0: '#01ff00', // 0
//     0.8: '#01ff00', // 0
//     0.4: '#b8ff05', // 1
//     0.2: '#ffff02', // 2
//     0.1: '#ffff02', // 3
//     0.0: '#ffff02', // 4
// }

// export const gradient = {
//     0.0: '#01ff00', // 0
//     0.1: '#01ff00', // 0
//     0.2: '#fcff02', // 1
//     0.4: '#fcff02', // 2
//     0.8: '#fcb700', // 3
//     1.0: '#fb0303', // 4
// }

export const mediaMapaCalor = (apurado, nc) => {
    apurado = parseFloat(apurado)

    nc = parseFloat(nc)

    const porcentagem = (apurado * 100) / nc

    let valorFinal = '0.0'
    let cor = '#01ff00'

    // if (porcentagem == 0) {
    //     valorFinal = '0.0'
    //     cor = '#01ff00'
    if (porcentagem > 0 && porcentagem < 100) {
        valorFinal = '0.2'
        cor = '#fcff02'
    } else if (porcentagem >= 100 && porcentagem < 300) {
        valorFinal = '0.8'
        cor = '#fcb700'
    } else if (porcentagem >= 300) {
        valorFinal = '1.0'
        cor = '#fb0303'
    }

    return { valorFinal, cor }
};


function info(item, nivelControle, listDates, groupTotalizar) {
    let info_necessarias = Object.entries(item).map(([pKey, value]: [string, any]) => {

        const codESpecie = Number(pKey.split(', ')[1]);
        const codVariaca = pKey.split(', ')[2] === 'null' ? null : Number(pKey.split(', ')[2]);

        let nivel: MoniNivelItemControleItens = {} as MoniNivelItemControleItens;

        if (!codVariaca) {
            nivel = nivelControle.find(
                (item) => item.cod_especie === codESpecie
            )
        } else {
            nivel = nivelControle.find(
                (item) =>
                    item.cod_especie === codESpecie &&
                    item.cod_variacao_especie === codVariaca
            )
        }

        const moni = listDates.value.map((data_dia) => {

            const array_de_data = Object.keys(value).filter((item) => data_dia === item);

            if (array_de_data.length > 0) {
                const obter_dados_pela_data = Object.entries(value).filter(([data]: any) => array_de_data[0] === data)[0];

                return { [obter_dados_pela_data[0]]: obter_dados_pela_data[1] }
            }

            return { [data_dia]: [] }
        })


        function variacao() {
            if (nivel?.variacao_especie_nome !== null) {
                if (groupTotalizar && nivel?.totalizar === 1) {
                    return null
                }
            }
            return nivel?.variacao_especie_nome;
        }

        return {
            monitoramento: moni,
            datas: listDates.value,
            variacao: variacao(),
            cod_variacao: codVariaca,
            cod_especie: codESpecie,
            // nivel: nivel
        };

    })

    return info_necessarias?.[0];
}


//TYPES
export type ItemCor = {
    cor: string;
    info_cor: { valorFinal?: string, cor: string },
    key: string,
    key_atributo: string;
    media: string;
    resultado_maximo: string;
    resultado_media: string;
    resultado_minimo: string;
    valor: any[]
}
function obterCores(dados_datas, cod_talhao?: number) {

    let extrair = dados_datas.map((data_item) => {
        return Object.entries(data_item).map(([nome_propri, valor]: any) => {

            const arrayValues = valor.map(item => parseFloat(item.valorApurado));

            let color = { cor: '' };

            const nivel = valor[0]?.nivel[0]

            const grad = nivel?.inverter_escala === 1 ? cores_monitoramento_invertido : cores_monitoramento;

            let is_inverter_escala = (nivel?.inverter_escala ?? 1) === 1;

            
            function ocultarDoMapa() {
                if (!is_inverter_escala) {
                    return false;
                }
                let is_valarApurado_sempre_zero = arrayValues.reduce((acumulador: number, item:number)=> acumulador + item, 0) === 0;
                return is_valarApurado_sempre_zero;
            }

            if (Math.max(...arrayValues) === 0) {
                color.cor = grad['0']
            }
            else {
                color = mediaMapaCalor(Math.max(...arrayValues), Number(valor[0]?.valorNivel).toFixed(1))
            }

            const med = (arrayValues.reduce((partialSum, a) => partialSum + a, 0) / arrayValues.length).toFixed(1)

            const chave = uid();
            
            return {
                key: chave,
                media: med,
                info_cor: color,
                cor: color?.cor,
                key_atributo: nome_propri,
                valor: valor,
                resultado_minimo: Math.min(...arrayValues).toFixed(1),
                resultado_media: Number(med).toFixed(1),
                resultado_maximo: Math.max(...arrayValues).toFixed(1),
                is_inverter_escala: is_inverter_escala,
                oculdar_do_mapa: ocultarDoMapa()
            }

        })
    })

    let cores_com_media = extrair.filter((cor) => { return cor[0].media !== 'NaN' && !(cor?.[0]?.oculdar_do_mapa) })

    let cor_verde_forte = cores_com_media.filter((cor) => { return cor[0].cor === '#01ff00' }).map((item: ItemCor[]) => {
        return item[0].cor;
    })
    let cor_verde_claro = cores_com_media.filter((cor) => { return cor[0].cor === '#b8ff05' }).map((item: ItemCor[]) => {
        return item[0].cor;
    })
    let cor_amarelo_claro = cores_com_media.filter((cor) => { return cor[0].cor === '#fcff02' }).map((item: ItemCor[]) => {
        return item[0].cor;
    })
    let cor_amarelo_forte = cores_com_media.filter((cor) => { return cor[0].cor === '#fcb700' }).map((item: ItemCor[]) => {
        return item[0].cor;
    })
    let cor_vermelho = cores_com_media.filter((cor) => { return cor[0].cor === '#fb0303' }).map((item: ItemCor[]) => {
        return item[0].cor;
    })

    let dados_gerais = extrair.map((item) => {
        return {
            ... item?.[0],
            cod_talhao: cod_talhao
        }
    });

    function isOcultarDoMapa() {
        return (dados_gerais ?? []).reduce((acumulador:boolean,item:any)=> acumulador && item.oculdar_do_mapa, true);
    }

    return {
        cor_verde_forte: cor_verde_forte.length,
        cor_verde_claro: cor_verde_claro.length,
        cor_amarelo_claro: cor_amarelo_claro.length,
        cor_amarelo_forte: cor_amarelo_forte.length,
        cor_vermelho: cor_vermelho.length,
        dados_gerais: dados_gerais,
        ocultar_do_mapa: isOcultarDoMapa()
    };
}

export function obterNomePraga(
    list_pragas: { [x: number]: { [x: string]: any[]; }; }[],
    nivelControle: MoniNivelItemControleItens[], 
    listDates: any, 
    groupTotalizar: boolean,
    cod_talhao: number
): listPragas[] {
    let pragas = list_pragas.map((item, index) => {
        let nome = ((Object.entries(item)[0])[0]).split(',')[0];
        let info_praga = info(item, nivelControle, listDates, groupTotalizar);
        let cores = obterCores(info_praga.monitoramento, cod_talhao);
        return {
            especie_nome: nome,
            cod_variacao: info_praga.cod_variacao,
            variacao: info_praga.variacao,
            cod_especie: info_praga.cod_especie,
            // monitoramento: info_praga.monitoramento,
            datas: info_praga.datas,
            // nivel: info_praga.nivel,
            cores: cores,
            key: index,
            cod_talhao: cod_talhao
        }
    })
    
    let exibir_atributo_acultar_mapa = pragas.map((item)=> {
        return {
            ...item,
            ocultar_do_mapa: (item.cores.dados_gerais?.map(item=>item.oculdar_do_mapa) ?? []).reduce((acc:boolean, item:boolean)=> acc && item, true)
        }
    });

    let remover_oculatar_do_mapa = exibir_atributo_acultar_mapa.filter(item=>item.ocultar_do_mapa === false);

    return remover_oculatar_do_mapa;
};


export function obterAlturaListaPragas(currentHeight: number) {
    if (currentHeight > 880) {
        return 430;
    }
    
    return 260;
}