import styled from "styled-components";

export const CollapseAcc = styled.div`
    border-left: thin solid var(--border-color);
    border-right: thin solid var(--border-color);
    border-bottom: thin solid var(--border-color);
    overflow: hidden;
    transition: .4s;

    :is([data-isFirst = true]) {
        border-top: thin solid var(--border-color);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    :is([data-isLast = true]) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    :is([data-single = true]) {
        border: thin solid var(--border-color);
        border-radius: 8px !important;
    }


    button.CollapseAcc__header {
        width: 100%;
        background: #fafafa;
        box-sizing: border-box;
        padding: 14.5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: none;

        :is([data-opened = true]) {
            border-bottom: thin solid var(--border-color);
        }

        :hover {
            filter: brightness(101%);
        }

        div.CollapseAcc__header__main {
            width: 100%;
            height: 100%;
            display: flex;
            gap: 14px;
            font-size: 14px;
            font-weight: 400;
            color: var(--text-color);

            .CA__header__main__arrow {
                transition: .3s;
            }
            .CA__header__main__arrow:is([data-opened = true]) {
                transform: rotate(90deg) !important;
            }
        }
    }
    
    div.CollapseAcc__content {
        width: 100%;
        box-sizing: border-box;
        padding: 14px;
        overflow: hidden;
        transition: .4s;
        position: sticky;
        top: 0;
    }

`;