import { H3Component } from "./styled"

//PROPS
interface Props {
    children: string | React.ReactNode;
    style?: React.CSSProperties;
}

export const TitleH3: React.FC<Props> = ({ children, style }) => {
    return (
        <H3Component style={style}>
            {children}
        </H3Component>
    )
}